const styles = {
  bold: {
    fontWeight: '600',
  },
  button: {
    backgroundColor: '#EE3124 !important',
    borderRadius: '5px !important',
    height: '38px !important',
    width: '100%'
  },
  check: {
    '&:after' : {
      background: '#FFFFFF',
      border: '1px solid #D9DEE4',
      boxSizing: 'border-box',
      color: '#EE3124',
      content: '""',
      display: 'flex',
      height: '17px',
      justifyContent: 'center',
      marginLeft: '-2px',
      marginTop: '-2px',
      visibility: 'visible',
      width: '17px',
    },
    '&:checked': {
      '&:after': {
        background: '#EE3124',
        color: '#ffffff',
        content: '"\\2714"',
      }
    },
    borderRadius: '3px',
    marginRight: '8.58px',
    visibility: 'none',
  },
  copyright: {
    color: '#474C55',
    fontSize: '12px',
    fontStyle: 'normal',
    fontWeight: '300',
    lineHeight: '18px',
    marginTop: '24px',
    textAlign: 'center',
  },
  error: {
    alignItems: 'center',
    backgroundColor: '#F55151',
    color: 'white',
    display: 'flex',
    height: '73px',
    justifyContent: 'center',
    position: 'absolute',
    top: 0,
    width: '100%',
  },
  forgot: {
    color: '#EE3124',
    fontSize: '13px',
    fontStyle: 'normal',
    fontWeight: 'normal',
    lineHeight: '19px',
    position: 'absolute',
    right: 0,
  },
  form: {
    width: '431px',
  },
  formDescription: {
    fontSize: '18px',
    fontStyle: 'normal',
    fontWeight: '300',
    lineHeight: '20px',
    marginBottom: '24px',
    marginTop: '0px',
  },
  formItem: {
    alignItems: 'center',
    display: 'flex',
    marginBottom: '16px',
    position: 'relative'
  },
  formTitle: {
    fontSize: '24px',
    fontStyle: 'normal',
    fontWeight: '500',
    lineHeight: '36px',
    marginBottom: '3px',
    marginTop: '0px',
  },
  image: {
    height: '100%',
    objectFit: 'cover',
    transform: 'matrix(-1, 0, 0, 1, 0, 0)',
    width: '100%',
  },
  imageLogo: {
    height: '82.5px',
    position: 'absolute',
    width: '285px',
    zIndex: 1
  },
  red: {
    color: '#EE3124',
  },
  remember: {
    color: '#474C55',
    fontSize: '14px',
    fontStyle: 'normal',
    fontWeight: 'normal',
    lineHeight: '21px',
    textAlign: 'center',
    width: '92.57px',
  },
  root: {
    display: 'flex',
    flexDirection: 'row-reverse',
    fontFamily: 'Poppins',
    height: '100vh',
    width: '100vw',
  },
  section: {
    alignItems: 'center',
    display: 'flex',
    height: '100%',
    justifyContent: 'center',
    width: '50%',
  },
  sectionForm: {
    position: 'relative',
  },
  sectionImage: {
    '&::after': {
      background: 'linear-gradient(227.44deg, rgba(0, 0, 0, 0.8) -4.57%, rgba(255, 0, 0, 0.184) 90.12%)',
      content: '""',
      height: '100%',
      left: 0,
      position: 'absolute',
      top: 0,
      width: '100%',
    },
    height: '100%',
    position: 'relative',
  },
  textfield: {
    marginBottom: '25px'
  }
};

export default styles;
