export default (title, arr) => {
  const newArr = [];
  newArr.push(title);
  arr.map((item) => {
    const newItem = [];
    
    Object.values(item).forEach((obj) => {
      newItem.push((typeof obj === 'string' || obj instanceof String) 
        ? obj.replace(/ /g,'-').replace(/,/g,'').replace(/(\r\n|\n|\r)/gm, '') : '-');
    });

    newArr.push(newItem);
  });
  
  return newArr.map(e => e.join(';')).join('\n');
};
