const styles = {
  '@keyframes ldsRing': {
    from: {
      transform: 'rotate(0deg)',
    },
    to: {
      transform: 'rotate(360deg)',
    }
  },  
  action: {
    cursor: 'pointer',
  },
  actionItem: {
    '&:hover': {
      background: '#F8E7E6',
      color: '#EE3124',
      fontStyle: 'normal',
      fontWeight: 500,
      lineHeight: '21px',
    },
    alignItems: 'center',
    display: 'flex',
    fontSize: '12px',
    fontWeight:' 500',
    height:' 39px',
    lineHeight: '18px',
    padding: '0 13px',
  },
  actionWrapper: {
    cursor: 'pointer',
    position: 'relative',
  },
  buttonDownload: {
    alignItems: 'center',
    background: '#FFFFFF',
    border: '1px solid #EB5757',
    borderRadius: '5px',
    boxSizing: 'border-box',
    color: '#EB5757',
    cursor: 'pointer',
    display: 'flex',
    fontSize: '16px',
    height: '38px',
    justifyContent: 'center',
    lineHeight: '24px',
    width: '155px',
  },
  buttonFilter: {
    '&:focus': {
      boxShadow: 'none !important',
      outline: 0,
    },
    backgroundColor: '#FFFFFF',
    border: '1px solid #BDBDBD',
    borderRadius: '25px',
    boxSizing: 'border-box',
    color: '#BDBDBD',
    cursor: 'pointer',
    fontSize: '12px',
    fontStyle: 'normal',
    fontWeight: 500,
    height: '25px',
    lineHeight: '18px',
    marginLeft: '13px',
    marginRight: '4px',
    width: '85px',
  },
  buttonFilterActive: {
    backgroundColor: 'rgba(232, 176, 172, 0.3)',
    border: 'none',
    color: '#EB5757',
  },
  center: {
    textAlign: 'center',
  },
  dropdown: {
    width: '120px',
  },
  entries: {
    alignItems: 'center',
    display: 'flex',
  },
  filterSection: {
    alignItems: 'center',
    background: '#FFFFFF',
    border: '1px solid #D9DEE4',
    borderCollapse: 'collapse',
    borderStyle: 'hidden',
    borderTopLeftRadius: '5px',
    borderTopRightRadius: '5px',
    boxShadow: '0 0 0 1px #D9DEE4',
    boxSizing: 'border-box',
    display: 'flex',
    height: '68px',
    justifyContent: 'space-between',
    paddingLeft: '18px',
    paddingRight: '24px',
  },
  imageAction: {
    marginRight: '11px'
  },
  imageDownload: {
    height: '24px',
    marginRight: '11px',
  },
  labelEntries: {
    color: 'rgba(0, 0, 0, 0.54)',
    display: 'flex',
    fontSize: '15px',
    fontStyle: 'normal',
    fontWeight: 600,
    lineJeight: '22px',
  },
  ldsRing: {
    display: 'inline-block',
    height: '35px',
    position: 'relative',
    width: '35px',
  },
  ldsRing1: {
    animationDelay: '-0.45s',
  },
  ldsRing2: {
    animationDelay: '-0.3s',
  },
  ldsRing3: {
    animationDelay: '-0.15s',
  },
  ldsRingDiv: {
    animation: 'ldsRing 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite',
    border: '3px solid #fff',
    borderColor: '#EB5757 transparent transparent transparent',
    borderRadius: '50%',
    boxSizing: 'border-box',
    display: 'block',
    height: '20px',
    margin: '8px',
    position: 'absolute',
    width: '20px'
  },
  nav: {
    alignItems: 'center',
    background: '#ffffff',
    border: '1px solid #D9DEE4',
    borderRight: '0px',
    boxSizing: 'border-box',
    color: '#808285',
    cursor: 'pointer',
    display: 'flex',
    fontSize: '14px',
    fontStyle: 'normal',
    fontWeight: 'normal',
    height: '39px',
    lineHeight: '21px',
    padding: '0 15px',
  },
  navCurrent: {
    background: '#EE3124',
    color: '#ffffff',
  },
  navNext: {
    borderBottomRightRadius: '5px',
    borderRight: '1px solid #D9DEE4',
    borderTopRightRadius: '5px',
  },
  navPrev: {
    borderBottomLeftRadius: '5px',
    borderTopLeftRadius: '5px',
  },
  navSection: {
    alignItems: 'center',
    display: 'flex',
    justifyContent: 'space-between',
    margin: '16px 0',
  },
  pagination: {
    display: 'flex',
  },
  red: {
    background: 'rgba(232, 176, 172, 0.1)',
  },
  redLabel: {
    color: '#EE3124',
    margin: '0 5px',
  },
  root: {
    background: '#FFFFFF',
    border: '1px solid #D9DEE4',
    borderBottomLeftRadius: '5px',
    borderBottomRightRadius: '5px',
    borderCollapse: 'collapse',
    borderStyle: 'hidden',
    boxShadow: '0 0 0 1px #D9DEE4',
    boxSizing: 'border-box',
    width: '100%',
  },
  td: {
    borderBottom: '1px solid #D9DEE4',
    fontSize: '14px',
    fontStyle: 'normal',
    fontWeight: 500,
    height: '50px',
    lineHeight: '21px',
    overflowWrap: 'break-word',
    padding: '15px',
  },
  tdHead: {
    borderBottom: '1px solid rgba(0, 0, 0, 0.38)',
    fontSize: '14px',
    fontStyle: 'normal',
    fontWeight: 600,
    height: '50px',
    lineHeight: '21px',
    padding: '0 15px',
  },
  textfield: {
    border: '1px solid #D9DEE4',
    fontSize: '13px',
    fontStyle: 'normal',
    fontWeight: 'normal',
    height: '38px',
    lineHeight: '12px',
    padding: '0 16px',
    width: '200px'
  },
  update: {
    background: 'white',
    borderRadius: '5px',
    boxShadow: '0px 0px 4px rgba(0, 0, 0, 0.25) ',
    padding: '10px 0',
    position: 'absolute',
    right: '0',
    width: '187px',
    zIndex: 1,
  },
};

export default styles;
