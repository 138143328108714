/* eslint-disable */
import React from 'react';
import PropTypes from 'prop-types';
import DateRangePicker from 'react-bootstrap-daterangepicker';
import { IMAGES } from '../../../configs';

export default class DataTable extends React.Component {
  constructor(props) {
    super(props);

    const { 'data-body': dataBody, 'filter-entries': entries, page, 'total-data': totalData } = this.props;
    this.state = {
      entries: entries[0],
      dataBody,
      filter: 0,
      page: (page) ? page : 1,
      minPage: 1,
      maxPage: (Math.ceil(totalData / entries[0]) <= 5) && totalData
        ? Math.ceil(totalData / entries[0])
        : (totalData) ? 5 : null,
      totalPage: Math.ceil(totalData / entries[0]),
      show: null,
    };
    this._handleEntries = this._handleEntries.bind(this);
  }

  componentDidMount() {
    
  }

  componentDidUpdate(prevProps) {
    const { 'data-body': dataBodyPrev, 'filter-entries': entriesPrev, pagePrev, 'total-data': totalDataPrev } = prevProps;
    const { 'data-body': dataBody, 'filter-entries': entries, page, 'total-data': totalData } = this.props;
    
    if (page !== this.state.page) {
      this.setState({
        page
      });
    }

    if (this.state.minPage > page) {
      this.setState({
        minPage: page,
      })
    }
    
    if (this.state.maxPage < page && this.state.maxPage) {
      this.setState({
        maxPage: page
      })
    }
    if (dataBodyPrev !== dataBody) {
      const maxPage = (Math.ceil(totalData / entries[0]) >= 5)
      ? Math.ceil(totalData / entries[0])
      : 5;
      this.setState({ 
        dataBody,
        maxPage: (this.state.maxPage > maxPage || !this.state.maxPage) ? maxPage : this.state.maxPage,
        totalPage: Math.ceil(totalData / entries[0]),
      });
    }
  }

  _handleEntries(index) {
    const { 'filter-entries': entries } = this.props;
    const { dataBody } = this.state;

    this.setState({
      entries: entries[index],
      page: 1,
      minPage: 1,
      maxPage: (Math.ceil(dataBody.length / entries[index]) <= 5)
        ? Math.ceil(dataBody.length / entries[index])
        : 5,
      totalPage: Math.ceil(dataBody.length / entries[index]),
    });
  }

  _handleClickAction(item, index, actionIndex) {
    const { onAction } = this.props;
    
    onAction(item, index, actionIndex);

    this.setState({ show: false });
  }

  _handleClickDownload() {
    const { onDownload } = this.props;

    if (onDownload)
      onDownload(this.state.dataBody);
  }

  _handleClickpage(page) {
    const { onClickPage } = this.props;

    this.setState({ page }, () => {
      onClickPage(page);
    });
  }

  _handleClicknav(iteration) {
    const { onClickPage } = this.props;
    const after = this.state.page + iteration;

    if (after > 0 && after <= this.state.totalPage)
      this.setState({ page: this.state.page + (iteration) }, () => {
        if (this.state.page > this.state.maxPage) {
          this.setState({
            minPage: this.state.minPage + 1,
            maxPage: this.state.maxPage + 1,
          });
        }

        if (this.state.page < this.state.minPage) {
          this.setState({
            minPage: this.state.minPage - 1,
            maxPage: this.state.maxPage - 1,
          });
        }
        onClickPage(this.state.page);
      });
  }

  _handleShowAction(index, show) {
    if (show) {
      this.setState({ show: index });
    }
  }

  renderBody() {
    const { 'max-char': maxChar, 'max-width': maxWidth, 'min-width': minWidth, align,
      classes, loading, show } = this.props;
    const { dataBody } = this.state;
    const minIndex = (this.state.page * this.state.entries) - this.state.entries;
    const maxIndex = this.state.page * this.state.entries;

    if (dataBody[this.state.page - 1] && dataBody[this.state.page - 1].length > 0) {
      return (
        dataBody[this.state.page - 1].map((item, index) => {
          const columns = [];
          const classesTr = (index % 2 === 0) ? classes.red : null;
          columns.push(
            <td className={classes.td}
              style={{ verticalAlign: align }}>{((this.state.page - 1) * 10 + index) + 1}</td>
          );

          for (let i = 0; i < show.length; i++) {
            let value = (item[show[i]]) ? ((maxChar && item[show[i]].length > maxChar)
              ? item[show[i]].substr(0, maxChar) + ' . . .' : item[show[i]]) : '-';
            let update = (index === this.state.show) ? (
              <div className={classes.update}>
                {
                  item.action.map((action, actionIndex) => (
                    <item className={classes.actionItem} onClick={this._handleClickAction.bind(this, item, index, actionIndex)}>
                      {(action.image) ? <img className={classes.imageAction} src={action.image} /> : ''} {action.text}
                    </item>
                  ))
                }
              </div>
            ) : '';
            
            value = (show[i] === "action")
              ? <div className={classes.actionWrapper}                  
                  onMouseEnter={this._handleShowAction.bind(this, index, true)}
                  onMouseLeave={this._handleShowAction.bind(this, false)}>
                  <img className={classes.action}
                    src={IMAGES.POINT} />
                  {update} 
                </div>
              : value;

            columns.push(
              <td className={classes.td} 
                style={{ 
                  maxWidth: `${maxWidth}px`,
                  minWidth: `${minWidth}px`,
                  verticalAlign: align,
                }}>{value}</td>
            );
          }

          return (
            <tr className={classesTr} key={index}>
              {columns}
            </tr>
          );
        })
      );
    } else {
      return (
        <tr className={classes.red}>
          <td className={classes.td} colSpan={show.length + 1}>
            {
              loading ? 'Data loading . . .' : 'Data Not Found'
            }
          </td>
        </tr>
      );
    }
  }

  renderFilter() {
    const { classes, date = '', onDownload, onApplyDate, loading } = this.props;

    return (
      <section className={classes.filterSection}>      
        <div>
          {
            (onApplyDate) && (
              <>
                <label>Filter berdasarkan tanggal:&nbsp; </label>
                <DateRangePicker
                  initialSettings={{
                    autoUpdateInput: false,
                    locale: {
                      cancelLabel: 'Clear',
                    },
                  }}
                  onApply={onApplyDate}
                  onCancel={onApplyDate.bind(null, false)}
                >
                  <input className={classes.textfield} value={date}/>
                </DateRangePicker>
              </>
            )
          }
          
        </div>
        { onDownload && (<div className={classes.buttonDownload} onClick={this._handleClickDownload.bind(this)}>
          {loading
            ? (<div className={classes.ldsRing}>
                <div className={classes.ldsRingDiv}></div>
                <div className={[classes.ldsRingDiv, classes.ldsRing1].join(' ')}></div>
                <div className={[classes.ldsRingDiv, classes.ldsRing2].join(' ')}></div>
                <div className={[classes.ldsRingDiv, classes.ldsRing3].join(' ')}></div>
              </div>)
            : (<><img className={classes.imageDownload} src={IMAGES.DOWNLOAD} />  Download</>)}
        </div>) }
      </section>
    )
  }

  renderHead() {
    const { 'data-head': dataHead, classes } = this.props;

    return (
      <tr>
        <td className={classes.tdHead}>No.</td>
        {
          dataHead.map((head, index) => {
            return (
              <td className={classes.tdHead} key={index}>{head}</td>
            );
          })
        }
      </tr>
    );
  }

  renderPagination() {
    const { classes } = this.props;
    const pages = [];

    for (let i = this.state.minPage; i <= this.state.maxPage; i++) {
      const navCurrent = (i === this.state.page) ? classes.navCurrent : null;

      pages.push(
        <div className={[classes.nav, navCurrent].join(' ')} onClick={this._handleClickpage.bind(this, i)}>
          {i}
        </div>
      );
    }
    return (
      <span className={classes.pagination}>
        <div className={[classes.nav, classes.navPrev].join(' ')}
          onClick={this._handleClicknav.bind(this, -1)}>Sebelumnya</div>
        {pages}
        <div className={[classes.nav, classes.navNext].join(' ')}
          onClick={this._handleClicknav.bind(this, 1)}>Selanjutnya</div>
      </span>
    );
  }

  render() {
    const { classes, 'filter-date': filterDate, onDownload, 'total-data': totalData } = this.props;
    const filterContent = (filterDate || onDownload) 
      ? this.renderFilter()
      : '';
    return (
      <React.Fragment>
        <main>
          {filterContent}
          <table className={classes.root}>
            <thead>
              {this.renderHead()}
            </thead>
            <tbody>
              {this.renderBody()}
            </tbody>
          </table>
          <section className={classes.navSection}>
            <div className={classes.entries}>
              <label className={classes.labelEntries}>
                Showing <div className={classes.redLabel}>{((this.state.page - 1) * 10 + 1)} - {
                  ((this.state.page) * 10) < totalData ? ((this.state.page) * 10) : totalData
                }</div>
                of {totalData} entries &nbsp;
              </label>
            </div>
            {this.renderPagination()}
          </section>
        </main>
      </React.Fragment>
    );
  }
}

DataTable.propTypes = {
  classes: PropTypes.object,
  className: PropTypes.string,
  'data-body': PropTypes.array,
  'data-head': PropTypes.array,
  date: PropTypes.string,
  filter: PropTypes.string,
  'filter-date': PropTypes.bool,
  'filter-entries': PropTypes.array,
  'filter-item': PropTypes.array,
  handleApplyDate: PropTypes.func,
  items: PropTypes.array,
  loading: PropTypes.bool,
  onChange: PropTypes.func,
  onClickPage: PropTypes.func,
  page: PropTypes.string,
  show: PropTypes.array,
  variant: PropTypes.string,
};

DataTable.defaultProps = {
  'data-body': [],
  'data-head': [],
  'filter-date': false,
  'filter-entries': [],
  'filter-item': [],
  classes: {},
  className: {},
  date: '',
  filter: '',
  onApplyDate: false,
  items: [],
  loading: false,
  onChange: () => { },
  onClickPage: () => { },
  page: 1,
  show: [],
  variant: '',
};
