import withStyle from 'react-jss';
import { connect } from 'react-redux';
import { reduxForm } from 'redux-form';
import Component from './component';
import styles from './styles';

function mapStateToProps(state) {
  const { EditCustomer = {} } = state.form;
  const { user } = state.subscribers;
  const formValues = EditCustomer.values;

  return {
    formValues,
    initialValues: user,
  };
}

const Styles = withStyle(styles)(Component);

const Form = reduxForm({
  form: 'EditCustomer',
  enableReinitialize: true,
})(Styles);

export default connect(
  mapStateToProps,
  null, null, { forwardRef: true }
)(Form);


