import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { withStyles } from '@material-ui/core/styles';
import Component from './component';
import * as actions from './action';
import styles from './styles';

function mapStateToProps(state) {
  const { labelActive, valueActive, labelInactive, valueInactive,
    labelActiveDevice, valueActiveDevice, labelInactiveDevice, valueInactiveDevice,
    labelMysooltan, valueMysooltan, labelSooltancam, valueSooltancam,
    labelSharinguser, valueSharinguser, totalActive, totalInactive, 
    totalMySooltan, totalSooltancamShare, totalSooltancam, users } = state.dashboard;
  const { isLoading, isLoadingSubmit } = state.loading;
  
  return {
    labelActive, valueActive, labelInactive, valueInactive,
    labelActiveDevice, valueActiveDevice, labelInactiveDevice, valueInactiveDevice,
    labelMysooltan, valueMysooltan, labelSooltancam, valueSooltancam,
    labelSharinguser, valueSharinguser,
    isLoading, isLoadingSubmit, totalActive, totalInactive,
    totalMySooltan, totalSooltancamShare, totalSooltancam, users,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(actions, dispatch)
  };
}

const styled = withStyles(styles)(Component);

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(styled);
