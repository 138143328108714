import React from 'react';
import { IMAGES } from '../../configs';

export default class Component extends React.Component {
  render() {
    return (
      <section style={{
        alignItems: 'center',
        background: 'radial-gradient(44.19% 58.93% at 50% 50%, #FFFFFF 0%, #E6E6E6 100%)',
        display: 'flex',
        height: '100vh',
        justifyContent: 'center',
      }}>
        <img src={IMAGES.NOT_FOUND} />
      </section>
    );
  }
}
