export function toLowerCase(text) {
  return text.toLowerCase();
}

export function dateToString(date) {
  let month = [
    'January', 'February', 'March',
    'April', 'May', 'June', 'July',
    'August', 'September', 'October',
    'November', 'December'
  ];

  return (date) ? ('0' + date.getDate()).slice(-2) + ' ' + month[date.getMonth()].substring(0, 3)
    + ' ' + date.getFullYear() : '';
}
