import React from 'react';
import PropTypes from 'prop-types';
import Typography from '../Typography';

export default class Component extends React.Component {
  constructor(props) {
    super(props);
    
    this.state = {
      active: false,
      hover: false,
      inputSelection: false,
      value: (this.props.meta) ? this.props.meta.value : '',
      updateSelection: false
    };

    this.inputElRef = React.createRef();
  }

  componentDidUpdate() {
    if (this.state.updateSelection) {
      const selection = this.state.updateSelection;
      this.inputElRef.current.selectionStart = selection.start;
      this.inputElRef.current.selectionEnd = selection.end;

      this.setState({ updateSelection: false });
    }

    if (this.props.input.value !== this.state.value) {
      this.setState({ value: this.props.input.value });
    }
  }
  
  _handlerClick(active) {
    this.setState({ active });
  }

  _handleChange(e) {
    const { input } = this.props;
    
    e.preventDefault();
    
    const { normalize, variant } = this.props.meta;
    let newValue;
    
    if (normalize) {
      const normalValue = normalize(e.target.value);
      newValue = (normalValue !== undefined) ? normalValue : input.value;
    } else {
      newValue = e.target.value;
    }
    
    this.setState({ value: newValue }, () => {
      input.onChange(this.state.value);

      if (variant !== 'area-filled' && variant !== 'area-line') {
        this.inputElRef.current.style.height = 'inherit';
        
        if ((this.inputElRef.current.value.match(/\n/g)||[]).length === 0) {
          this.inputElRef.current.style.height = `15px`; 
        } else {
          this.inputElRef.current.style.height = `${this.inputElRef.current.scrollHeight}px`; 
        }
      }
      
      this.setState({ updateSelection: { ...this.state.inputSelection } });
    });
  }

  _handlerClickWrapper() {
    const { onClick } = this.props;

    this.inputElRef.current.focus();
    
    if (typeof onClick === 'function') {
      onClick();
    }
  }

  _handlerFocus() {
    const { input } = this.props; 
    const {
      onFocus = () => {},
    } = input;

    this.saveCursorPosition();

    onFocus();
  }

  _handlerHover(hover) {
    this.setState({ hover });
  }

  _handlerKeydown(e) {
    if ( e.key === 'Backspace' ) {
      this.saveCursorPosition(-1);
    } else {
      this.saveCursorPosition(1);
    }
  }

  _handlerKeyUp(e) {
    const { input } = this.props;
    const number = new RegExp(/^\d+$/);
    let increment;
    
    if ( e.key === 'Enter' && input.onSubmit) {
      input.onSubmit(this.state.value);
    }

    if ( e.key === 'ArrowUp' && number.test(e.target.value)) {
      increment = 1;
      this.saveCursorPosition(2);
    }

    if ( e.key === 'ArrowDown') {
      increment = -1;
    }

    if ( increment ) {
      this.setState({ value: parseInt(e.target.value) + increment }, () => {
        input.onChange(this.state.value);
      });
    }
  }

  saveCursorPosition(increment) {
    this.setState({
      inputSelection: {
        start: this.inputElRef.current.selectionStart + (increment),
        end: this.inputElRef.current.selectionEnd + (increment)
      }
    });
  }

  render() {
    const { className: classnameProps, classes, input, meta } = this.props;
    const { 
      'icon-align': iconAlign, 
      color,
      disabled,
      error,
      icon: Icon,
      iconRight, 
      helper,
      placeholder, 
      type,
      variant = 'basic-filled',
    } = meta;
    const { 
      onBlur = () => {},
      onMouseEnter = () => {},
      onMouseLeave = () => {},
    } = input;
    let formInputWrap, formInput, textAreaVariant, textarea, hideScroll;

    switch (variant) {
      case 'basic-filled': 
        formInput = (!this.state.hover || disabled) 
          ? classes.formInputFilled : classes.formInputFilledHover;
        formInputWrap = (!this.state.hover || disabled) 
          ? classes.formInputWrapFilled : classes.formInputWrapFilledHover;
        break;
      case 'basic-line': 
        formInput = classes.formInputLine;
        formInputWrap = classes.formInputWrapLine;
        break;
      case 'multiline-filled':
        formInput = (!this.state.hover || disabled) 
          ? classes.formInputFilled : classes.formInputFilledHover;
        formInputWrap = (!this.state.hover || disabled) 
          ? classes.formInputWrapFilled : classes.formInputWrapFilledHover;
        hideScroll = classes.textareaHideScroll;
        textarea = true;
        break;
      case 'multiline-line': 
        formInput = classes.formInputLine;
        formInputWrap = classes.formInputWrapLine;
        hideScroll = classes.textareaHideScroll;
        textarea = true;
        break;
      case 'area-filled': 
        formInput = (!this.state.hover || disabled) 
          ? classes.formInputFilled : classes.formInputFilledHover;
        formInputWrap = (!this.state.hover || disabled) 
          ? classes.formInputWrapFilled : classes.formInputWrapFilledHover;
        textAreaVariant = classes.textAreaVariant;
        textarea = true;
        break;
      case 'area-line': 
        formInputWrap = classes.formInputWrapLine;
        formInput = classes.formInputLine;
        textAreaVariant = classes.textAreaVariant;
        textarea = true;
        break;
      default:
        formInput = (!this.state.hover && !disabled) 
          ? classes.formInputFilled : classes.formInputFilledHover;
        formInputWrap = (!this.state.hover && !disabled) 
          ? classes.formInputWrapFilled : classes.formInputWrapFilledHover;
        break;
    }

    const variantStyle = (variant) ? variant.split('-')[1] : '';
    const activeIcon = (this.state.active) ? classes.formIconActive : '';
    const activeInput = (this.state.active) ? classes.formActive : '';
    const activeText = (this.state.active) ? classes.formInputActive : '';
    const align = (iconAlign === 'right') ? classes.iconRight : classes.iconLeft;
    const disabledInput = (disabled && variantStyle === 'filled') ? classes.formInputDisabled : '';
    const disabledInputWrap = (disabled && variantStyle === 'filled') ? classes.formInputWrapDisabled : '';
    const errorHelper = (error) ? classes.helperWrapError : '';
    const errorInput = (error) ? classes.formInputError : '';
    const errorInputWrap = (error) ? classes.formInputWrapError : '';
    const helperWrap = (helper) ? (
      <div className={[classes.helperWrap, errorHelper].join(' ')}>
        <Typography variant="overline1">{helper}</Typography>
      </div>
    ) : null;
    const password = (type === 'password' && 
      (input.value !== '' && input.value !== null)) ? classes.password : '';
    const iconWrapper = (Icon) ? <div className={[classes.iconWrap, activeIcon].join(' ')} onClick={this._handlerClickWrapper.bind(this)}>{Icon}</div> : '';
    const inputForm = (!textarea) ? (
      <input className={[classes.formInput, formInput, disabledInput, errorInput, password].join(' ')} 
        disabled={disabled} id={input.name} 
        name={input.name}
        onBlur={onBlur.bind(this)}
        onChange={this._handleChange.bind(this)}
        onFocus={this._handlerFocus.bind(this)}
        onKeyDown={this._handlerKeydown.bind(this)}
        onKeyUp={this._handlerKeyUp.bind(this)}
        onMouseEnter={onMouseEnter.bind(this)}
        onMouseLeave={onMouseLeave.bind(this)}
        placeholder={placeholder} ref={this.inputElRef}
        style={{ color: color }} type={type}
        value={this.state.value} />
    ) : (
      <textarea 
        className={[classes.formInput, formInput, classes.textarea, disabledInput, errorInput, hideScroll, textAreaVariant].join(' ')} 
        id={input.name} name={input.name} 
        onBlur={onBlur.bind(this)} 
        onChange={this._handleChange.bind(this, input)}
        onFocus={this._handlerFocus.bind(this)}
        onKeyDown={this._handlerKeydown.bind(this)}
        onKeyUp={this._handlerKeyUp.bind(this)}
        onMouseEnter={onMouseEnter.bind(this)}
        onMouseLeave={onMouseLeave.bind(this)}
        placeholder={placeholder}
        ref={this.inputElRef}  
        style={{ color: color }}
        type={type} />
    );    
    
    return (
      <section 
        className={classnameProps}
        onClick={this._handlerClickWrapper.bind(this)}
        onMouseEnter={this._handlerHover.bind(this, true)}
        onMouseLeave={this._handlerHover.bind(this, false)}
        style={{ display: 'flex' }}>
        <div 
          className={[classes.formInputWrap, activeInput, align, formInputWrap, disabledInputWrap, errorInputWrap].join(' ')}>
          {iconWrapper}
          <div className={[classes.formInputTextWrap, activeText].join(' ')} id="wrapper"
            onBlur={this._handlerClick.bind(this, false)}
            onFocus={this._handlerClick.bind(this, true)}>
            { inputForm }
          </div>
          {iconRight}
        </div>
        {helperWrap}
      </section>
    );
  }
}

Component.propTypes = {
  classes: PropTypes.object,
  className: PropTypes.string,
  input: PropTypes.object,
  meta: PropTypes.object,
  onClick: PropTypes.func,
};

Component.defaultProps = {
  classes: {},
  className: null,
  input: {},
  meta: {},
  onClick: () => {},
};
