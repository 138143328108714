import React from 'react';
import PropTypes from 'prop-types';
import CryptoJS from 'crypto-js';
import { IMAGES, ROUTES } from '../../configs';
import { getUserData } from '../../utils/common';
import Base from '../../components/layouts/Pagebase';
import Button from '../../components/elements/Button';
import Dropdown from '../../components/elements/Dropdown';
import Textfield from '../../components/elements/Textfield';
export default class Component extends React.Component {
  constructor(props) {
    super(props);

    const { admin } = this.props;

    this.state = {
      errorMessage: false,
      password: null,
      passwordconfirm: null,
      passwordType: true,
      passwordTypeConfirm: true,
      username: (admin) ? admin.username : getUserData().username,
      selectedItem: (admin) ? this.getLevel(admin.level) - 1 : getUserData().level - 1,
      showModal: false,
    };

    this._handleChangeDropdown = this._handleChangeDropdown.bind(this);
    this._handleShowpassword = this._handleShowpassword.bind(this);
    this._handleShowpasswordConfirm = this._handleShowpasswordConfirm.bind(this);
  }

  componentDidMount() {
    const { actions } = this.props;

    actions.fetchAllUsers();
  }

  _closeModal() {
    const { admin, actions } = this.props;

    actions.closeModal();

    if (admin)
      this.props.history.push(ROUTES.ADMIN());
  }

  _handleChangeDropdown(selectedItem) {
    this.setState({ selectedItem });
  }
  _handlePassword(password) {
    this.setState({ errorPassword: false, password });
  }
  _handlePasswordConfirm(passwordconfirm) {
    this.setState({ errorPassword: false, passwordconfirm });
  }
  _handleReset() {
    const { admin } = this.props;
    if (admin) {
      this.props.history.push(ROUTES.ADMIN());
    } else {
      this.setState({ password: '', passwordconfirm: '' });
    }
  }
  _handleShowModal(showModal) {
    this.setState({ showModal });
  }
  _handleUsername(username) {
    this.setState({ errorUsername: false, username });
  }
  _handleShowpassword() {
    this.setState({ passwordType: !this.state.passwordType });
  }
  _handleShowpasswordConfirm() {
    this.setState({ passwordTypeConfirm: !this.state.passwordTypeConfirm });
  }
  _handleSubmit() {
    const { actions, admin } = this.props;

    if (!admin) {
      const payload = {
        password: CryptoJS.MD5(this.state.password).toString(),
        username: this.state.username,
        level: this.state.selectedItem + 1,
      };

      if (!this.state.password || !this.state.passwordconfirm) {
        this.setState({ errorMessage: 'Password tidak boleh kosong.' });
      } else if (this.state.password === this.state.passwordconfirm) {
        this.setState({ errorMessage: false, showModal: false });

        actions.updateUser(payload, null);
      } else {
        this.setState({ errorMessage: 'Password dan Password konfirmasi tidak sesuai.' });
      }
    } else {
      const payload = {
        username: this.state.username,
        level: this.state.selectedItem + 1,
      };

      actions.updateUser(payload, admin);
    }
  }

  getAdmin(level) {
    switch (level) {
      case 0:
        return 'Super Admin';
      case 1:
        return 'Supervisor';
      case 2:
        return 'CS';
    }
  }

  getLevel(level) {
    switch (level) {
      case 'Super Admin':
        return 0;
      case 'Supervisor':
        return 1;
      case 'CS':
        return 2;
    }
  }

  _renderField() {
    const { admin, classes } = this.props;
    const filterItem = ['Supervisor', 'CS'];
    const imagePassword = (this.state.passwordType)
      ? (<img id="show-password" onClick={this._handleShowpassword} src={IMAGES.EYE} />)
      : (<img id="show-password" onClick={this._handleShowpassword} src={IMAGES.EYE_OPEN} />);
    const imagePasswordConfirm = (this.state.passwordTypeConfirm)
      ? (<img id="show-password" onClick={this._handleShowpasswordConfirm} src={IMAGES.EYE} />)
      : (<img id="show-password" onClick={this._handleShowpasswordConfirm} src={IMAGES.EYE_OPEN} />);

    const password = (this.state.passwordType) ? 'password' : 'text';
    const passwordConfirm = (this.state.passwordTypeConfirm) ? 'password' : 'text';
    const propsUsername = {
      className: classes.textfield,
      input: {
        name: 'name',
        onChange: this._handleUsername.bind(this),
        value: this.state.username,
      },
      meta: {
        'icon-align': 'left',
        disabled: true,
        error: this.state.errorUsername,
        placeholder: 'Nama Lengkap',
        type: 'text',
        variant: 'basic-filled',
      },
    };
    const propsPassword = {
      className: classes.textfield,
      input: {
        name: 'password',
        onChange: this._handlePassword.bind(this),
        value: this.state.password,
      },
      meta: {
        'icon-align': 'left',
        error: this.state.errorPassword,
        iconRight: imagePassword,
        placeholder: (!this.state.errorPassword) ? 'Password Baru'
          : 'Silahkan masukan password Anda',
        type: password,
        variant: 'basic-filled',
      },
    };

    const propsPasswordKonfirm = {
      className: classes.textfield,
      input: {
        name: 'passwordconfirm',
        onChange: this._handlePasswordConfirm.bind(this),
        value: this.state.passwordconfirm,
      },
      meta: {
        'icon-align': 'left',
        error: this.state.errorPassword,
        iconRight: imagePasswordConfirm,
        placeholder: (!this.state.errorPassword) ? 'Ulangi Password'
          : 'Silahkan masukan password Anda',
        type: passwordConfirm,
        variant: 'basic-filled',
      },
    };
    return (
      <React.Fragment>
        <div className={classes.field}>
          <section className={classes.profile}>
            <img className={classes.avatar} src={IMAGES.AVATAR_BIG} />
            <div className={classes.profileLabel}>
              <label className={classes.profileTitle}>
                {(admin) ? admin.username : getUserData().username}
              </label>
              <label className={classes.profileRole}>
                {(admin) ? admin.level : this.getAdmin(getUserData().level)}
              </label>
            </div>
          </section>
          <section className={classes.modalField}>
            <label className={classes.textfieldLabel}>Name</label>
            <Textfield {...propsUsername} />
          </section>
          {(admin) ? (<section className={classes.modalField}>
            <label className={classes.textfieldLabel}>Role</label>
            <Dropdown className={classes.dropdownCreate} items={filterItem}
              onChange={this._handleChangeDropdown}
              selected-item={filterItem[this.state.selectedItem]}
              variant="white" />
          </section>) : ''}
          {(!admin) ? (<React.Fragment>
            <section className={classes.modalField}>
              <label className={classes.textfieldLabel}>Password</label>
              <Textfield {...propsPassword} />
            </section>
            <section className={classes.modalField}>
              <label className={classes.textfieldLabel}>Ulangi Password</label>
              <Textfield {...propsPasswordKonfirm} />
            </section>
          </React.Fragment>) : ''}
        </div>
        <section className={[classes.modalBottom].join(' ')}>
          <Button className={[classes.button, classes.buttonWhite].join(' ')} color="primary"
            onClick={this._handleReset.bind(this)} size="32">
            <label className={classes.buttonLabel}>
              Batal
            </label>
          </Button>
          <Button className={[classes.button].join(' ')} color="primary"
            onClick={this._handleSubmit.bind(this)} size="32">
            <label className={classes.buttonLabel}>
              Simpan
            </label>
          </Button>
        </section>
      </React.Fragment>
    );
  }

  render() {
    const { classes } = this.props;
    const blurred = (this.state.showModal) ? classes.blur : '';
    const errorMessage = (this.state.errorMessage) ? (
      <div className={classes.error}>
        {this.state.errorMessage}
      </div>
    ) : '';
    let modal = (this.props.modal) ? (
      <section className={classes.modalWrapper}>
        <span className={classes.modalOverlay} />
        <div className={classes.modal}>
          {
            (this.props.modal.success)
              ? <img src={IMAGES.STATUS_SUCCESS} />
              : <img src={IMAGES.STATUS_ERROR} />
          }
          <span className={classes.modalDivider} />
          <div className={classes.modalDescription}>
            {this.props.modal.message}
          </div>
          <section className={classes.modalButton} onClick={this._closeModal.bind(this)}>
            <Button className={[classes.buttonModal, classes.buttonWhite].join(' ')} color="primary"
              size="32">
              <label className={classes.buttonLabel}>
                OK
              </label>
            </Button>
          </section>
        </div>
      </section>
    ) : modal;
    return (
      <React.Fragment>
        <main className={blurred}>
          <Base {...this.props}>
            <section className={classes.contentWrapper}>
              <div className={classes.filterSection}>
                <label className={classes.title}>
                  Kelola Akun
                </label>
              </div>
              <div className={classes.form}>
                {errorMessage}
                {this._renderField()}
              </div>
            </section>
            {modal}
          </Base>
        </main>
      </React.Fragment>
    );
  }
}

Component.propTypes = {
  actions: PropTypes.object,
  admin: PropTypes.object,
  classes: PropTypes.object,
  history: PropTypes.object,
  isLoading: PropTypes.bool,
  modal: PropTypes.object,
  users: PropTypes.array,
};

Component.defaultProps = {
  actions: {},
  admin: {},
  classes: {},
  history: {},
  isLoading: false,
  modal: {},
  users: [],
};
