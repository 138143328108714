import fetch from '../../utils/fetch';
import { SERVICES } from '../../configs';
import { ACTIONS } from '../../constants';
import { checkType, getUserData, setUserData } from '../../utils/common';

export function fetchAllUsers() {
  return dispatch => {
    const options = {
      headers: { 
        'Authorization': `Bearer ${getUserData().userToken}`, 
      },
      method: 'GET',
      url: SERVICES.ADMIN,
    };

    dispatch(loading());
    
    fetch(options)
      .then(res => {
        if (res.data) {
          dispatch(setUsers(res.data));
          dispatch(doneLoading());
        }
      })
      .catch(() => {
        dispatch(doneLoading());
      });
  };
}

export function closeModal() {
  return dispatch => {
    dispatch({
      type: ACTIONS.HIDE_MODAL,
    });
  };
}

export function updateUser(payload, admin) {
  return dispatch => {
    const options = {
      headers: { 
        'Authorization': `Bearer ${getUserData().userToken}`, 
      },
      data: payload,
      method: 'PUT',
      url: SERVICES.ADMIN,
    };

    fetch(options)
      .then(() => {
        if (!admin) {
          setUserData({
            ...getUserData(),
            ...payload
          }, checkType());
        }

        dispatch({
          type: ACTIONS.SHOW_MODAL,
          modal: {
            success: true,
            message: 'Data berhasil di update' 
          },
        });
      })
      .catch(() => {
        dispatch({
          type: ACTIONS.SHOW_MODAL,
          modal: {
            success: false,
            message: 'Data tidak berhasil di update' 
          },
        });
      });
  };
}

function doneLoading() {
  return {
    type: ACTIONS.DONE_LOADING,
  };
}

function loading() {
  return {
    type: ACTIONS.LOADING,
  };
}

function setUsers(users) {
  return {
    type: ACTIONS.FETCH_ADMIN,
    users
  };
}
