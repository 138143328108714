/* eslint-disable */
const styles = {
  button: {
    backgroundColor: '#EE3124 !important',
    borderRadius: '5px !important',
    height: '38px !important',
    width: '155px'
  },
  buttonLabel: {
    cursor: 'pointer',
    marginLeft: '30px',
  },
  buttonWrapper: {
    marginTop: '11px',
    padding: '0 30px',
    textAlign: 'right',
    width: '100%',
  },
  captionCards: {
    fontFamily: 'Poppins',
    fontSize: '18px',
    fontStyle: 'normal',
    fontWeight: 600,
    lineHeight: '27px',
  },
  card: {
    background: '#FFFFFF',
    border: '1px solid #D9DEE4',
    borderRadius: '5px',
    boxSizing: 'border-box',
    display: 'flex',
    flexDirection: 'column',
    padding: '35px 34px 24px 34px',
    width: '49%',
  },
  cardSummary: {
    alignItems: 'center',
    background: '#FFFFFF',
    border: '1px solid #D9DEE4',
    borderRadius: '5px',
    boxSizing: 'border-box',
    display: 'flex',
    flexDirection: 'row',
    height: '208px',
    justifyContent: 'space-around',
    padding: '35px 34px 24px 34px',
    width: '49%',
  },
  cardSummary3: {
    width: '30%',
  },
  cardSummaryWrapper: {
    display: 'flex',
  },
  cardWrapper: {
    display: 'flex',
    justifyContent: 'space-between',
    position: 'relative',
  },
  chartWrapper: {
    position: 'relative',
    width: '100%',
  },
  checkWrapper: {
    alignItems: 'center',
    background: 'rgba(111, 207, 151, 0.5)',
    borderRadius: '100%',
    display: 'flex',
    height: '107px',
    justifyContent: 'center',
    width: '107px',
  },
  countCards: {
    fontFamily: 'Poppins',
    fontSize: '30px',
    fontStyle: 'normal',
    fontWeight: 500,
    lineHeight: '45px',
    marginRight: '11px'
  },
  crossWrapper: {
    alignItems: 'center',
    background: 'rgba(235, 121, 114, 0.5)',
    borderRadius: '100%',
    display: 'flex',
    height: '107px',
    justifyContent: 'center',
    width: '107px',
  },
  countIncrement: {
    fontSize: '18px',
    fontWeight: 500,
    lineHeight: '27px',
  },
  countIncrementDown: {
    '&:before': {
      content: '"\▾"',
      marginBottom: '5px',
      marginRight: '5px',
    },
    color: '#CF212A',
  },
  countIncrementUp: {
    '&:before': {
      content: '"\▴"',
      marginBottom: '5px',
      marginRight: '5px',
    },
    color: '#44AB4A',
  },
  countWrapper: {
    alignItems: 'center',
    display: 'flex',
    marginTop: '4px',
    marginBottom: '15px',
  },
  datePicker: {
    alignItems: 'center',
    background: '#FFFFFF',
    boxShadow: '0px 2px 8px rgba(0, 0, 0, 0.1)',
    display: 'flex',
    flexDirection: 'column',
    padding: '21px 0',
    position: 'absolute',
    right: 0,
    width: '320px',
    zIndex: 1,
  },
  dropdown: {
    width: '150px',
  },
  filterSection: {
    alignItems: 'center',
    display: 'flex',
    justifyContent: 'space-between',
  },
  filterWrapper: {
    position: 'relative',
  },
  input: {
    '&:focus': {
      outline: 'none',
    },
    border: 'none',
    height: '15px',
    fontSize: '12px',
    letterSpacing: '0.25px',
    lineHeight: '14px',
    textDecoration: 'none',
    width: '75px',
  },
  inputWrapper: {
    alignItems: 'center',
    backgroundColor: 'white',
    border: '1px solid #828282',
    boxSizing: 'border-box',
    borderRadius: '5px',
    display: 'flex',
    height: '37px',
    justifyContent: 'space-between',
    padding: '0px 15px',
    width: '256px',
  },
  labelRed: {
    color: '#EE3124',
  },
  resultFilter: {
    color: '#333333',
    fontWeight: 500,
    fontSize: '12px',
    lineHeight: '14px',
    letterSpacing: '0.25px',
    marginBottom: '10px',
    textAlign: 'right',
  },
  search: {
    alignItems: 'center',
    background: '#ffffff',
    border: '1px solid #D9DEE4',
    borderRadius: '5px',
    boxSizing: 'border-box',
    display: 'flex',
    height: '78px',
    padding: '0px 24px',
  },
  summaryCaption: {
    color: '#4F4F4F',
    fontWeight: 600,
    fontSize: '14px',
    lineHeight: '21px',
  },
  summaryCount: {
    color: '#828282',
    fontWeight: 600,
    fontSize: '48px',
    lineHeight: '72px',
  },
  summaryIncreasement: {
    fontWeight: 500,
    fontSize: '14px',
    lineHeight: '21px',
  },
  summaryInfoWrapper: {
    alignItems: 'center',
    display: 'flex',
    flexDirection: 'column',
  },
  searchLabel: {
    fontSize: '18px',
    fontStyle: 'normal',
    fontWeight: 600,
    lineHeight: '27px',
    marginLeft: '15px',
    marginRight: '16px',
  },
  table: {
    marginTop: '30px',
  },
  textfieldWrap: {
    flexGrow: 1,
    marginLeft: '25px',
    marginRight: '24px',
  },
  title: {
    fontSize: '24px',
    fontWeight: 'bold',
    lineHeight: '36px',
  }
};

export default styles;
