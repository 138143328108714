const purple = {
  10: '#F5F0FA',
  20: '#EBDBFA',
  30: '#DABBF7',
  40: '#C79CF2',
  50: '#AD73E8',
  60: '#9657D6',
  70: '#7D3CBE',
  80: '#632B9C',
  90: '#4A2173',
  100: '#381D54',
};

export default purple;
