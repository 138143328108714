const styles = {
  blur: {
    filter: 'blur(5px)',
    WebkitFilter: 'blur(5px)',
  },
  button: {
    backgroundColor: '#EE3124 !important',
    borderRadius: '5px !important',
    height: '38px !important',
    width: '191px'
  },
  buttonModal: {
    borderRadius: '5px !important',
    height: '50px !important',
    margin: 0,
    width: '320px',
  },
  buttonModalTiny: {
    borderRadius: '5px !important',
    height: '48px !important',
    width: '156px',
  },
  buttonWhite: {
    backgroundColor: 'white !important',
    border: '1px solid #EE3124 !important',
    color: '#EE3124 !important',
  },
  dropdownCreate: {
    width: '498px',
  },
  filterSection: {
    alignItems: 'center',
    background: '#FFFFFF',
    border: '1px solid #D9DEE4',
    borderCollapse: 'collapse',
    borderStyle: 'hidden',
    borderTopLeftRadius: '5px',
    borderTopRightRadius: '5px',
    boxShadow: '0 0 0 1px #D9DEE4',
    boxSizing: 'border-box',
    display: 'flex',
    height: '68px',
    justifyContent: 'space-between',
    marginBottom: '-30px',
    paddingLeft: '18px',
    paddingRight: '24px',
  },
  modal: {
    alignItems: 'center',
    background: 'white',
    borderRadius: '5px',
    display: 'flex',
    flexDirection: 'column',
    height: '653px',
    justifyContent: 'flex-start',
    padding: '28px 37px',
    position: 'fixed',
    width: '598px',
  },
  modalBottom: {
    bottom: '55px',
    position: 'absolute',
  },
  modalButton: {
    marginTop: '28px',
  },
  modalButtonTiny: {
    display: 'flex',
    justifyContent: 'space-between',
    marginTop: '43px',
    width: '100%',
  },
  modalClose: {
    alignItems: 'center',
    background: '#EE3124',
    borderRadius: '100%',
    bottom: '97%',
    color: 'white',
    cursor: 'pointer',
    display: 'flex',
    height: '39px',
    justifyContent: 'center',
    left: '97%',
    position: 'absolute',
    width: '39px',
  },
  modalDescription: {
    color: 'rgba(0, 0, 0, 0.54)',
    fontSize: '23px',
    fontStyle: 'normal',
    fontWeight: 500,
    lineHeight: '34px',
    marginTop: '18px',
    textAlign: 'center',
  },
  modalDescriptionTiny: {
    color: 'rgba(0, 0, 0, 0.54)',
    fontSize: '24px',
    fontStyle: 'normal',
    fontWeight: 600,
    lineHeight: '34px',
    textAlign: 'center',
  },
  modalDivider: {
    background: '#EE3124',
    borderRadius: '7px',
    display: 'block',
    height: '7px',
    marginTop: '4px',
    width: '70px',
  },
  modalField: {
    marginBottom: '17px',
  },
  modalOverlay: {
    background: 'rgb(0,0,0,0.4)',
    display: 'block',
    height: '100%',
    width: '100%',
  },
  modalSmall: {
    height: '312px',
  },
  modalTiny: {
    height: '211px',
    padding: '41px 44px',
    width: '422px',
  },
  modalTitle: {
    fontSize: '24px',
    fontWeight: 500,
    lineHeight: '28px',
    margin: 0,
    marginBottom: '31px',
    marginTop: '22px',
  },
  modalWrapper: {
    alignItems: 'center',
    display: 'flex',
    fontFamily: 'Poppins',
    height: '100vh',
    justifyContent: 'center',
    left: 0,
    position: 'fixed',
    top: 0,
    width: '100vw',
  },
  search: {
    alignItems: 'center',
    background: '#ffffff',
    border: '1px solid #D9DEE4',
    borderRadius: '5px',
    boxSizing: 'border-box',
    display: 'flex',
    height: '78px',
    padding: '0px 24px',
  },
  searchLabel: {
    fontSize: '18px',
    fontStyle: 'normal',
    fontWeight: 600,
    lineHeight: '27px',
    marginLeft: '15px',
    marginRight: '16px',
  },
  table: {
    marginTop: '30px',
  },
  textfield: {
    width: '498px',
  },
  textfieldWrap: {
    flexGrow: 1,
    marginLeft: '25px',
    marginRight: '24px',
  },
  title: {
    color: '#000000',
    fontSize: '18px',
    fontWeight: 600,
    lineHeight: '27px',
  },
};

export default styles;
