const styles = {
  grid: {
    stroke: '#D9DEE4',
    strokeDasharray: 0,
    strokeWidth: 1,
  },
  label: {
    display: 'flex',
    justifyContent: 'space-between',
    marginTop: '20px',
  },
  text: {
    width: '35px'
  }
};

export default styles;
