import fetch, { getBasicSooltancam } from '../../utils/fetch';
import moment from 'moment';
import { SERVICES } from '../../configs';
import { ACTIONS } from '../../constants';
import { getEazy, getUserData } from '../../utils/common';

export function fetchDevices(page, queryObject = false, query = '', date) {
  return dispatch => {
    let params = {
      download: false,
      page,
      size: 10,
      startDate: date ? moment(date.split(' - ')[0]).format('YYYY-MM-DD') : null,
      endDate: date ? moment(date.split(' - ')[1]).format('YYYY-MM-DD') : null
    };

    if (query && queryObject) {
      params[`${queryObject}`] = query;
    }
    let options = {
      method: 'GET',
      url: SERVICES.DOWNLOAD_DEVICE,
      params,
      headers: { 
        'Authorization': `Bearer ${getUserData().userToken}`, 
      }
    };

    if (getBasicSooltancam()) {
      delete options.headers;

      options.auth = getBasicSooltancam();
      options.url = SERVICES.DOWNLOAD_DEVICE_SOOLTANCAM;
    }

    if (getEazy() === 'true') {
      options.params = {
        ...options.params,
        isEazy: true
      };
    }

    dispatch(loading());
    
    fetch(options)
      .then(res => {
        if (res.data) {
          dispatch(setDevices(res.data.list, page, res.data.totalData));
          dispatch(doneLoading());
        }
      })
      .catch(() => {
        dispatch(doneLoading());
        dispatch(setDevices([], 1, 0));
      });
  };
}

export function fetchAllDevices(page, queryObject = false, query = '',size = 10, showModalDownload) {
  return dispatch => {
    let params = {
      download: true,
      page,
      size,
    };

    if (query && queryObject) {
      params[`${queryObject}`] = query;
    }
    let options = {
      method: 'GET',
      url: SERVICES.DOWNLOAD_DEVICE,
      params,
      headers: { 
        'Authorization': `Bearer ${getUserData().userToken}`, 
      }
    };

    if (getBasicSooltancam()) {
      delete options.headers;

      options.auth = getBasicSooltancam();
      options.url = SERVICES.DOWNLOAD_DEVICE_SOOLTANCAM;
    }

    if (getEazy() === 'true') {
      options.params = {
        ...options.params,
        isEazy: true
      };
    }

    dispatch(loading());
    
    fetch(options)
      .then(res => {
        if (res.data) {
          download(res.data.url, 'all-devices');
          dispatch(doneLoading());
          showModalDownload();
        }
      })
      .catch(() => {
        dispatch(doneLoading());
      });
  };
}

export function deleteItem(item) {
  return dispatch => {
    const params = item.device_brand === 'icode' ? {
      brand: item.device_brand,
      ndmain: item.msisdn,
      deviceSerial: item.serial_number,
      device_db_id: 0
    } : {
      brand: item.device_brand,
      userId: item.userId,
      deviceSerial: item.serial_number,
    };

    let options = {
      method: 'DELETE',
      url: item.device_brand === 'icode' ? SERVICES.DEVICE_V1 : SERVICES.DEVICE,
      params,
      headers: { 
        'Authorization': `Bearer ${getUserData().userToken}`, 
      }
    };

    if (getBasicSooltancam()) {
      delete options.headers;

      options.auth = getBasicSooltancam();
      options.url = SERVICES.DEVICE_SOOLTANCAM;
    }

    dispatch(loading());
    
    fetch(options)
      .then(() => {
        dispatch({
          type: ACTIONS.SELECT_DEVICE,
          device: {}
        });

        dispatch(doneLoading());
      })
      .catch(() => {
        dispatch(doneLoading());
      });
  };
}

export function resetList() {
  return dispatch=> {
    dispatch({
      type: ACTIONS.RESET_LIST_DEVICES,
    });
  };
}

export function selectDevice(device) {
  return dispatch => {
    dispatch({
      type: ACTIONS.SELECT_DEVICE,
      device
    });
  };
}

function doneLoading() {
  return {
    type: ACTIONS.DONE_LOADING,
  };
}

function loading() {
  return {
    type: ACTIONS.LOADING,
  };
}

function setDevices(devices, page, totalData) {
  return {
    type: ACTIONS.FETCH_LIST_DEVICES,
    devices,
    page,
    totalData
  };
}

function download(dataurl, filename) {
  const link = document.createElement('a');
  link.href = dataurl;
  link.download = filename;
  link.click();
}
