import { EAZY_DATA, EXPIRE_TIME_STORAGE, REMEMBER_SESSION, SOOLTANCAM_DATA, USER_DATA_STORAGE } from '../configs';

export function clearStorages() {
  localStorage.removeItem(EXPIRE_TIME_STORAGE);
  localStorage.removeItem(REMEMBER_SESSION);
  localStorage.removeItem(USER_DATA_STORAGE);
  localStorage.removeItem(SOOLTANCAM_DATA);
  sessionStorage.removeItem(EXPIRE_TIME_STORAGE);
  sessionStorage.removeItem(REMEMBER_SESSION);
  sessionStorage.removeItem(USER_DATA_STORAGE);
}

export function setExpireTime(value, type) {
  const timeObject = new Date();

  if (type === 'local') {
    localStorage.setItem(EXPIRE_TIME_STORAGE, new Date(timeObject.getTime() + value * 1000));
  } else if (type === 'session') {
    sessionStorage.setItem(EXPIRE_TIME_STORAGE, new Date(timeObject.getTime() + value * 1000));
  }
}

export function checkExpireTime() {
  const time = new Date().getTime();
  const expire = (localStorage.getItem(EXPIRE_TIME_STORAGE)) 
    ? localStorage.getItem(EXPIRE_TIME_STORAGE) 
    : (sessionStorage.getItem(EXPIRE_TIME_STORAGE) 
      ? sessionStorage.getItem(EXPIRE_TIME_STORAGE) : 0);  

  return time > new Date(expire).getTime();
}

export function setUserData(value, type) {
  if (type === 'local') {
    localStorage.setItem(USER_DATA_STORAGE, JSON.stringify(value));
  } else if (type === 'session') {
    sessionStorage.setItem(USER_DATA_STORAGE, JSON.stringify(value));
  }
}

export function setRemember(remember, type) {
  if (type === 'local') {
    localStorage.setItem(REMEMBER_SESSION, remember);
  } else if (type === 'session') {
    sessionStorage.setItem(REMEMBER_SESSION, remember);
  }
}

export function getRemember() {
  const retval = (localStorage.getItem(REMEMBER_SESSION))
    ? localStorage.getItem(REMEMBER_SESSION) 
    : (sessionStorage.getItem(REMEMBER_SESSION) 
      ? sessionStorage.getItem(REMEMBER_SESSION) : null);

  return retval;
}

export function getUserData() {
  const retval = (localStorage.getItem(USER_DATA_STORAGE))
    ? localStorage.getItem(USER_DATA_STORAGE)
    : (sessionStorage.getItem(USER_DATA_STORAGE)
      ? sessionStorage.getItem(USER_DATA_STORAGE) : null);

  return JSON.parse(retval);
}

export function checkType() {
  const retval = (localStorage.getItem(USER_DATA_STORAGE))
    ? 'local'
    : (sessionStorage.getItem(USER_DATA_STORAGE)
      ? 'session' : null);

  return retval;
}

export function setSooltanCam(value) {
  localStorage.setItem(SOOLTANCAM_DATA, value);
}

export function getSooltanCam() {
  const retval = localStorage.getItem(SOOLTANCAM_DATA);

  return retval;
}

export function setEazy(value) {
  localStorage.setItem(EAZY_DATA, value);
}

export function getEazy() {
  const retval = localStorage.getItem(EAZY_DATA);

  return retval;
}
