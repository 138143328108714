const sky = {
  10: '#EAF5FA',
  20: '#BDE6FA',
  30: '#8DD4F7',
  40: '#54BAED',
  50: '#229BD8',
  60: '#0C7AB0',
  70: '#066493',
  80: '#064C6F',
  90: '#093953',
  100: '#0C2837',
};

export default sky;
