import { combineReducers } from 'redux';
import { routerReducer } from 'react-router-redux';
import { reducer as formReducer } from 'redux-form';
import admin from '../pages/Admin/reducer';
import base from '../components/layouts/Pagebase/reducer';
import dashboard from '../pages/Dashboard/reducer';
import device from '../pages/ListDevice/reducer';
import editAdmin from '../pages/EditProfile/reducer';
import loading from './loading';
import logdevice from '../pages/LogDevice/reducer';
import logemail from '../pages/LogEmail/reducer';
import login from '../pages/Login/reducer';
import subscribers from '../pages/ListCustomer/reducer';
import users from '../pages/Admin/reducer';

const rootReducer = combineReducers({
  admin,
  base,
  dashboard,
  device,
  editAdmin,
  form: formReducer,
  loading,
  logdevice,
  logemail,
  login,
  routing: routerReducer,
  subscribers,
  users,
});

export default rootReducer;
