import { ACTIONS } from '../../constants';

const initialState = {
  users: [],
  modal: false
};

export default function reducer(state = initialState, action) {
  const { users, modal, type } = action;
  
  switch (type) {
    case ACTIONS.FETCH_ADMIN:
      return {
        ...state,
        users,
      };
    case ACTIONS.SHOW_MODAL: 
      return {
        ...state,
        modal
      };
    case ACTIONS.HIDE_MODAL: 
      return {
        ...state,
        modal: false,
      };
    default:
      return {
        ...state
      };
  }
}
