import { ACTIONS } from '../../constants';

const initialState = {
  logs: [],
};

export default function reducer(state = initialState, action) {
  const { logs, type } = action;
  
  switch (type) {
    case ACTIONS.FETCH_LIST_LOG:
      return {
        ...state,
        logs,
      };
    default:
      return {
        ...state
      };
  }
}
