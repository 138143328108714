const styles = {
  bottomCenter: {
    '&:after': {
      backgroundColor: 'white',
      boxShadow: '0px 1px 20px rgba(0, 0, 0, 0.2)',
      content: '""',
      left: 'calc(50% + 4px)',
      position: 'absolute',
      top: 0,
      transform: 'rotate(-225deg)',
      transformOrigin: '0 0'
    }
  },
  bottomLeft: {
    '&:after': {
      backgroundColor: 'white',
      boxShadow: '0px 1px 20px rgba(0, 0, 0, 0.2)',
      content: '""',
      left: '15px',
      position: 'absolute',
      top: 0,
      transform: 'rotate(-225deg)',
      transformOrigin: '0 0'
    }
  },
  bottomRight: {
    '&:after': {
      backgroundColor: 'white',
      boxShadow: '0px 1px 20px rgba(0, 0, 0, 0.2)',
      content: '""',
      position: 'absolute',
      right: 0,
      top: 0,
      transform: 'rotate(-225deg)',
      transformOrigin: '0 0'
    }
  },
  leftBottom: {
    '&:after': {
      backgroundColor: 'white',
      bottom: '4px',
      boxShadow: '0px 1px 20px rgba(0, 0, 0, 0.2)',
      content: '""',
      position: 'absolute',
      right: 0,
      transform: 'rotate(45deg)',
      transformOrigin: '100% 100%'
    }
  },
  leftCenter: {
    '&:after': {
      backgroundColor: 'white',
      boxShadow: '0px 1px 20px rgba(0, 0, 0, 0.2)',
      content: '""',
      position: 'absolute',
      right: 0,
      top: 'calc(50% - 4.25px)',
      transform: 'rotate(-45deg)',
      transformOrigin: '100% 0'
    }
  },
  leftTop: {
    '&:after': {
      backgroundColor: 'white',
      boxShadow: '0px 1px 20px rgba(0, 0, 0, 0.2)',
      content: '""',
      position: 'absolute',
      right: 0,
      top: '4px',
      transform: 'rotate(-45deg)',
      transformOrigin: '100% 0'
    }
  },
  mainWrapper: {
    display: 'inline-block',
    position: 'relative'
  },
  rightBottom: {
    '&:after': {
      backgroundColor: 'white',
      bottom: '4px',
      boxShadow: '0px 1px 20px rgba(0, 0, 0, 0.2)',
      content: '""',
      left: 0,
      position: 'absolute',
      transform: 'rotate(-45deg)',
      transformOrigin: '0 100%'
    }
  },
  rightCenter: {
    '&:after': {
      backgroundColor: 'white',
      boxShadow: '0px 1px 20px rgba(0, 0, 0, 0.2)',
      content: '""',
      left: 0,
      position: 'absolute',
      top: 'calc(50% - 4.25px)',
      transform: 'rotate(45deg)',
      transformOrigin: '0 0'
    }
  },
  rightTop: {
    '&:after': {
      backgroundColor: 'white',
      boxShadow: '0px 1px 20px rgba(0, 0, 0, 0.2)',
      content: '""',
      left: 0,
      position: 'absolute',
      top: '4px',
      transform: 'rotate(45deg)',
      transformOrigin: '0 0'
    }
  },
  root: {
    backgroundColor: 'white',
    boxShadow: '0px 1px 20px rgba(0, 0, 0, 0.2)',
    position: 'absolute'
  },
  tooltip: {
    alignItems: 'center',
    backgroundColor: 'white',
    borderRadius: '1px',
    boxSizing: 'border-box',
    display: 'flex',
    height: '24px',
    lineHeight: '12px',
    maxWidth: '180px',
    minWidth: '21px',
    padding: '6px 5px',
    position: 'relative',
    width: 'max-content',
    zIndex: '999'
  },
  topCenter: {
    '&:after': {
      backgroundColor: 'white',
      bottom: '-6px',
      boxShadow: '0px 1px 20px rgba(0, 0, 0, 0.2)',
      content: '""',
      left: 'calc(50% + 4px)',
      position: 'absolute',
      transform: 'rotate(-225deg)',
      transformOrigin: '0 0'
    }
  },
  topLeft: {
    '&:after': {
      backgroundColor: 'white',
      bottom: 0,
      boxShadow: '0px 1px 20px rgba(0, 0, 0, 0.2)',
      content: '""',
      left: '6px',
      position: 'absolute',
      transform: 'rotate(45deg)',
      transformOrigin: '0 100%'
    }
  },
  topRight: {
    '&:after': {
      backgroundColor: 'white',
      bottom: 0,
      boxShadow: '0px 1px 20px rgba(0, 0, 0, 0.2)',
      content: '""',
      position: 'absolute',
      right: '6px',
      transform: 'rotate(-45deg)',
      transformOrigin: '100% 100%'
    }
  }
};
export default styles;
