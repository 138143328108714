import fetch from '../../utils/fetch';
import { SERVICES } from '../../configs';
import { ACTIONS } from '../../constants';
import { getUserData } from '../../utils/common';

export function fetchAllUsers() {
  return dispatch => {
    const options = {
      headers: { 
        'Authorization': `Bearer ${getUserData().userToken}`, 
      },
      method: 'GET',
      url: SERVICES.ADMIN,
    };

    dispatch(loading());
    
    fetch(options)
      .then(res => {
        if (res.data) {
          res.data.map((item) => {
            switch (item.level) {
              case 0:
                item.level = 'Super Admin';
                break;
              case 1:
                item.level = 'Supervisor';
                break;
              case 2:
                item.level = 'CS';
                break;
              case 3:
                item.level = 'API';
                break;
              case 4:
                item.level = 'Agent 147';
                break;
            }
          });

          dispatch(setUsers(res.data));
          dispatch(doneLoading());
        }
      })
      .catch(() => {
        dispatch(doneLoading());
      });
  };
}

export function closeModal() {
  return dispatch => {
    dispatch({
      type: ACTIONS.HIDE_MODAL,
    });
  };
}

export function createUser(payload, users) {
  return dispatch => {
    const options = {
      headers: { 
        'Authorization': `Bearer ${getUserData().userToken}`,   
      },
      data: payload,
      method: 'POST',
      url: SERVICES.ADMIN_NEW,
    };

    fetch(options)
      .then(() => {
        switch (parseInt(payload.level)) {
          case 0:
            payload.level = 'Super Admin';
            break;
          case 1:
            payload.level = 'Supervisor';
            break;
          case 2:
            payload.level = 'CS';
            break;
          case 3:
            payload.level = 'API';
            break;
          case 4:
            payload.level = 'Agent 147';
            break;
        }

        let usersNew = [
          ...users,
          payload,
        ];
        
        dispatch(setUsers(usersNew));
        dispatch({
          type: ACTIONS.SHOW_MODAL,
          modal: {
            success: true,
            message: 'Admin berhasil ditambah.'
          }
        });
      })
      .catch(() => {
        dispatch({
          type: ACTIONS.SHOW_MODAL,
          modal: {
            success: false,
            message: 'Admin tidak berhasil ditambah.'
          }
        });
      });
  };
}

export function deleteAdmin(username, users) {
  return dispatch => {
    const options = {
      headers: { 
        'Authorization': `Bearer ${getUserData().userToken}`,   
      },
      method: 'DELETE',
      url: SERVICES.ADMIN + '?username=' + username,
    };
    fetch(options)
      .then(() => {
        dispatch(setUsers(users));

        dispatch({
          type: ACTIONS.HIDE_MODAL,
        });

        dispatch({
          type: ACTIONS.SHOW_MODAL,
          modal: {
            success: true,
            message: 'Admin berhasil dihapus.'
          }
        });
      })
      .catch(() => {
        dispatch({
          type: ACTIONS.SHOW_MODAL,
          modal: {
            success: false,
            message: 'Admin tidak berhasil dihapus.'
          }
        });
      });
  };
}

export function selectAdmin(admin) {
  return dispatch => {
    dispatch({
      type: ACTIONS.SELECT_ADMIN,
      admin
    });
  };
}

export function showDeleteModal(item, index) {
  return dispatch => {
    dispatch({
      type: ACTIONS.SHOW_MODAL_DELETE,
      modalDelete: {
        text: `Hapus user '${item.username}'?`,
        ...item,
        index,
      }
    });
  };
}

function doneLoading() {
  return {
    type: ACTIONS.DONE_LOADING,
  };
}

function loading() {
  return {
    type: ACTIONS.LOADING,
  };
}

function setUsers(users) {
  return {
    type: ACTIONS.FETCH_ADMIN,
    users
  };
}
