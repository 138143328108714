import React from 'react';
import PropTypes from 'prop-types';
import { capitalize } from '../../../utils/helpers';

export default class Component extends React.Component {
  _mapping = {
    body1: 'p',
    body2: 'p',
    button: 'a',
    caption: 'a',
    h1: 'h1',
    h2: 'h2',
    h3: 'h3',
    h4: 'h4',
    h5: 'h5',
    overline1: 'b',
    overline2: 'b',
    subtitle1: 'h6',
    subtitle2: 'h6'
  };

  render() {
    const{
      children,
      classes,
      className: classNameProp,
      color,
      noWrap,
      tag,
      type,
      variant
    } = this.props;

    const className = [
      classes.root,
      {
        [classes[variant]]: variant !== 'inherit',
        [classes[
          `color${capitalize(color)}${color === 'primary' || color === 'secondary' ? capitalize(type) : ''
          }`
        ]]: color !== 'default',
        [classes[noWrap]]: noWrap
      },
      classNameProp
    ].join(' ');

    const VarianMapping = tag ? tag : this._mapping[variant];
    return <VarianMapping className={className}>{children}</VarianMapping>;
  }
}

Component.propTypes = {
  /**
   *  The content of the component.
   */
  children: PropTypes.node.isRequired,

  /**
   * Override or extend the styles applied to the component.
   * See [CSS API](#css) below for more details.
   */
  classes: PropTypes.object.isRequired,

  /**
  * @ignore
  */
  className: PropTypes.string,

  /**
   *  The color of the component. It supports those theme colors that make sense for this component.
   */
  color: PropTypes.oneOf(['default', 'primary', 'secondary', 'basePrimary', 'baseSecondary', 'success', 'error', 'information', 'warning']),

  /**
   *  If `true`, the text will not wrap, but instead will truncate with an ellipsis.
   */
  noWrap: PropTypes.bool,

  /**
  * We are empirically mapping the variant property to a range of different DOM element types.
  * For instance, subtitle1 to `<h6>`.
  * If you wish to change that mapping, you can provide your own.
  * Alternatively, you can use the `component` property.
  */
  tag: PropTypes.string,

  /**
   * Mapping typical light or dark  
   */
  type: PropTypes.oneOf(['light', 'dark', 'main']),

  /**
  * Applies the theme typography styles.
  */
  variant: PropTypes.oneOf(['h1', 'h2', 'h3', 'h4', 'h5', 'subtitle1', 'subtitle2', 'body1', 'body2', 'button', 'caption', 'overline1', 'overline2'])
};

Component.defaultProps = {
  className: null,
  color: 'default',
  noWrap: false,
  tag: 'p',
  type: 'main',
  variant: 'body1',
};
