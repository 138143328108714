import { Grey, Red } from '../Colors';

const styles = theme => ({
  '@global': {
    'input': {
      '&:-webkit-autofill': {
        WebkitBoxShadow: '0 0 0 30px white inset'
      },
      '&:disabled': {
        backgroundColor: 'inherit'
      }
    }
  },
  content: {
    content: '',
    display: 'block',
    paddingTop: '100%'
  },
  formActive: {
    boxSizing: 'border-box',
    MozBoxSizing: 'border-box',
    WebkitBoxSizing: 'border-box'
  },
  formColorPreview: {
    border: 'solid ' + theme.palette.base.primary.main,
    borderRadius: '4px',
    borderWidth: '0px 0.5px 0px 0px',
    maxHeight: '100%',
    minHeight: '99%',
    position: 'relative',
    width: '50%'
  },
  formIcon: {
    margin: '0 -5px 0 5px',
    maxHeight: '40%',
    width: '10%'
  },
  formInput: {
    '&:-moz-placeholder': {
      color: '#C5D0DE',
      fontFamily: 'Poppins',
      fontSize: '13px',
      fontStyle: 'normal',
      fontWeight: 'normal',
      lineHeight: '12px',
    },
    '&:-ms-input-placeholder': {
      color: '#C5D0DE',
      fontFamily: 'Poppins',
      fontSize: '13px',
      fontStyle: 'normal',
      fontWeight: 'normal',
      lineHeight: '12px',
    },
    '&:-webkit-autofill': {
      color: 'grey',
      WebkitBoxShadow: '0 0 0 30px white inset'
    },
    '&::-moz-placeholder': {
      color: '#C5D0DE',
      fontFamily: 'Poppins',
      fontSize: '13px',
      fontStyle: 'normal',
      fontWeight: 'normal',
      lineHeight: '12px',
    },
    '&::-webkit-input-placeholder': {
      color: '#C5D0DE',
      fontFamily: 'Poppins',
      fontSize: '13px',
      fontStyle: 'normal',
      fontWeight: 'normal',
      lineHeight: '12px',
    },
    '&::placeholder': {
      color: '#C5D0DE',
      fontFamily: 'Poppins',
      fontSize: '13px',
      fontStyle: 'normal',
      fontWeight: 'normal',
      lineHeight: '12px',
    },
    '&:focus': {
      outline: 'none'
    },
    '&:focus + label': {
      fontWeight: '600',
      transform: 'translate(0, 0) scale(1) !important',
      transformOrigin: 'left bottom',
      WebkitTransform: 'translate(0, 0) scale(1) !important',
    },
    '&:placeholder-shown + label': {
      cursor: 'text',
      maxWidth: '62%',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      transform: 'translate(0, 0.8rem) scale(1.3)',
      transformOrigin: 'left bottom',
      WebkitTransform: 'translate(0, 0.8rem) scale(1.3)',
      whiteSpace: 'nowrap',
    },
    border: '0px',
    fontFamily: 'Poppins',
    fontSize: '13px',
    fontStyle: 'normal',
    fontWeight: 'normal',
    lineHeight: '12px',
    width: '99%'
  },
  formInputColor: {
    width: '69% !important'
  },
  formInputDisabled: {
    color: `${Grey[50]} !important`,
    cursor: 'not-allowed !important'
  },
  formInputError: {
    '&::placeholder': {      
      color: `#E45656`
    },
  },
  formInputFilled: {
    backgroundColor: 'white'
  },
  formInputFilledHover: {
    backgroundColor: 'white'
  },
  formInputTextLabel: {
    color: '#C5D0DE',
    fontSize: '10px',
    textTransform: 'capitalize',
    transition: 'all 0.2s'
  },
  formInputTextLabelError: {
    color: Red[60]
  },
  formInputTextWrap: {
    display: 'flex',
    flex: '1',
    flexDirection: 'column-reverse',
    maxWidth: '100%',
    padding: '15px 0px'
  },
  formInputWrap: {
    alignContent: 'center',
    alignItems: 'center',
    background: 'white',
    border: '1px solid #D9DEE4',
    borderRadius: '3px',
    boxSizing: 'border-box',
    display: 'flex',
    fontFamily: theme.typography.fontFamily,
    height: '38px',
    MozBoxSizing: 'border-box',
    padding: '0px 16px',
    WebkitBoxSizing: 'border-box',
    width: '100%'
  },
  formInputWrapDisabled: {
    borderBottom: 'solid ' + Grey[50],
    color: Grey[50],
    cursor: 'not-allowed'
  },
  formInputWrapError: {
    border: '1px solid #EE3124',
  },
  formInputWrapFilled: {
    backgroundColor: 'white',
  },
  formInputWrapFilledHover: {
    backgroundColor: 'white'
  },
  formLabelActive: {
    color: '#EE3124',
    fontWeight: '600'
  },
  helperWrap: {
    color: Grey[90],
    margin: '4px 8px'
  },
  helperWrapError: {
    color: Red[60]
  },
  iconLeft: {
    flexDirection: 'row'
  },
  iconRight: {
    flexDirection: 'row-reverse'
  },
  iconWrap: {
    color: Grey[90],
    display: 'flex',
    fontSize: '20px',
    marginRight: '11px'
  },
  password: {
    fontSize: '35px',
  },
  textarea: {
    height: '15px',
    resize: 'none'
  },
  textareaHideScroll: {
    '&::-webkit-scrollbar': {
      background: 'transparent !important',
      width: '0px !important'
    }
  },
  textAreaVariant: {
    height: '150px'
  }
});

export default styles;
