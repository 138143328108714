import { ACTIONS } from '../../constants';

const initialState = {
  labelActive: [],
  valueActive: [],
  labelInactive: [],
  valueInactive: [],
  labelActiveDevice: [],
  valueActiveDevice: [],
  labelInactiveDevice: [],
  valueInactiveDevice: [],
  labelMysooltan: [],
  valueMysooltan: [],
  labelSharinguser: [],
  valueSharinguser: [],
  labelSooltancam: [],
  valueSooltancam: [],
  totalActive: 0,
  totalActiveDevice: 0,
  totalInactive: 0,
  totalInactiveDevice: 0,
  totalMySooltan: 0,
  totalSooltancamShare: 0,
  totalSooltancam: 0,
  users: [],
};

export default function reducer(state = initialState, action) {
  const { users, labelActive, valueActive, labelInactive,
    labelActiveDevice, valueActiveDevice, labelInactiveDevice, valueInactiveDevice,
    labelMysooltan, valueMysooltan,
    labelSharinguser, valueSharinguser,
    labelSooltancam, valueSooltancam,
    totalActiveDevice, totalInactiveDevice,
    valueInactive, totalActive, totalInactive,
    totalMySooltan, totalSooltancamShare, totalSooltancam, type } = action;
  
  switch (type) {
    case ACTIONS.FETCH_LIST_SUBSCRIBERS:
      return {
        ...state,
        users,
      };
    case ACTIONS.FILTER_ACTIVE:
      return {
        ...state,
        labelActive,
        valueActive,
      };
    case ACTIONS.FILTER_INACTIVE: 
      return {
        ...state,
        labelInactive,
        valueInactive,
      };    
    case ACTIONS.FILTER_ACTIVE_DEVICE:
      return {
        ...state,
        labelActiveDevice, valueActiveDevice
      };
    case ACTIONS.FILTER_INACTIVE_DEVICE:
      return {
        ...state,
        labelInactiveDevice, valueInactiveDevice,
      };    
    case ACTIONS.FILTER_MYSOOLTAN: 
      return {
        ...state,
        labelMysooltan, valueMysooltan,
      };
    case ACTIONS.FILTER_SHARINGUSER: 
      return {
        ...state,
        labelSharinguser, valueSharinguser,
      };
    case ACTIONS.FILTER_SOOLTANCAM: 
      return {
        ...state,
        labelSooltancam, valueSooltancam,
      };
    case ACTIONS.SUMMARY_ACTIVE:
      return {
        ...state,
        totalActive,
      };
    case ACTIONS.SUMMARY_INACTIVE: 
      return {
        ...state,
        totalInactive,
      };
    case ACTIONS.SUMMARY_ACTIVE_DEVICE:
      return {
        ...state,
        totalActiveDevice,
      };
    case ACTIONS.SUMMARY_INACTIVE_DEVICE:
      return {
        ...state,
        totalInactiveDevice,
      };
    case ACTIONS.SUMMARY_SOOLTANCAM:
      return {
        ...state,
        totalMySooltan, totalSooltancamShare, totalSooltancam
      };
    default:
      return {
        ...state
      };
  }
}
