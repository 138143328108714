import { ACTIONS } from '../../constants';

const initialState = {
  admin: false,
  modal: false,
  modalDelete: false,
  users: [],
};

export default function reducer(state = initialState, action) {
  const { admin, modal, modalDelete, users, type } = action;
  
  switch (type) {
    case ACTIONS.FETCH_ADMIN:
      return {
        ...state,
        users,
      };
    case ACTIONS.HIDE_MODAL:
      return {
        ...state,
        modal: false,
        modalDelete: false,
      };
    case ACTIONS.SELECT_ADMIN:
      return {
        ...state,
        admin
      };
    case ACTIONS.SHOW_MODAL:
      return {
        ...state,
        modal,
      };
    
    case ACTIONS.SHOW_MODAL_DELETE:
      return {
        ...state,
        modalDelete,
      };
    default:
      return {
        ...state
      };
  }
}
