/* eslint-disable max-lines */
import fetch, { getBasicSooltancam } from '../../utils/fetch';
import { SERVICES } from '../../configs';
import { ACTIONS } from '../../constants';
import { getUserData } from '../../utils/common';

export function getSummary(payload) {
  return dispatch => {
    let options = {
      method: 'GET',
      url: SERVICES.USER_SUMMARY,
      params: payload,
      headers: {
        'Authorization': `Bearer ${getUserData().userToken}`,
      }
    };

    if (getBasicSooltancam()) {
      delete options.headers;

      options.params = {
        endDate: payload.endDate,
        startDate: payload.startDate,
      };
      options.auth = getBasicSooltancam();
      options.url = SERVICES.USER_SUMMARY_SOOLTANCAM;
    }

    dispatch(loading());

    fetch(options)
      .then(res => {
        if (payload.status === 'aktif') {
          dispatch(active(res.data.list[0].activeUser));
        } else if (payload.status === 'tidak aktif') {
          dispatch(inactive(res.data.list[0].inactiveUser));
        }
        if (getBasicSooltancam()) {
          dispatch(active(res.data.list[0].activeUser));
          dispatch(inactive(res.data.list[0].inactiveUser));
          dispatch({
            type: ACTIONS.SUMMARY_SOOLTANCAM,
            totalMySooltan: res.data.list[0].userMySooltan,
            totalSooltancamShare: res.data.list[0].userSharing,
            totalSooltancam: res.data.list[0].userSooltancam
          });
        }
      })
      .catch(() => {
        dispatch(doneLoading());
      });
  };
}

export function getSummaryDevice(payload) {
  return dispatch => {
    const options = {
      auth: getBasicSooltancam(),
      method: 'GET',
      url: SERVICES.DEVICE_SUMMARY_SOOLTANCAM,
      params: payload,
    };

    if (getBasicSooltancam()) {
      options.params = {
        endDate: payload.endDate,
        startDate: payload.startDate,
      };
    }

    dispatch(loading());

    fetch(options)
      .then(res => {
        if (getBasicSooltancam()) {
          dispatch(activeDevice(res.data.list[0].activeDevice));
          dispatch(inactiveDevice(res.data.list[0].inactiveDevice));
        }
      })
      .catch(() => {
        dispatch(doneLoading());
      });
  };
}

export function getFilterDevice(payload) {
  return dispatch => {
    const options = {
      auth: getBasicSooltancam(),
      method: 'GET',
      url: SERVICES.DEVICE_SUMMARY_SOOLTANCAM,
      params: payload,
    };


    if (getBasicSooltancam()) {
      options.params = {
        endDate: payload.endDate,
        startDate: payload.startDate,
      };
    }

    dispatch(loading());

    fetch(options)
      .then(res => {
        let month = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Okt', 'Nov', 'Dec'];
        let labelActiveDevice = [];
        let valueActiveDevice = [];
        let labelInactiveDevice = [];
        let valueInactiveDevice = [];

        if (getBasicSooltancam()) {
          for (let d = new Date(payload.startDate); d <= new Date(payload.endDate); 
            d.setDate(d.getDate() + 1)) {
            let label = d.getDate() + '/' + month[d.getMonth()] + '/' + d.getFullYear();
            let date = res.data.list.filter(item =>
              item.date === `${d.getFullYear()}-${
                ('0' + (d.getMonth() + 1)).slice(-2)}-${
                ('0' + d.getDate()).slice(-2)}`
            );

            labelActiveDevice.push(label);
            valueActiveDevice.push({
              label,
              value: date[0].activeDevice
            });
            labelInactiveDevice.push(label);
            valueInactiveDevice.push({
              label,
              value: date[0].inactiveDevice
            });
          }
          dispatch({
            type: ACTIONS.FILTER_ACTIVE_DEVICE,
            labelActiveDevice,
            valueActiveDevice
          });
          dispatch({
            type: ACTIONS.FILTER_INACTIVE_DEVICE,
            labelInactiveDevice,
            valueInactiveDevice
          });  
        }
      })
      .catch(() => {
        dispatch(doneLoading());
      });
  };
}

export function getFilter(payload) {
  return dispatch => {
    const options = {
      method: 'GET',
      url: SERVICES.USER_SUMMARY,
      params: payload,
      headers: {
        'Authorization': `Bearer ${getUserData().userToken}`,
      }
    };


    if (getBasicSooltancam()) {
      delete options.headers;

      options.params = {
        endDate: payload.endDate,
        startDate: payload.startDate,
      };
      options.auth = getBasicSooltancam();
      options.url = SERVICES.USER_SUMMARY_SOOLTANCAM;
    }

    dispatch(loading());

    fetch(options)
      .then(res => {
        let month = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Okt', 'Nov', 'Dec'];
        let labelActive = [];
        let valueActive = [];
        let labelInactive = [];
        let valueInactive = [];
        let labelMysooltan = [];
        let valueMysooltan = [];
        let labelSooltancam = [];
        let valueSooltancam = [];
        let labelSharinguser = [];
        let valueSharinguser = [];

        if (getBasicSooltancam()) {
          for (let d = new Date(payload.startDate); d <= new Date(payload.endDate); 
            d.setDate(d.getDate() + 1)) {
            let label = d.getDate() + '/' + month[d.getMonth()] + '/' + d.getFullYear();
            let date = res.data.list.filter(item =>
              item.date === `${d.getFullYear()}-${
                ('0' + (d.getMonth() + 1)).slice(-2)}-${
                ('0' + d.getDate()).slice(-2)}`
            );

            labelActive.push(label);
            valueActive.push({
              label,
              value: date[0].activeUser
            });
            labelInactive.push(label);
            valueInactive.push({
              label,
              value: date[0].inactiveUser
            });
            labelMysooltan.push(label);
            valueMysooltan.push({
              label,
              value: date[0].userMySooltan
            });
            labelSooltancam.push(label);
            valueSooltancam.push({
              label,
              value: date[0].userSooltancam,
            });
            labelSharinguser.push(label);
            valueSharinguser.push({
              label,
              value: date[0].userSharing,
            });
          }
          dispatch({
            type: ACTIONS.FILTER_ACTIVE,
            labelActive,
            valueActive
          });
          dispatch({
            type: ACTIONS.FILTER_INACTIVE,
            labelInactive,
            valueInactive
          });
          dispatch({
            type: ACTIONS.FILTER_MYSOOLTAN,
            labelMysooltan,
            valueMysooltan
          });
          dispatch({
            type: ACTIONS.FILTER_SOOLTANCAM,
            labelSooltancam,
            valueSooltancam
          });
          dispatch({
            type: ACTIONS.FILTER_SHARINGUSER,
            labelSharinguser,
            valueSharinguser
          });          
        }
        if (payload.status === 'aktif' && getBasicSooltancam() === false) {
          for (let d = new Date(payload.startDate); d <= new Date(payload.endDate); 
            d.setDate(d.getDate() + 1)) {
            let label = d.getDate() + '/' + month[d.getMonth()] + '/' + d.getFullYear();
            let value = (res.data.countUsers.filter(item =>
              item.date === `${d.getFullYear()}-${
                ('0' + (d.getMonth() + 1)).slice(-2)}-${
                ('0' + d.getDate()).slice(-2)}`
            ).length > 0) ? res.data.countUsers.filter(item =>
                item.date === `${d.getFullYear()}-${
                  ('0' + (d.getMonth() + 1)).slice(-2)}-${
                  ('0' + d.getDate()).slice(-2)}`
              )[0].count : 0;

            labelActive.push(label);
            valueActive.push({
              label,
              value
            });
          }

          dispatch({
            type: ACTIONS.FILTER_ACTIVE,
            labelActive,
            valueActive
          });
        } else if (payload.status === 'tidak_aktif' && getBasicSooltancam() === false) {
          for (let d = new Date(payload.startDate); d <= new Date(payload.endDate); 
            d.setDate(d.getDate() + 1)) {
            let label = d.getDate() + '/' + month[d.getMonth()] + '/' + d.getFullYear();
            let value = (res.data.countUsers.filter(item =>
              item.date === `${d.getFullYear()}-${
                ('0' + (d.getMonth() + 1)).slice(-2)}-${
                ('0' + d.getDate()).slice(-2)}`
            ).length > 0) ? res.data.countUsers.filter(item =>
                item.date === `${d.getFullYear()}-${
                  ('0' + (d.getMonth() + 1)).slice(-2)}-${
                  ('0' + d.getDate()).slice(-2)}`
              )[0].count : 0;

            labelInactive.push(label);
            valueInactive.push({
              label,
              value
            });
          }

          dispatch({
            type: ACTIONS.FILTER_INACTIVE,
            labelInactive,
            valueInactive
          });
        }
      })
      .catch(() => {
        dispatch(doneLoading());
      });
  };
}

function doneLoading() {
  return {
    type: ACTIONS.DONE_LOADING,
  };
}

function loading() {
  return {
    type: ACTIONS.LOADING,
  };
}

function active(count) {
  return {
    type: ACTIONS.SUMMARY_ACTIVE,
    totalActive: count,
  };
}

function inactive(count) {
  return {
    type: ACTIONS.SUMMARY_INACTIVE,
    totalInactive: count,
  };
}

function activeDevice(count) {
  return {
    type: ACTIONS.SUMMARY_ACTIVE_DEVICE,
    totalActiveDevice: count,
  };
}

function inactiveDevice(count) {
  return {
    type: ACTIONS.SUMMARY_INACTIVE_DEVICE,
    totalInactiveDevice: count,
  };
}
