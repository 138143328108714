const styles = () => ({
  picker: {
    '&:after': {
      border: '5px solid black',
      borderColor: 'transparent transparent white white',
      boxShadow: '-1px 1px 1px 0 rgb(224,224,224, 0.5)',
      boxSizing: 'border-box',
      content: '""',
      height: 0,
      marginLeft: '-0.5em',
      marginTop: '0.1px',
      position: 'absolute',
      right: 0,
      top: 0,
      transform: 'rotate(135deg)',
      transformOrigin: '0 0',
      width: 0
    },
    backgroundColor: 'white',
    borderRadius: '5px',
    boxShadow: `0px 1px 4px #E0E0E0`,
    boxSizing: 'border-box',
    marginTop: '20px',
    padding: '12px 16px 21px 16px',
    position: 'relative',
    width: '280px'
  },
  pickerBody: {
    marginTop: '4px'
  },
  pickerChevron: {
    color: 'red',
    cursor: 'pointer',
    display: 'flex'
  },
  pickerDateCurrent: {
    '&:hover': {
      backgroundColor: 'grey'
    }
  },
  pickerDateItem: {
    alignItems: 'center',
    cursor: 'pointer',
    display: 'flex',
    height: '24px',
    justifyContent: 'center',
    width: '24px'
  },
  pickerDay: {
    display: 'grid',
    gridTemplateColumns: '24px 24px 24px 24px 24px 24px 24px'
  },
  pickerHead: {
    alignItems: 'center',
    display: 'flex'
  },
  pickerHeadCenter: {
    justifyContent: 'center'
  },
  pickerHeadJustify: {
    justifyContent: 'space-between'
  },
  pickerHeadLabel: {
    alignItems: 'center',
    display: 'flex'
  },
  pickerItemDisabled: {
    color: 'grey'
  },
  pickerItemSunday: {
    color: 'red'
  },
  pickerMonth: {
    display: 'grid',
    gridTemplateColumns: '55px 55px 55px',
    marginTop: '30px'
  },
  pickerMonthItem: {
    '&:hover': {
      backgroundColor: 'grey'
    },
    alignItems: 'center',
    cursor: 'pointer',
    display: 'flex',
    height: '30px',
    justifyContent: 'center'
  },
  pickerSelected: {
    backgroundColor: `red !important`,
    color: 'white !important'
  },
  wrapper: {
    width: '280px'
  }
});

export default styles;
