const styles = () => ({
  item: {
    '&:hover': {
      background: 'rgba(232, 176, 172, 0.3)',
      color: '#EE3124',
      padding: '8px 15px',
    },
    background: 'white',
    color: '#808285',
    padding: '8px 10px',
  },
  root: {
    background: '#ffffff',
    boxShadow: '0px 2px 8px rgba(0, 0, 0, 0.14)',
    cursor: 'pointer',
  },
  selected: {
    background: 'rgba(232, 176, 172, 0.3)',
    color: '#EE3124',
    padding: '8px 15px',
  },
});

export default styles;
