import Admin from './Admin';
import Dashboard from './Dashboard';
import EditProfile from './EditProfile';
import Error404 from './Error404';
import ErrorEvent from './ErrorEvent';
import ListCustomer from './ListCustomer';
import ListCustomerSharing from './ListCustomerSharing';
import ListDevice from './ListDevice';
import Login from './Login';
import LogDevice from './LogDevice';
import LogEmail from './LogEmail';

const pages = {
  Admin,
  Dashboard,
  EditProfile,
  Error404,
  ErrorEvent,
  ListCustomer,
  ListCustomerSharing,
  ListDevice,
  Login,
  LogDevice,
  LogEmail,
};

export default pages;
