const styles = {
  '@keyframes ldsRing': {
    from: {
      transform: 'rotate(0deg)',
    },
    to: {
      transform: 'rotate(360deg)',
    }
  },
  blur: {
    filter: 'blur(5px)',
    WebkitFilter: 'blur(5px)',
  },
  button: {
    backgroundColor: '#EE3124 !important',
    borderRadius: '5px !important',
    height: '38px !important',
    width: '155px'
  },
  buttonModal: {
    borderRadius: '5px !important',
    height: '50px !important',
    marhin: 0,
    width: '320px',
  },
  dropdown: {
    width: '186px',
  },
  ldsRing: {
    '& > div': {
      '&:nth-child(1)': {
        animationDelay: '-0.45s',
      },
      '&:nth-child(2)': {
        animationDelay: '-0.3s',
      },
      '&:nth-child(3)': {
        animationDelay: '-0.15s',
      },
      animation: '$ldsRing 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite',
      border: '3px solid #fff',
      borderColor: '#fff transparent transparent transparent',
      borderRadius: '50%',
      boxSizing: 'border-box',
      display: 'block',
      height: '30px',
      margin: '1px',
      position: 'absolute',
      width: '30px',
    },
    display: 'inline-block',
    height: '35px',
    position: 'relative',
    width: '35px',
  },
  modal: {
    alignItems: 'center',
    background: 'white',
    borderRadius: '5px',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    minHeight: '437px',
    padding: '28px 37px',
    position: 'fixed',
    width: '730px',
  },
  modalButton: {
    marginTop: '28px',
  },
  modalDescription: {
    color: 'rgba(0, 0, 0, 0.54)',
    fontSize: '23px',
    fontStyle: 'normal',
    fontWeight: 500,
    lineHeight: '34px',
    marginTop: '18px',
    textAlign: 'center',
  },
  modalDivider: {
    background: '#EE3124',
    borderRadius: '7px',
    display: 'block',
    height: '7px',
    marginTop: '4px',
    width: '70px',
  },
  modalOverlay: {
    background: 'rgb(0,0,0,0.4)',
    display: 'block',
    height: '100%',
    width: '100%',
  },
  modalTitle: {
    fontSize: '36px',
    fontStyle: 'normal',
    fontWeight: 'bold',
    lineHeight: '54px',
    margin: 0,
    marginTop: '22px',
  },
  modalWrapper: {
    alignItems: 'center',
    display: 'flex',
    fontFamily: 'Poppins',
    height: '100vh',
    justifyContent: 'center',
    left: 0,
    position: 'fixed',
    top: 0,
    width: '100vw',
  },
  search: {
    alignItems: 'center',
    background: '#ffffff',
    border: '1px solid #D9DEE4',
    borderRadius: '5px',
    boxSizing: 'border-box',
    display: 'flex',
    height: '78px',
    padding: '0px 24px',
  },
  searchLabel: {
    fontSize: '18px',
    fontStyle: 'normal',
    fontWeight: 600,
    lineHeight: '27px',
    marginLeft: '15px',
    marginRight: '16px',
  },
  table: {
    marginTop: '30px',
  },
  textfieldWrap: {
    flexGrow: 1,
    marginLeft: '25px',
    marginRight: '24px',
  },
};

export default styles;
