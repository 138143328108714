import React from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import { capitalize, tagPropType } from '../../../utils/helpers';

class Component extends React.Component {
  constructor(props) {
    super(props);
    this._handleOnClick = this._handleOnClick.bind(this);
  }

  _handleOnClick() {
    if (this.props.disabled) {
      return;
    }
    
    if (this.props.onClick) {
      this.props.onClick();
    }
  }

  render() {
    let {
      'aria-label': ariaLabel,
      className: classNameProps,
      classes,
      close,
      color,
      tag: Tag,
      innerRef,
      size,
      variant,
      ...attributes
    } = this.props;

    if (close && typeof attributes.children === 'undefined') {
      attributes.children = <span aria-hidden>×</span>;
    }

    if (attributes.href && Tag === 'button') {
      Tag = 'a';
    }
    const className = classNames(
      classes.root,
      {
        [classes[`variant${capitalize(variant)}`]]:
          variant !== 'filled' &&  variant !== 'minimalBetweentext' && 
          variant !== 'minimalBlack' && variant !== 'minimalColor',
        [classes[`variantMinimalColor`]]:
          variant === 'minimalBetweentext' || variant === 'minimalBlack' || variant === 'minimalColor',

        [classes[`circle${size}`]]: variant === 'circle',
        [classes[`filled${size}`]]: variant === 'filled' || variant === 'ghost',
        [classes[`color${capitalize(
          variant === 'imageBorder' || variant === 'imageFilled' ? 'image' : variant
        )}${capitalize(color)}`]]: color !== 'default'
      },
      classNameProps
    );

    const defaultAriaLabel = close ? 'Close' : null;

    return (
      <Tag
        type={Tag === 'button' && attributes.onClick ? 'button' : undefined}
        {...attributes}
        aria-label={ariaLabel || defaultAriaLabel}
        className={className}
        onClick={this._handleOnClick}
        ref={innerRef}
      />
    );
  }
}

Component.propTypes = {
  'aria-label': PropTypes.string,
  children: PropTypes.node,
  classes: PropTypes.object.isRequired,
  className: PropTypes.string,
  close: PropTypes.bool,
  color: PropTypes.string,
  disabled: PropTypes.bool,
  innerRef: PropTypes.oneOfType([PropTypes.object, PropTypes.func, PropTypes.string]),
  onClick: PropTypes.func,
  size: PropTypes.oneOf(['48', '32', '24']),
  tag: tagPropType,
  variant: PropTypes.oneOf([
    'filled', 'ghost', 'minimal',
    'minimalColor', 'minimalBetweentext',
    'minimalBlack', 'image', 'imageBorder', 'imageFilled',
    'circle'
  ])
};

Component.defaultProps = {
  'aria-label': null,
  children: null,
  className: null,
  close: false,
  color: 'primary',
  disabled: false,
  innerRef: null,
  onClick: null,
  size: '32',
  tag: 'button',
  variant: 'filled'
};

export default Component;
