import images from './images';
import menu, { menu147, menuSooltancam } from './menu';
import routes from './routes';
import services from './services';

export const AUTH_KEY = 'Basic dGVsa29tOmRhMWMyNWQ4LTM3YzgtNDFiMS1hZmUyLTQyZGQ0ODI1YmZlYQ==';
export const EXPIRE_TIME_STORAGE = 'ihsmart_dashboard_expire_time';
export const IMAGES = images;
export const MENU = {
  index: menu,
  147: menu147,
  sooltancam: menuSooltancam
};
export const SOOLTANCAM_DATA = 'ihsmart_dashboard_sooltancam';
export const EAZY_DATA = 'ihsmart_dashboard_eazy';
export const REMEMBER_SESSION = 'ihsmart_dashboard_remember';
export const ROUTES = routes;
export const USER_DATA_STORAGE = 'ihsmart_dashboard_user_data';
export const SERVICES = services;