import fetch from '../../utils/fetch';
import { AUTH_KEY, SERVICES } from '../../configs';
import { ACTIONS } from '../../constants';

export function fetchAllDevices(keyword) {
  return dispatch => {
    const options = {
      headers: { 
        'Authorization': AUTH_KEY, 
      },
      method: 'GET',
      params: {
        keyword,
      },
      url: SERVICES.LOG + '?index=logging&search=shim-login',
    };

    dispatch(loading());
    
    fetch(options)
      .then(res => {
        if (res.data) {
          let data = [];

          res.data.map(item => {
            data.push({
              timestamp: item['@timestamp'],
              fields: item.fields.responseData,
            });
          });

          dispatch(setDevices(data));
          dispatch(doneLoading());
        }
      })
      .catch(() => {
        dispatch(doneLoading());
      });
  };
}

function doneLoading() {
  return {
    type: ACTIONS.DONE_LOADING,
  };
}

function loading() {
  return {
    type: ACTIONS.LOADING,
  };
}

function setDevices(logs) {
  return {
    type: ACTIONS.FETCH_LIST_LOG,
    logs
  };
}
