const red = {
  10: '#FAF0F0',
  20: '#FAD6D6',
  30: '#FAB7B7',
  40: '#FA8E8E',
  50: '#F55151',
  60: '#DE1B1B',
  70: '#BA0D0D',
  80: '#900E0E',
  90: '#661414',
  100: '#451717',
};

export default red;
