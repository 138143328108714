const styles = {
  avatar: {
    marginRight: '35px',
  },
  blur: {
    filter: 'blur(5px)',
    WebkitFilter: 'blur(5px)',
  },
  button: {
    backgroundColor: '#EE3124 !important',
    borderRadius: '5px !important',
    height: '38px !important',
    marginLeft: '32px !important',
    width: '191px'
  },
  buttonWhite: {
    background: '#FFFFFF !important',
    border: '1px solid #EE3124 !important',
    boxSizing: 'border-box !imporant',
    color: '#EE3124 !important',
  },
  contentWrapper: {
    border: '1px solid #D9DEE4',
    borderRadius: '5px',
    boxSizing: 'border-box',
    overflow: 'hidden',
  },
  error: {
    alignItems: 'center',
    backgroundColor: '#F55151',
    color: 'white',
    display: 'flex',
    height: '73px',
    justifyContent: 'center',
    left: 0,
    position: 'absolute',
    top: 0,
    width: '100%',
  },
  field: {
    width: '323px',
  },
  filterSection: {
    alignItems: 'center',
    background: '#FFFFFF',
    borderBottom: '1px solid #ACACAC;',
    display: 'flex',
    height: '68px',
    justifyContent: 'space-between',
    paddingLeft: '18px',
    paddingRight: '24px',
  },
  form: {
    background: 'white',
    height: '680px',
    padding: '40px 63px',
    position: 'relative',
  },
  modal: {
    alignItems: 'center',
    background: 'white',
    borderRadius: '5px',
    display: 'flex',
    flexDirection: 'column',
    height: '300px',
    justifyContent: 'flex-start',
    padding: '28px 37px',
    position: 'fixed',
    width: '598px',
  },
  modalBottom: {
    bottom: '55px',
    position: 'absolute',
    right: '53px',
  },
  modalButton: {
    marginTop: '28px',
  },
  modalClose: {
    alignItems: 'center',
    background: '#EE3124',
    borderRadius: '100%',
    bottom: '97%',
    color: 'white',
    cursor: 'pointer',
    display: 'flex',
    height: '39px',
    justifyContent: 'center',
    left: '97%',
    position: 'absolute',
    width: '39px',
  },
  modalDescription: {
    color: 'rgba(0, 0, 0, 0.54)',
    fontSize: '23px',
    fontStyle: 'normal',
    fontWeight: 500,
    lineHeight: '34px',
    marginTop: '18px',
    textAlign: 'center',
  },
  modalDivider: {
    background: '#EE3124',
    borderRadius: '7px',
    display: 'block',
    height: '7px',
    marginTop: '4px',
    width: '70px',
  },
  modalField: {
    marginBottom: '17px',
  },
  modalOverlay: {
    background: 'rgb(0,0,0,0.4)',
    display: 'block',
    height: '100%',
    width: '100%',
  },
  modalTitle: {
    fontSize: '24px',
    fontWeight: 500,
    lineHeight: '28px',
    margin: 0,
    marginBottom: '31px',
    marginTop: '22px',
  },
  modalWrapper: {
    alignItems: 'center',
    display: 'flex',
    fontFamily: 'Poppins',
    height: '100vh',
    justifyContent: 'center',
    left: 0,
    position: 'fixed',
    top: 0,
    width: '100vw',
  },
  profile: {
    alignItems: 'center',
    display: 'flex',
    marginBottom: '53px',
  },
  profileLabel: {
    display: 'flex',
    flexDirection: 'column',
  },
  profileRole: {
    fontSize: '18px',
    fontStyle: 'normal',
    fontWeight: 'normal',
    lineHeight: '28px',
  },
  profileTitle: {
    fontSize: '24px',
    fontStyle: 'normal',
    fontWeight: '500',
    lineHeight: '28px',
    textTransform: 'capitalize',
  },
  search: {
    alignItems: 'center',
    background: '#ffffff',
    border: '1px solid #D9DEE4',
    borderRadius: '5px',
    boxSizing: 'border-box',
    display: 'flex',
    height: '78px',
    padding: '0px 24px',
  },
  searchLabel: {
    fontSize: '18px',
    fontStyle: 'normal',
    fontWeight: 600,
    lineHeight: '27px',
    marginLeft: '15px',
    marginRight: '16px',
  },
  table: {
    marginTop: '30px',
  },
  textfieldLabel: {
    color: '#AFADAD',
    fontSize: '14px',
    fontStyle: 'normal',
    fontWeight: '500',
    lineHeight: '28px',
  },
  textfieldWrap: {
    flexGrow: 1,
    marginLeft: '25px',
    marginRight: '24px',
  },
  title: {
    color: '#000000',
    fontSize: '18px',
    fontWeight: 600,
    lineHeight: '27px',
  },
};

export default styles;
