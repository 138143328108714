const grey = {
  10: '#FAFAFA',
  20: '#F5F5F5',
  30: '#EEEEEE',
  40: '#E0E0E0',
  50: '#BDBDBD',
  60: '#9E9E9E',
  70: '#757575',
  80: '#616161',
  90: '#424242',
  100: '#212121',
};

export default grey;
