/* eslint-disable */
import React from 'react';
import PropTypes from 'prop-types';
import { IMAGES } from '../../configs';
import Base from '../../components/layouts/Pagebase';
import Button from '../../components/elements/Button';
import DataTable from '../../components/elements/DataTableNew';
import Dropdown from '../../components/elements/Dropdown';
import Textfield from '../../components/elements/Textfield';
import { getSooltanCam, getUserData } from '../../utils/common';

export default class Component extends React.Component {
  constructor(props) {
    super(props);

    const data = getUserData();


    let filterObject;
    
    if (getSooltanCam() === 'true') {
      filterObject = ['mobileNumber','device_serial','brand'];
    } else {
      filterObject = ['ndmain','msisdn','device_serial','brand'];
    }

    this.state = { date: '',
      filterObject,
      page: 1, queryText: null, query: null, selectedItem: 0,
      showModal: false, item: false };

    this._handleClearfilter = this._handleClearfilter.bind(this);
    this._handleFilter = this._handleFilter.bind(this);
    this._handleQuery = this._handleQuery.bind(this);
    this._handleChangeDropdown = this._handleChangeDropdown.bind(this);


    if (data.level === 4) {
      this.state = { 
        ...this.state,
        filterObject: ['ndmain','msisdn'], selectedItem: 1
      };
    }
  }

  componentDidMount() {
    const { actions } = this.props;

    actions.fetchDevices(1);
  }

  componentDidUpdate(prevProps) {
    const { actions, isLoading } = this.props;
    const { date } = this.state;

    if (prevProps.download !== this.props.download && this.props.download.length > 0) {
      this._download(this.props.download);
    }

    if (Object.keys(prevProps.device).length > 0 && Object.keys(this.props.device).length === 0
      && this.state.showModal == 'delete') {
      const { filterObject, selectedItem } = this.state;

      this.setState({ showModal: 'deleteSuccess' });
      actions.fetchDevices(this.state.page,filterObject[selectedItem],this.state.queryText,date); 
    }

    if (Object.keys(prevProps.device).length > 0 && !isLoading && this.state.showModal == 'delete') {
      this.setState({ showModal: 'deleteError' });

      actions.selectDevice();
    }
  }

  _filteredDevices(devices) {
    const { filterObject, query, selectedItem } = this.state;
    let filtered = devices;
    
    if (query) {
      filtered = devices.filter((device) => {
        if (device[filterObject[selectedItem]]) {
          return device[filterObject[selectedItem]].toLowerCase()
            .includes(this.state.query.toLowerCase());
        }
      });
    }

    return filtered;
  }

  _handleAction(item) {
    const { actions } = this.props;

    this.setState({ showModal: 'delete', item });
    actions.selectDevice(item);
  }

  _handleApplyDate(_, picker) {
    const { actions } = this.props;
    const { filterObject, selectedItem } = this.state;
    let date;

    if (_ === false) {
      date = '';
    } else {
      date = `${picker.startDate.format('MM/DD/YYYY')} - ${picker.endDate.format('MM/DD/YYYY')}`;
    }

    this.setState({ date }, () => {
      actions.resetList();
      actions.fetchDevices(1,filterObject[selectedItem],this.state.queryText, date);
    });
  }

  _handleChangeDropdown(selectedItem) {
    this.setState({ query: '', queryText: '', selectedItem });
  }

  _handleClearfilter() {
    const { actions } = this.props;
    const { date } = this.state;
    
    actions.resetList();
    actions.fetchDevices(1, null, null, date);
    this.setState({ page: 1, query: '', queryText: '' });
  }

  _handleDownload() {
    const { actions, isLoading, totalData } = this.props;
    
    if (isLoading) { return; }
    
    const { filterObject, selectedItem } = this.state;
    
    actions.fetchAllDevices(1,filterObject[selectedItem],this.state.queryText
      ,totalData,() => { this.setState({ showModal: 'download' }); });
  }

  _handleQuery(queryText) {
    const { actions } = this.props;
    
    this.setState({ queryText }, () => {
      if (queryText === '') { actions.fetchDevices(1); }
    });
  }

  _handleFilter() {
    const { actions } = this.props;
    const { filterObject, selectedItem, date } = this.state;
    
    actions.resetList();
    actions.fetchDevices(1,filterObject[selectedItem],this.state.queryText,date);

    this.setState({ page: 1, query: this.state.queryText });
  }

  _handleNav(page) {
    const { actions, devices } = this.props;
    const { filterObject, selectedItem, date } = this.state;

    this.setState({ page }, () => { 
      if (!devices[page - 1]) {
        actions.fetchDevices(page,filterObject[selectedItem],this.state.queryText,date); 
      }
    });    
  }

  _handleSubmitDelete() {
    const { actions, isLoading } = this.props;

    if (this.state.item && !isLoading) { actions.deleteItem(this.state.item); }
  }

  renderModal() {
    const { actions, classes, isLoading } = this.props;
    const resetModal = () => { 
      if (!isLoading) { 
        this.setState({ item: false, showModal: false }); 
        actions.selectDevice();
      }
    };

    if (this.state.showModal == 'download') {
      return (
        <>
          <img src={IMAGES.DOWNLOAD_MODAL} />
          <span className={classes.modalDivider} />
          <div className={classes.modalDescription}>Data berhasil di download</div>
          <section className={classes.modalButton}>
            <Button className={[classes.buttonModal, classes.buttonWhite].join(' ')} color="primary" 
              onClick={resetModal} size="32">
              <label className={classes.buttonLabel}>OK</label>
            </Button>
          </section>
        </>
      );
    }

    if (this.state.showModal == 'delete' && this.state.item) {
      const { device_name = '', msisdn = '' } = this.state.item;

      return (
        <>
          <img src={IMAGES.TRASH} />
          <div className={classes.modalTitle} style={{ fontSize: '24px' }}>Hapus Perangkat</div>
          <p className={classes.modalDescription} style={{ fontSize: '18px' }}>
            Apakah Anda yakin menghapus {device_name} dari akun pelanggan ({msisdn})?
            <b style={{ color: '#EE3124', marginLeft: '5px' }}>
              aksi ini tidak dapat dikembalikan, harap periksa kembali data tersebut!
            </b>
          </p>
          <div>
            <Button className={[classes.buttonModal, classes.buttonWhite].join(' ')} color="outline" 
              onClick={resetModal} size="32">
              <label className={classes.buttonLabel}>Batal</label>
            </Button>
            <Button className={[classes.buttonModal, classes.buttonWhite].join(' ')} color="primary" 
              onClick={this._handleSubmitDelete.bind(this)} size="32">
              <label className={classes.buttonLabel}>
                {isLoading ? (<div className={classes.ldsRing}><div /><div /><div /><div /></div>)
                  : 'Oke'}</label>
            </Button>
          </div>
        </>
      );
    }

    if ((this.state.showModal == 'deleteSuccess'
      || this.state.showModal == 'deleteError') && this.state.item) {
      const { device_name = '', msisdn = '' } = this.state.item;

      return (
        <>
          <img src={IMAGES.TRASH_CHECK} />
          <div className={classes.modalTitle} style={{ fontSize: '24px' }}>Hapus Perangkat</div>
          <p className={classes.modalDescription} style={{ fontSize: '18px' }}>
            {device_name} {this.state.showModal === 'deleteSuccess' ? 'telah ' : 'gagal '}
              terhapus dari akun pelanggan ({msisdn})
          </p>
          <div>
            <Button className={[classes.buttonModal, classes.buttonWhite].join(' ')} color="primary" 
              onClick={resetModal} size="32">
              <label className={classes.buttonLabel}>Oke</label>
            </Button>
          </div>
        </>
      );
    }
  }

  render() {
    const { classes, devices, isLoading, totalData } = this.props;
    const { date } = this.state;

    let filterItem, thead, show;

    if (getSooltanCam() === 'true') {
      filterItem = getUserData().level < 4 ? ['Nomor Handphone', 'Nomor Serial', 'Merk Perangkat']
        : ['Nomor Handphone'];
    } else {
      filterItem = getUserData().level < 4 ? ['Nomor Indihome', 'Nomor Handphone', 'Nomor Serial', 'Merk Perangkat']
        : ['Nomor Indihome', 'Nomor Handphone'];
    }
    
    const propsSearch = {
      className: classes.textfield,
      input: {
        name: 'username', onChange: this._handleQuery,
        onSubmit: this._handleFilter, value: this.state.queryText,
      },
      meta: {
        'icon-align': 'left', icon: <img src={IMAGES.SEARCH} />,
        iconRight: <img onClick={this._handleClearfilter} src={IMAGES.TIMES}/>,
        placeholder: `Masukkan ${filterItem[this.state.selectedItem]}`,
        type: 'text', value: this.state.queryText, variant: 'basic-filled',
      },
    };
    const filterEntries = [10, 20, 30, 40, 50];

    if (getSooltanCam() === 'true') {
      thead = (getUserData().level < 2)
        ? ['Nama', 'No. HP', 'Merk Perangkat', 'Nomor Serial', 'Versi Perangkat', 'Versi Aplikasi', 'MAU', 'Online',
          'Nama Perangkat', 'Kategori Device', 'Cloud Recording Status', 'Jenis Aktivasi', 'Jenis Paket', 'Masa Aktif',
          'Tanggal Aktivasi', 'Tanggal Expired', 'Terakhir Aktif', 'Tanggal Pemasangan']
        : ['Nama', 'No. HP', 'Merk Perangkat', 'Nomor Serial', 'Versi Perangkat', 'Versi Aplikasi', 'MAU', 'Online',
          'Kategori Device', 'Cloud Recording Status', 'Jenis Aktivasi', 'Jenis Paket', 'Masa Aktif', 'Tanggal Aktivasi',
          'Tanggal Expired', 'Terakhir Aktif', 'Tanggal Pemasangan'];
      show = (getUserData().level < 2)
        ? ['name', 'mobileNumber', 'device_brand', 'device_serial', 'device_version', 'appVersion', 'mau', 'online', 'device_name',
          'device_category', 'cloud_status', 'paymentMethod', 'cloud_recording_package', 'cloud_recording_expire_day',
          'cloud_recording_activate_date', 'cloud_recording_expire', 'updatedAt', 'createdAt']
        : ['name', 'mobileNumber', 'device_brand', 'device_serial', 'device_version', 'appVersion',  'mau', 'online', 'device_category',
          'cloud_status', 'paymentMethod', 'cloud_recording_package', 'cloud_recording_expire_day',
          'cloud_recording_activate_date', 'cloud_recording_expire', 'updatedAt', 'createdAt'];  
        
    } else {
      thead = (getUserData().level < 2)
        ? ['Nomor Indihome', 'Nama', 'No. HP', 'Merk Perangkat', 'Nomor Serial', 'Versi Perangkat', 'Versi Aplikasi', 'MAU',
          'Nama Perangkat', 'Kategori Device', 'Cloud Recording Status', 'Jenis Aktivasi', 'Jenis Paket', 'Masa Aktif',
          'Tanggal Aktivasi', 'Tanggal Expired', 'Terakhir Aktif', 'Tanggal Pemasangan', 'Action']
        : ['Nomor Indihome', 'Nama', 'No. HP', 'Merk Perangkat', 'Nomor Serial', 'Versi Perangkat', 'Versi Aplikasi', 'MAU',
          'Kategori Device', 'Cloud Recording Status', 'Jenis Aktivasi', 'Jenis Paket', 'Masa Aktif', 'Tanggal Aktivasi',
          'Tanggal Expired', 'Terakhir Aktif', 'Tanggal Pemasangan'];
      show = (getUserData().level < 2)
        ? ['ndmain', 'name', 'msisdn', 'device_brand', 'serial_number', 'device_version', 'appVersion', 'mau', 'device_name',
          'device_category', 'cloud_status', 'paymentMethod', 'cloud_recording_package', 'cloud_recording_expire_day',
          'cloud_recording_activate_date', 'cloud_recording_expire', 'lastActive', 'createdAt', 'action']
        : ['ndmain', 'name', 'msisdn', 'device_brand', 'serial_number', 'device_version', 'appVersion',  'mau', 'device_category',
          'cloud_status', 'paymentMethod', 'cloud_recording_package', 'cloud_recording_expire_day',
          'cloud_recording_activate_date', 'cloud_recording_expire', 'lastActive', 'createdAt'];  
    }
    const table = devices.length > 0 && (
      <DataTable data-body={devices.map((device) => (typeof device === 'object' && device?.length > 0) ? device.map((item) => ({
        ...item,
        mau: item.mau === 'true' ? 'Yes' : 'No',
        online: item.online === 'true' ? 'Yes' : 'No',
      })) : [])} data-head={thead} date={date} filter-date 
      filter-entries={filterEntries} loading={isLoading} onAction={this._handleAction.bind(this)}
      onApplyDate={this._handleApplyDate.bind(this)} onClickPage={this._handleNav.bind(this)}
      onDownload={getUserData().level < 4 && this._handleDownload.bind(this)}
      page={this.state.page} show={show} total-data={totalData} />
    );
    
    const modal = (this.state.showModal) ? (
      <section className={classes.modalWrapper}>
        <span className={classes.modalOverlay}/>
        <div className={classes.modal}>{this.renderModal()}</div>
      </section>
    ) : null;
    const blurred = (this.state.showModal) ? classes.blur : '';
    return (
      <React.Fragment>
        <main className={blurred}>
          <Base {...this.props}>
            <section className={classes.search}>
              <label className={classes.searchLabel}>Cari dengan :</label>
              <Dropdown className={classes.dropdown} items={filterItem}
                onChange={this._handleChangeDropdown} selected-item={this.state.selectedItem} />
              <div className={classes.textfieldWrap}><Textfield {...propsSearch} /></div>
              <Button className={classes.button} color="primary" onClick={this._handleFilter} size="32" >Cari</Button>
            </section>
            <section className={classes.table}>{table}</section>
          </Base>
        </main>
        {modal}
      </React.Fragment>
    );
  }
}

Component.propTypes = {
  actions: PropTypes.object,
  classes: PropTypes.object,
  device: PropTypes.object,
  devices: PropTypes.array,
  download: PropTypes.array,
  isLoading: PropTypes.bool,
  totalData: PropTypes.number
};

Component.defaultProps = {
  actions: {},
  classes: {},
  device: {},
  devices: [],
  download: [],
  isLoading: false,
  totalData: 0
};

// _download(list) {
//   this.setState({ showModal: 'download' }, () => {
//     let csv = toCsv([
//       'Device Id', 'Nama', 'Nomor Indihome', 'Nomor Handphone', 
//       'Merk Perangkat', 'Nomor Serial', 'Versi Perangkat', 
//       'Nama Perangkat',  'Terakhir Aktif', 'Tanggal Pemasangan'
//     ],list.map(item => ({
//       deviceId: item.deviceId, name: item.name, ndmain: item.ndmain,
//       msisdn: item.msisdn ? item.msisdn : item.ndmain, device_brand: item.device_brand,
//       serial_number: item.serial_number, device_version: item.device_version,
//       device_name: item.device_name, lastActive: item.lastActive, updatedAt: item.updatedAt
//     })));

//     let blob = new Blob([csv], { type: 'text/csv;charset=utf-8;' });
//     let link = document.createElement('a');
//     console.log
//     if (link.download !== undefined) {
//       let url = URL.createObjectURL(blob);
      
//       link.setAttribute('href', url);
//       link.setAttribute('download', 'download.csv');
//       link.style.visibility = 'hidden';
//       document.body.appendChild(link);
//       link.click();
//       document.body.removeChild(link);
//     }
//   });
// }
