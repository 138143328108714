import React from 'react';
import PropTypes from 'prop-types';
import ListItem from '../ListItem';
import { IMAGES } from '../../../configs';

export default class Dropdown extends React.Component {
  constructor(props) {
    super(props);

    const { items, 'selected-item': selectedItem } = props;

    this.state = {
      selectedItem: (selectedItem) ? items[selectedItem] : ((items) ? items[0] : null),
      show: false,
    };

    this.setWrapperRef = this.setWrapperRef.bind(this);
    this._handleClick = this._handleClick.bind(this);
    this._handleClickItem = this._handleClickItem.bind(this);
    this._handleClickOutside = this._handleClickOutside.bind(this);
  }

  componentDidMount() {
    document.addEventListener('mousedown', this._handleClickOutside);
  }

  componentWillUnmount() {
    document.removeEventListener('mousedown', this._handleClickOutside);
  }

  setWrapperRef(node) {
    this.wrapperRef = node;
  }

  _handleClickOutside(event) {
    if (this.wrapperRef && !this.wrapperRef.contains(event.target)) {
      this.setState({ show: false }); 
    }
  }
  
  _handleClick() {
    this.setState({
      show: !this.state.show,
    });
  }

  _handleClickItem(index) {
    const { items, onChange } = this.props;

    this.setState({
      selectedItem: items[index],
      show: false,
    }, () => {
      if (onChange)
        onChange(index);
    });
  }

  render() {
    const { border, classes, className: classNameprops, items, 'selected-item': selectedItem,
      separator, variant } = this.props;
    const chevron = [
      variant === 'white' ? classes.chevronWhite : classes.chevron,
      separator === true && classes.separate
    ].join(' ');
    const classNames = [
      border && classes.border, 
      classNameprops,
      classes.rootDropdown,
      classes[variant],
    ].join(' ');
    const listItem = (this.state.show) ? (
      <ListItem className={classes.dropdownItem} items={items} 
        onClick={this._handleClickItem} selected-item={selectedItem} />
    ) : null;
    const label = (variant === 'white') ? classes.labelWhite : classes.label;
    const icon = this.state.show ? IMAGES.CHEVRON_UP : IMAGES.CHEVRON_DOWN;
    return (
      <main className={classes.wrapper} ref={this.setWrapperRef}>
        <section className={classNames} onClick={this._handleClick}>
          <label className={label}>{this.state.selectedItem}</label>
          <span className={chevron}>
            <img className={classes.icon} src={icon} />
          </span>
        </section>
        {listItem}
      </main>
    );
  }
}

Dropdown.propTypes = {
  border: PropTypes.string,
  classes: PropTypes.object,
  className: PropTypes.string,
  items: PropTypes.array,
  onChange: PropTypes.func,
  'selected-item': PropTypes.number,
  separator: PropTypes.bool,
  variant: PropTypes.string,
};

Dropdown.defaultProps = {
  'selected-item': 0,
  border: 'false',
  classes: {},
  className: {},
  items: [],
  onChange: () => {},
  separator: true,
  variant: '',
};
