const blue = {
  10: '#EEF4FC',
  20: '#CFE0FC',
  30: '#ABCAFC',
  40: '#85B2FA',
  50: '#5691F0',
  60: '#3071D9',
  70: '#1D5BBF',
  80: '#114497',
  90: '#103570',
  100: '#15243C',
};

export default blue;
