import CryptoJS from 'crypto-js';
import { AUTH_KEY, ROUTES, SERVICES } from '../../configs';
import fetch from '../../utils/fetch';
import { setExpireTime, setRemember, setUserData } from '../../utils/common';
import { ACTIONS } from '../../constants';

export function login(payload) {
  return dispatch => {
    const { password, username } = payload;

    const options = {
      data: {
        password: CryptoJS.MD5(password).toString(),
        username,
      },
      headers: { 
        'Authorization': AUTH_KEY ,
        'Content-Type': 'application/json',
      },
      method: 'POST',
      url: SERVICES.LOGIN,
    };
    
    fetch(options)
      .then((res) => {
        let type = 'local';

        setExpireTime(10800, type);
        setRemember(true, type);
        setUserData(res.data, type);
        
        location.href = res.data.level < 4 ? ROUTES.CUSTOMER_LIST() : ROUTES.DEVICE_LIST();
      })
      .catch(() => {
        dispatch({
          type: ACTIONS.ERROR_LOGIN,
          error: 'Kombinasi username / password yang Anda masukan salah, silahkan ulangi',
        });
      });
  };
}

export function errorLogin(message) {
  return dispatch => {
    dispatch({
      type: ACTIONS.ERROR_LOGIN,
      error: message,
    });
  };
}

export function resetError() {
  return dispatch => {
    dispatch({
      type: ACTIONS.RESET_ERROR
    });
  };
}
