const styles = {
  button: {
    alignItems: 'center',
    backgroundColor: '#EE3124',
    border: 'none',
    borderRadius: '100%',
    color: 'white',
    cursor: 'pointer',
    display: 'flex',
    fontSize: '20px',
    fontWeight: 'bold',
    height: '43px',
    justifyContent: 'center',
    position: 'absolute',
    right: '-10px',
    top: '-10px',
    width: '43px',
  },
  buttonSubmit: {
    borderRadius: '5px',
    bottom: '30px',
    fontSize: '16px',
    height: '56px',
    left: 0,
    lineHeight: '24px',
    marginLeft: 'auto',
    marginRight: 'auto',
    position: 'absolute',
    right: 0,
    width: '360px'
  },
  caption: {
    fontSize: '12px',
    lineHeight: '18px'
  },
  field: {
    marginBottom: '14px',
  },
  fields: {
    marginTop: '27px',
    width: '560px',
  },
  label: {
    alignItems: 'center',
    display: 'flex',
    fontSize: '14px',
    height: '30px',
    lineHeight: '28px',
  },
  root: {
    alignItems: 'center',
    background: 'radial-gradient(175px at 98% 1.8%, transparent 15%, white 15%) no-repeat',
    display: 'flex',
    flexDirection: 'column',
    height: '730px',
    maxHeight: '85vh',
    padding: '50px 55px 30px 55px',
    position: 'absolute',
    width: '670px',
  },
  title: {
    margin: 0,
  },
};

export default styles;
