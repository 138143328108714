import { ACTIONS } from '../../constants';

const initialState = {
  error: false,
};

export default function reducer(state = initialState, action) {
  const { error, type } = action;
  
  switch (type) {
    case ACTIONS.ERROR_LOGIN:
      return {
        ...state,
        error,
      };
    case ACTIONS.RESET_ERROR:
      return {
        ...state,
        error: false,
      };
    default:
      return {
        ...state
      };
  }
}
