const actions = {
  DONE_LOADING: 'DONE_LOADING',
  ERROR_LOGIN: 'ERROR_LOGIN',
  FETCH_ADMIN: 'FETCH_ADMIN',
  FETCH_LIST_ALL_DEVICES: 'FETCH_LIST_ALL_DEVICES',
  FETCH_LIST_DEVICES: 'FETCH_LIST_DEVICES',
  FETCH_LIST_LOG: 'FETCH_LIST_LOG',
  FETCH_LIST_ALL_SUBSCRIBERS: 'FETCH_LIST_ALL_SUBSCRIBERS',
  FETCH_LIST_SUBSCRIBERS: 'FETCH_LIST_SUBSCRIBERS',
  FETCH_LIST_USERS: 'FETCH_LIST_USERS',
  FETCH_USER: 'FETCH_USER',
  FILTER_ACTIVE: 'FILTER_ACTIVE',
  FILTER_ACTIVE_DEVICE: 'FILTER_ACTIVE_DEVICE',
  FILTER_INACTIVE: 'FILTER_INACTIVE',
  FILTER_INACTIVE_DEVICE: 'FILTER_INACTIVE_DEVICE',
  FILTER_MYSOOLTAN: 'FILTER_MYSOOLTAN',
  FILTER_SHARINGUSER: 'FILTER_SHARINGUSER',
  FILTER_SOOLTANCAM: 'FILTER_SOOLTANCAM',
  HIDE_MODAL: 'HIDE_MODAL',
  LOADING: 'LOADING',
  LOGIN: 'LOGIN',
  RESET_ERROR: 'RESET_ERROR',
  RESET_LIST_DEVICES: 'RESET_LIST_DEVICES',
  RESET_LIST_SUBSCRIBERS: 'RESET_LIST_SUBSCRIBERS',
  SELECT_ADMIN: 'SELECT_ADMIN',
  SELECT_DEVICE: 'SELECT_DEVICE',
  SELECT_USER: 'SELECT_USER',
  SHOW_MODAL: 'SHOW_MODAL',
  SHOW_MODAL_DELETE: 'SHOW_MODAL_DELETE',
  SUMMARY_ACTIVE: 'SUMMARY_ACTIVE',
  SUMMARY_ACTIVE_DEVICE: 'SUMMARY_ACTIVE_DEVICE',
  SUMMARY_INACTIVE: 'SUMMARY_INACTIVE',
  SUMMARY_INACTIVE_DEVICE: 'SUMMARY_INACTIVE_DEVICE',
  SUMMARY_SOOLTANCAM: 'SUMMARY_SOOLTANCAM',
  UPDATE_STATUS: 'UPDATE_STATUS',
};

export default actions;