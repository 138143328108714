import React from 'react';
import PropTypes from 'prop-types';
import Calendar from '../Calendar';

export default class Datepicker extends React.Component {
  constructor(props) {
    super(props);

    const { endDate, input: { value = null }, startDate } = props;
    let today = new Date();

    this.state = {
      activeLayer: 'date',
      actualDate: today.getDate(),
      actualMonth: today.getMonth(),
      actualYear: today.getFullYear(),
      currentDate: null,
      currentMonth: today.getMonth(),
      currentYear: today.getFullYear(),
      month: [
        'January', 'February', 'March',
        'April', 'May', 'June', 'July',
        'August', 'September', 'October',
        'November', 'December'
      ],
      show: true,
      startDate,
      endDate,
      value: (value) ? value : 'DD / MM / YYYY'
    };
  }

  componentDidUpdate(prevProps) { 
    const { endDate, startDate } = this.props; 
    
    if (this.state.startDate > this.state.endDate && this.state.endDate) {
      this.setState({
        endDate: this.state.startDate,
        startDate: this.state.endDate,
      });
    }

    if (prevProps !== this.props) {
      this.setState({
        endDate,
        startDate,
      });
    }
  }

  _handlerChange(value) {
    this.setState({ value }, () => {
      if (value.split('/').length === 3) {
        let dates = value.split('/');

        const date = (/^\d+$/.test(dates[0].replace(/ /g, '')))
          ? parseInt(dates[0].replace(/ /g, '')) : null;
        const month = (/^\d+$/.test(dates[1].replace(/ /g, '')))
          ? parseInt(dates[1].replace(/ /g, '')) : null;
        const year = (/^\d+$/.test(dates[2].replace(/ /g, '')))
          ? parseInt(dates[2].replace(/ /g, '')) : null;

        if (date && month && year && (0 > parseInt(date) < 32
          && 0 > parseInt(month) < 12
          && 0 > parseInt(year).length <= 4)) {
          this.setState({
            actualDate: date,
            actualMonth: month - 1,
            actualYear: year,
            currentDate: date,
            currentMonth: month - 1,
            currentYear: year,
          });
        }
      }
    });
  }
  
  _handlerFocus(focus) {
    this.setState({ show: focus }, () => {
      if (this.state.value === '' && !this.state.show) {
        this.setState({ value: 'DD / MM / YYYY' });
      }
    });
  }

  _handlerClickLayer(layer) {
    this.setState({ activeLayer: layer });
  }

  _handlerClickNavigator(action) {
    if (this.state.activeLayer === 'date') {
      if (action === 'before') {
        if (this.state.currentMonth > 0) {
          this.setState({ currentMonth: this.state.currentMonth - 1 });
        } else {
          this.setState({ currentMonth: 11, currentYear: this.state.currentYear - 1 });
        }
      } else {
        if (this.state.currentMonth < 11) {
          this.setState({ currentMonth: this.state.currentMonth + 1 });
        } else {
          this.setState({ currentMonth: 0, currentYear: this.state.currentYear + 1 });
        }
      }
    } else {
      if (action === 'before') {
        this.setState({ currentYear: this.state.currentYear - 12 });
      } else {
        this.setState({ currentYear: this.state.currentYear + 12 });
      }
    }
  }

  _handlerClickValue(date) {
    const { onChange } = this.props;

    this.setState({
      actualDate: date,
      actualMonth: this.state.currentMonth,
      actualYear: this.state.currentYear,
      currentDate: date,
    }, () => {
      let newDate = new Date(this.state.currentYear,this.state.currentMonth,date);
      if (!this.state.startDate) {
        this.setState({ startDate: newDate }, () => {
          onChange(this.state.startDate, this.state.endDate);
        });
      } else if (this.state.startDate && !this.state.endDate) {
        this.setState({ endDate: newDate }, () => {
          onChange(this.state.startDate, this.state.endDate);
        });
      } else if (this.state.startDate && this.state.endDate) {
        const middleDate = new Date(this.state.endDate - 
          (this.state.endDate - this.state.startDate ) /2 );
        
        if (newDate > middleDate && this.state.startDate) {
          this.setState({ endDate: newDate }, () => {
            onChange(this.state.startDate, this.state.endDate);
          });  
        } else {
          this.setState({ startDate: newDate }, () => {
            onChange(this.state.startDate, this.state.endDate);
          });
        }
      }
      
      let value = `${this._pad(this.state.actualDate)} / ${this._pad(this.state.actualMonth 
        + 1)} / ${this.state.actualYear}`;

      this._handlerChange(value);
    });
  }

  _handlerSetMonth(month) {
    this.setState({ currentMonth: month, activeLayer: 'date' });
  }

  _handlerSetYear(year) {
    this.setState({ currentYear: year, activeLayer: 'date' });
  }

  _pad(d) {
    return (d < 10) ? '0' + d.toString() : d.toString();
  }
  
  render() {
    const { classes } = this.props;
    const picker = (this.state.show) ? (
      <Calendar {...this.state}
        clickLayer={this._handlerClickLayer.bind(this)}
        clickNavigator={this._handlerClickNavigator.bind(this)}
        clickValue={this._handlerClickValue.bind(this)}
        endDate={this.state.endDate}
        setMonth={this._handlerSetMonth.bind(this)}
        setYear={this._handlerSetYear.bind(this)}
        startDate={this.state.startDate} />
    ) : '';

    return (
      <section className={classes.wrapper}>
        {picker}
      </section>
    );
  }
}

Datepicker.propTypes = {
  classes: PropTypes.object.isRequired,
  endDate: PropTypes.date,
  input: PropTypes.object,
  meta: PropTypes.object,
  onChange: PropTypes.func,
  startDate: PropTypes.date,
};

Datepicker.defaultProps = {
  endDate: new Date(),
  input: {},
  meta: {},
  onChange: () => {},
  startDate: new Date(),
};
