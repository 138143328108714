const yellow = {
  10: '#FFF9DB',
  20: '#FFF3BF',
  30: '#FFEC99',
  40: '#FFE066',
  50: '#FFD43B',
  60: '#FCC419',
  70: '#FAB005',
  80: '#F59F00',
  90: '#F08C00',
  100: '#E67700',
};

export default yellow;
