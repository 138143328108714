import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import Typography from '../Typography';

export default class Component extends React.Component {
  constructor(props) {
    super(props);
  }

  _renderSeparator = (index, separator) => {
    const { classes, className, ...other } = this.props;

    return (<li
      aria-hidden
      className={classNames(classes.separator, className)}
      key={`separator-${index}`}
      {...other}>{separator}
    </li>);
  }

  _renderContent(items, separator) {
    return items.reduce((res, current, index) => {
      if (index < items.length - 1) {
        res = res.concat(
          current,
          this._renderSeparator(index, separator)
        );
      } else {
        res.push(current);
      }
      return res;
    }, []);
  }

  render() {
    let {
      children,
      classes,
      className,
      separator,
      disabled,
      ...other
    } = this.props;

    const items = React.Children.toArray(children)
      .filter(child => React.isValidElement(child))
      .map((child, index) => (
        <li
          className={
            classNames(
              Array.isArray(child.props.children) ?
                classes.liArray : classes.li,
              (index === children.length - 1 && (disabled ? classes.disabled : classes.active)),
              index === React.Children.toArray(children).length
                ? classes.last : null )}
          key={`child-${index}`}
          tabIndex="0">
          {child}
        </li>
      ));

    return (
      <Typography
        className={classNames(classes.root, className)}
        variant="subtitle2"
        {...other}
      >
        <ol className={classes.ol}>
          {this._renderContent(items, separator)}
        </ol>
      </Typography>
    );
  }
}

Component.propTypes = {
  /**
   * The breadcrumb children.
   */
  children: PropTypes.node.isRequired,

  /**
   * Override or extend the styles applied to the component.
   * See [CSS API](#css) below for more details.
   */
  classes: PropTypes.object.isRequired,

  /**
   * @ignore
   */
  className: PropTypes.string,

  /**
   * Custom separator node.
   */
  disabled: PropTypes.bool,

  /**
   * isDisabled style
   */
  separator: PropTypes.node
};

Component.defaultProps = {
  className: null,
  disabled: false,
  separator: '/'
};
