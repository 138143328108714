/* eslint-disable */
import React from 'react';
import PropTypes from 'prop-types';
import { IMAGES } from '../../../configs';

export default class DataTable extends React.Component {
  constructor(props) {
    super(props);

    const { 'data-body': dataBody, 'filter-entries': entries, page } = this.props;

    this.state = {
      entries: entries[0],
      dataBody,
      filter: 0,
      page: (page) ? page : 1,
      minPage: 1,
      maxPage: (Math.ceil(dataBody.length / entries[0]) <= 5)
        ? Math.ceil(dataBody.length / entries[0])
        : 5,
      totalPage: Math.ceil(dataBody.length / entries[0]),
      show: null,
    };

    this._handleEntries = this._handleEntries.bind(this);
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    const { 'data-body': dataBodyNext, 'filter-entries': entries,
      'filter-item': filterItem, filter } = nextProps;
    const { dataBody: dataBodyPrev } = prevState;

    if (
      !prevState &&
      dataBodyPrev !== dataBodyNext
    ) {
      prevState.minPage = 1;
      prevState.maxPage = (Math.ceil(dataBodyNext.length / entries[0]) <= 5)
        ? Math.ceil(dataBodyNext.length / entries[0])
        : 5;
      prevState.totalPage = Math.ceil(dataBodyNext.length / entries[0]);
      prevState.page = 1;
    }

    if (dataBodyPrev !== dataBodyNext && prevState.filter > 0 && prevState.page === 1
      && nextProps.filter) {
      prevState.dataBody = dataBodyNext.filter(item => item[`${filter}`].toLowerCase()
        === filterItem[prevState.filter - 1].toLowerCase());
      prevState.minPage = 1;
      prevState.maxPage = (Math.ceil(prevState.dataBody.length / entries[0]) <= 5)
        ? Math.ceil(prevState.dataBody.length / entries[0])
        : 5;
      prevState.totalPage = Math.ceil(prevState.dataBody.length / entries[0]);
      prevState.page = 1;
    }

    if (dataBodyPrev !== dataBodyNext && prevState.filter === 0 && prevState.page === 1
      && nextProps.filter) {
      prevState.dataBody = dataBodyNext;
      prevState.minPage = 1;
      prevState.maxPage = (Math.ceil(prevState.dataBody.length / entries[0]) <= 5)
        ? Math.ceil(prevState.dataBody.length / entries[0])
        : 5;
      prevState.totalPage = Math.ceil(prevState.dataBody.length / entries[0]);
      prevState.page = 1;
    }

    if (!nextProps.filter) {
      prevState.dataBody = dataBodyNext;
      prevState.totalPage = Math.ceil(prevState.dataBody.length / entries[0]);

      if (prevState.page === 1 || dataBodyPrev !== dataBodyNext) {
        prevState.minPage = 1;
        prevState.maxPage = (Math.ceil(prevState.dataBody.length / entries[0]) <= 5)
          ? Math.ceil(prevState.dataBody.length / entries[0])
          : 5;
        prevState.page = 1;
      }
    }
  }

  _handleEntries(index) {
    const { 'filter-entries': entries } = this.props;
    const { dataBody } = this.state;

    this.setState({
      entries: entries[index],
      page: 1,
      minPage: 1,
      maxPage: (Math.ceil(dataBody.length / entries[index]) <= 5)
        ? Math.ceil(dataBody.length / entries[index])
        : 5,
      totalPage: Math.ceil(dataBody.length / entries[index]),
    });
  }

  _handleClickAction(item, index, actionIndex) {
    const { onAction } = this.props;
    
    onAction(item, index, actionIndex);

    this.setState({ show: false });
  }

  _handleClickDownload() {
    const { onDownload } = this.props;

    if (onDownload)
      onDownload(this.state.dataBody);
  }

  _handleClickFilter(filterIndex) {
    const { 'data-body': dataBody, 'filter-entries': entries,
      'filter-item': filterItem, filter } = this.props;
    const filtered = (filterIndex > 0)
      ? dataBody.filter(item => item[`${filter}`].toLowerCase() === filterItem[filterIndex - 1].toLowerCase())
      : dataBody;

    this.setState({ filter: filterIndex, dataBody: filtered }, () => {
      this.setState({
        page: 1,
        minPage: 1,
        maxPage: (Math.ceil(this.state.dataBody.length / entries[0]) <= 5)
          ? Math.ceil(this.state.dataBody.length / entries[0])
          : 5,
        totalPage: Math.ceil(this.state.dataBody.length / entries[0]),
      })
    });
  }

  _handleClickpage(page) {
    this.setState({ page });
  }

  _handleClicknav(iteration) {
    const after = this.state.page + iteration;

    if (after > 0 && after <= this.state.totalPage)
      this.setState({ page: this.state.page + (iteration) }, () => {
        if (this.state.page > this.state.maxPage) {
          this.setState({
            minPage: this.state.minPage + 1,
            maxPage: this.state.maxPage + 1,
          });
        }

        if (this.state.page < this.state.minPage) {
          this.setState({
            minPage: this.state.minPage - 1,
            maxPage: this.state.maxPage - 1,
          });
        }
      });
  }

  _handleShowAction(index, show) {
    if (show) {
      this.setState({ show: index });
    }
  }

  renderBody() {
    const { 'max-char': maxChar, 'max-width': maxWidth, 'min-width': minWidth, align, classes, show } = this.props;
    const { dataBody } = this.state;
    const minIndex = (this.state.page * this.state.entries) - this.state.entries;
    const maxIndex = this.state.page * this.state.entries;

    if (dataBody.length > 0) {
      return (
        dataBody.map((item, index) => {
          const columns = [];
          const classesTr = (index % 2 === 0) ? classes.red : null;

          columns.push(
            <td className={classes.td}
              style={{ verticalAlign: align }}>{index + 1}</td>
          );

          for (let i = 0; i < show.length; i++) {
            let value = (item[show[i]]) ? ((maxChar && item[show[i]].length > maxChar)
              ? item[show[i]].substr(0, maxChar) + ' . . .' : item[show[i]]) : '-';
              
            let update = (index === this.state.show) ? (
              <div className={classes.update}>
                {
                  item.action.map((action, actionIndex) => (
                    <item className={classes.actionItem} onClick={this._handleClickAction.bind(this, item, index, actionIndex)}>
                      {(action.image) ? <img className={classes.imageAction} src={action.image} /> : ''} {action.text}
                    </item>
                  ))
                }
              </div>
            ) : '';
            
            value = (show[i] === "action")
              ? <div className={classes.actionWrapper}>
                  <img className={classes.action}
                    onMouseEnter={this._handleShowAction.bind(this, index, true)}
                    src={IMAGES.POINT} />
                  {update} 
                </div>
              : value;

            columns.push(
              <td className={classes.td} 
                style={{ 
                  maxWidth: `${maxWidth}px`,
                  minWidth: `${minWidth}px`,
                  verticalAlign: align,
                }}>{value}</td>
            );
          }

          if (index >= minIndex && index < maxIndex)
            return (
              <tr className={classesTr} key={index}>
                {columns}
              </tr>
            );
        })
      );
    } else {
      return (
        <tr className={classes.red}>
          <td className={classes.td} colSpan={show.length + 1}>Data Not Found</td>
        </tr>
      );
    }
  }

  renderFilter() {
    const { classes, 'filter-item': filterItem, 'filter-label': filterLabel, filter } = this.props;
    const button = (filterItem) ? filterItem.map((item, idx) => {
      const active = (this.state.filter) === idx + 1 ? classes.buttonFilterActive : '';

      return <button className={[classes.buttonFilter, active].join(' ')}
        onClick={this._handleClickFilter.bind(this, idx + 1)}>{item}</button>;
    }) : '';
    const filterContent = (filter) ? (<React.Fragment>
      <label>Filter {filterLabel} : </label>
      <button className={[classes.buttonFilter,
      (this.state.filter) === 0 ? classes.buttonFilterActive : ''].join(' ')}
        onClick={this._handleClickFilter.bind(this, 0)}>Semua</button>
      {button}
    </React.Fragment>) : '';

    return (
      <section className={classes.filterSection}>
        <div>
          {filterContent}
        </div>
        <div className={classes.buttonDownload} onClick={this._handleClickDownload.bind(this)}>
          <img className={classes.imageDownload} src={IMAGES.DOWNLOAD} /> Download
        </div>
      </section>
    )
  }

  renderHead() {
    const { 'data-head': dataHead, classes } = this.props;

    return (
      <tr>
        <td className={classes.tdHead}>No.</td>
        {
          dataHead.map((head, index) => {
            return (
              <td className={classes.tdHead} key={index}>{head}</td>
            );
          })
        }
      </tr>
    );
  }

  renderPagination() {
    const { classes } = this.props;
    const pages = [];

    for (let i = this.state.minPage; i <= this.state.maxPage; i++) {
      const navCurrent = (i === this.state.page) ? classes.navCurrent : null;

      pages.push(
        <div className={[classes.nav, navCurrent].join(' ')} onClick={this._handleClickpage.bind(this, i)}>
          {i}
        </div>
      );
    }

    return (
      <span className={classes.pagination}>
        <div className={[classes.nav, classes.navPrev].join(' ')}
          onClick={this._handleClicknav.bind(this, -1)}>Sebelumnya</div>
        {pages}
        <div className={[classes.nav, classes.navNext].join(' ')}
          onClick={this._handleClicknav.bind(this, 1)}>Selanjutnya</div>
      </span>
    );
  }

  render() {
    const { classes, filter, onDownload } = this.props;
    const { dataBody } = this.state;
    const filterContent = (filter || onDownload) 
      ? this.renderFilter()
      : '';
    return (
      <React.Fragment>
        <main>
          {filterContent}
          <table className={classes.root}>
            <thead>
              {this.renderHead()}
            </thead>
            <tbody>
              {this.renderBody()}
            </tbody>
          </table>
          <section className={classes.navSection}>
            <div className={classes.entries}>
              <label className={classes.labelEntries}>
                Showing <div className={classes.redLabel}>
                  {(this.state.page * this.state.entries) -
                    this.state.entries + 1} - {
                    (this.state.page * this.state.entries) < dataBody.length
                      ? this.state.page * this.state.entries
                      : dataBody.length
                  } </div>
                of {dataBody.length} entries &nbsp;
              </label>
            </div>
            {this.renderPagination()}
          </section>
        </main>
      </React.Fragment>
    );
  }
}

DataTable.propTypes = {
  classes: PropTypes.object,
  className: PropTypes.string,
  'data-body': PropTypes.array,
  'data-head': PropTypes.array,
  filter: PropTypes.string,
  'filter-entries': PropTypes.array,
  'filter-item': PropTypes.array,
  items: PropTypes.array,
  onChange: PropTypes.func,
  page: PropTypes.string,
  show: PropTypes.array,
  variant: PropTypes.string,
};

DataTable.defaultProps = {
  'data-body': [],
  'data-head': [],
  'filter-entries': [],
  'filter-item': [],
  classes: {},
  className: {},
  filter: '',
  items: [],
  onChange: () => { },
  page: 1,
  show: [],
  variant: '',
};
