import { getSooltanCam } from "../utils/common";

const BASE_URL_PROD = 'https://api.eazy.co.id';
const BASE_URL_PROD_SOOLTANCAM = 'https://api-sooltancam.mysooltan.co.id';
const BASE_URL_DEV = 'https://api-stg.eazy.co.id';
const BASE_URL_DEV_SOOLTANCAM = 'https://api-stg.eazy.co.id';
const BASE_URL_UAT = 'https://api-stg.eazy.co.id';

let BASE_URL = process.env.NODE_ENV == 'production' && !__DEV__ ? BASE_URL_PROD : BASE_URL_DEV;

if (typeof __UAT__ != 'undefined' && __UAT__) BASE_URL = BASE_URL_UAT;

export function getBaseUrlSooltanCam() {
  if (localStorage.getItem('ihsmart_dashboard_sooltancam') === 'true') {
    BASE_URL = process.env.NODE_ENV == 'production' && !__DEV__ ? BASE_URL_PROD_SOOLTANCAM : BASE_URL_DEV_SOOLTANCAM;
  }

  if (typeof __UAT__ != 'undefined' && __UAT__ && getSooltanCam() === 'true') BASE_URL = BASE_URL_UAT;

  return BASE_URL;
}

const services = {
  ADMIN: `${BASE_URL}/users/v1/admin`,
  ADMIN_NEW: `${BASE_URL}/users/v1/admin`,
  DEVICE: `${BASE_URL}/devices/v4/device`,
  DEVICE_V1: `${BASE_URL}/devices/v3/device`,
  DEVICE_SOOLTANCAM: `${BASE_URL}/devices/v1/device`,
  DEVICE_SUMMARY_SOOLTANCAM: `${getBaseUrlSooltanCam()}/devices/v1/summary`,
  DOWNLOAD_DEVICE: `${BASE_URL}/devices/v3/device/installed`,
  DOWNLOAD_DEVICE_SOOLTANCAM: `${getBaseUrlSooltanCam()}/devices/v1/list`,
  DOWNLOAD_USER_SOOLTANCAM: `${getBaseUrlSooltanCam()}/users/v1/list`,
  GET_USERS_SUBSCRIBE: (page,size) => { return `${BASE_URL}/users/v4/profile/list?page=${page}&size=${size}` },
  GET_USERS_SUBSCRIBE_SOOLTANCAM: (page,size) => { return `${getBaseUrlSooltanCam()}/users/v1/list?page=${page}&size=${size}` },
  GET_USERS_SUBSCRIBE_SOOLTANCAM_USER_SHARING: (page,size) => { return `${getBaseUrlSooltanCam()}/users/v1/user-sharing/list?page=${page}&size=${size}` },
  GET_DEVICE_INSTALLED: (page,size) => { return `${BASE_URL}/devices/v1/devices-installed?page=${page}&size=${size}` },
  LOG: `${BASE_URL}/users/v1/elasticsearch`,
  LOGIN: `${BASE_URL}/users/v1/login/dashboard`,
  LOGIN_TUYA: `${BASE_URL}/tuya/v4/login/private`,
  UPDATE_MSISDN: `${BASE_URL}/users/v4/msisdn`,
  UPDATE_STATUS: `${BASE_URL}/users/v1/recheck-subscribe-status`,
  UPDATE_SUBSCRIBE: `${BASE_URL}/users/v1/subscribe/status`,
  UPDATE_USER: `${BASE_URL}/users/v4/profile`,
  USER_SUMMARY: `${BASE_URL}/users/v1/user-summary`,
  USER_SUMMARY_SOOLTANCAM: `${getBaseUrlSooltanCam()}/users/v1/summary`,
};

export default services;