/* eslint-disable */
import React from 'react';
import PropTypes from 'prop-types';
import { IMAGES } from '../../configs';
import Base from '../../components/layouts/Pagebase';
import ChartLine from '../../components/elements/ChartLine';
import Datepicker from '../../components/elements/Datepicker';
import Dropdown from '../../components/elements/Dropdown';
import Tooltip from '../../components/elements/Tooltip';
import { dateToString } from '../../utils/text';
import { getSooltanCam } from '../../utils/common';

export default class Component extends React.Component {
  constructor(props) {
    super(props);

    let today = new Date();
    let month = [
      'January', 'February', 'March',
      'April', 'May', 'June', 'July',
      'August', 'September', 'October',
      'November', 'December'
    ];

    this.state = {
      activeStart: 0,
      activeStartDevice: 0,
      activeLayer: 'date',
      actualDate: today.getDate(),
      actualMonth: today.getMonth(),
      actualYear: today.getFullYear(),
      currentDate: today.getDate(),
      currentMonth: today.getMonth(),
      currentYear: today.getFullYear(),
      datePickerShow: false,
      endDate: null,
      endDateString: ("0" + today.getDate()).slice(-2) + ' ' + 
        month[today.getMonth()].substring(0,3) + ' ' + today.getFullYear(),
      inactiveStart: 0,
      inactiveStartDevice: 0,
      month,
      mySooltanStart: 0,
      startDate: null,
      startDateString: ("0" + today.getDate()).slice(-2) + ' ' + 
        month[today.getMonth()].substring(0,3) + ' ' + today.getFullYear(),
      today: new Date(),
      typing: false,
      typingTimeout: 0,
      show: false,
      left: 0,
      top: 0,
      text: '',
      sharinguserStart: 0,
      showDeviceActive: false,
      showDeviceInactive: false,
      showInactive: false,
      showMysooltan: false,
      showSooltancam: false,
      showSharinguser: false,
      sooltancamStart: 0,
      leftDeviceActive: 0,
      topDeviceActive: 0,
      textDeviceActive: '',
      leftDeviceInactive: 0,
      topDeviceInactive: 0,
      textDeviceInactive: '',
      leftInactive: 0,
      topInactive: 0,
      textInactive: '',
      leftMysooltan: 0,
      topMysooltan: 0,
      textMysooltan: '',
      leftSharinguser: 0,
      topSharinguser: 0,
      textSharinguser: '',
      leftSooltancam: 0,
      topSooltancam: 0,
      textSooltancam: '',
    }

    this.handleChangeDate = this.handleChangeDate.bind(this);
    this._handleCancel = this._handleCancel.bind(this);
    this._handleConfirm = this._handleConfirm.bind(this);
    this._handleCustom = this._handleCustom.bind(this);
    this.inputEndRef = React.createRef();
    this.inputStartRef = React.createRef();
  }

  componentDidMount() {
    const { actions } = this.props;

    actions.getSummary({
      endDate: `${new Date().getFullYear()}-${
        ("0" + (new Date().getMonth() + 1)).slice(-2)}-${
        ("0" + new Date().getDate()).slice(-2)}`,
      startDate: `${new Date().getFullYear()}-${
        ("0" + (new Date().getMonth() + 1)).slice(-2)}-${
        ("0" + new Date().getDate()).slice(-2)}`,
      group: 'day',
      status: 'aktif',
    });

    actions.getSummary({
      endDate: `${new Date().getFullYear()}-${
        ("0" + (new Date().getMonth() + 1)).slice(-2)}-${
        ("0" + new Date().getDate()).slice(-2)}`,
      startDate: `${new Date().getFullYear()}-${
        ("0" + (new Date().getMonth() + 1)).slice(-2)}-${
        ("0" + new Date().getDate()).slice(-2)}`,
      group: 'day',
      status: 'tidak aktif',
    });

    if (getSooltanCam() === 'true') {
      actions.getSummaryDevice({
        endDate: `${new Date().getFullYear()}-${
          ("0" + (new Date().getMonth() + 1)).slice(-2)}-${
          ("0" + new Date().getDate()).slice(-2)}`,
        startDate: `${new Date().getFullYear()}-${
          ("0" + (new Date().getMonth() + 1)).slice(-2)}-${
          ("0" + new Date().getDate()).slice(-2)}`,
      });
    }
  }

  handleChangeDate(startDate, endDate) {
    this.setState({
      endDate,
      startDate,
    }, () => {
      if (startDate && this.inputStartRef.current) {
        this.inputStartRef.current.value = dateToString(startDate);
      }

      if (endDate && this.inputEndRef.current) {
        this.inputEndRef.current.value = dateToString(endDate);
      }
    });
  }

  _handleCancel() {
    this.setState({
      datePickerShow: false,
      endDate: null,
      startDate: null,
    });
  }

  _handleChangeDateInput(range, e) {
    let text = e.target.value.split(' ');
    let month = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Okt', 'Nov', 'Dec'];

    if (text.length === 3 && range === "start") {
      this.setState({ startDate: new Date(text[2], month.indexOf(text[1]), text[0]) });
    }

    if (text.length === 3 && range === "end") {
      this.setState({ endDate: new Date(text[2], month.indexOf(text[1]), text[0]) });
    }
  }

  _handleConfirm() {
    const { actions } = this.props;
    
    this.setState({
      datePickerShow: false,
    }, () => {
      actions.getFilter({
        endDate: `${this.state.endDate.getFullYear()}-${
          ("0" + (this.state.endDate.getMonth() + 1)).slice(-2)}-${
          ("0" + this.state.endDate.getDate()).slice(-2)}`,
        startDate: `${this.state.startDate.getFullYear()}-${
          ("0" + (this.state.startDate.getMonth() + 1)).slice(-2)}-${
          ("0" + this.state.startDate.getDate()).slice(-2)}`,
        group: 'day',
        status: 'aktif',
      });

      actions.getFilter({
        endDate: `${this.state.endDate.getFullYear()}-${
          ("0" + (this.state.endDate.getMonth() + 1)).slice(-2)}-${
          ("0" + this.state.endDate.getDate()).slice(-2)}`,
        startDate: `${this.state.startDate.getFullYear()}-${
          ("0" + (this.state.startDate.getMonth() + 1)).slice(-2)}-${
          ("0" + this.state.startDate.getDate()).slice(-2)}`,
        group: 'day',
        status: 'tidak_aktif',
      });

      if (getSooltanCam() === 'true') {
        actions.getFilterDevice({
          endDate: `${this.state.endDate.getFullYear()}-${
            ("0" + (this.state.endDate.getMonth() + 1)).slice(-2)}-${
            ("0" + this.state.endDate.getDate()).slice(-2)}`,
          startDate: `${this.state.startDate.getFullYear()}-${
            ("0" + (this.state.startDate.getMonth() + 1)).slice(-2)}-${
            ("0" + this.state.startDate.getDate()).slice(-2)}`,
        });        
      }
    })
  }

  _handleCustom() {
    this.setState({ datePickerShow: true });
  }

  _handleNavActive(nav) {
    const { valueActive } = this.props;
    
    if ((this.state.activeStart + 5) + 1 <= valueActive.length && nav === 1)
      this.setState({ activeStart: this.state.activeStart + nav });

    if (this.state.activeStart - 1 >= 0 && nav === -1)
      this.setState({ activeStart: this.state.activeStart + nav });
  }

  _handleNavInactive(nav) {
    const { valueInactive } = this.props;
    
    if ((this.state.inactiveStart + 5) + 1 <= valueInactive.length && nav === 1)
      this.setState({ inactiveStart: this.state.inactiveStart + nav });

    if (this.state.inactiveStart - 1 >= 0 && nav === -1)
      this.setState({ inactiveStart: this.state.inactiveStart + nav });
  }

  _handleNavActiveDevice(nav) {
    const { valueActiveDevice } = this.props;
    
    if ((this.state.activeStartDevice + 5) + 1 <= valueActiveDevice.length && nav === 1)
      this.setState({ activeStartDevice: this.state.activeStartDevice + nav });

    if (this.state.activeStart - 1 >= 0 && nav === -1)
      this.setState({ activeStartDevice: this.state.activeStartDevice + nav });
  }

  _handleNavInactiveDevice(nav) {
    const { valueInactiveDevice } = this.props;
    
    if ((this.state.inactiveStartDevice + 5) + 1 <= valueInactiveDevice.length && nav === 1)
      this.setState({ inactiveStartDevice: this.state.inactiveStartDevice + nav });

    if (this.state.inactiveStartDevice - 1 >= 0 && nav === -1)
      this.setState({ inactiveStartDevice: this.state.inactiveStartDevice + nav });
  }

  _handleNavMysooltan(nav) {
    const { valueMysooltan } = this.props;
    
    if ((this.state.mySooltanStart + 5) + 1 <= valueMysooltan.length && nav === 1)
      this.setState({ mySooltanStart: this.state.mySooltanStart + nav });

    if (this.state.mySooltanStart - 1 >= 0 && nav === -1)
      this.setState({ mySooltanStart: this.state.mySooltanStart + nav });
  }

  _handleNavMysooltan(nav) {
    const { valueMysooltan } = this.props;
    
    if ((this.state.mySooltanStart + 5) + 1 <= valueMysooltan.length && nav === 1)
      this.setState({ mySooltanStart: this.state.mySooltanStart + nav });

    if (this.state.mySooltanStart - 1 >= 0 && nav === -1)
      this.setState({ mySooltanStart: this.state.mySooltanStart + nav });
  }

  _handleNavSooltancam(nav) {
    const { valueSooltancam } = this.props;
    
    if ((this.state.sooltancamStart + 5) + 1 <= valueSooltancam.length && nav === 1)
      this.setState({ sooltancamStart: this.state.sooltancamStart + nav });

    if (this.state.sooltancamStart - 1 >= 0 && nav === -1)
      this.setState({ sooltancamStart: this.state.sooltancamStart + nav });
  }

  _handleNavSharinguser(nav) {
    const { valueSharinguser } = this.props;
    
    if ((this.state.sharinguserStart + 5) + 1 <= valueSharinguser.length && nav === 1)
      this.setState({ sharinguserStart: this.state.sharinguserStart + nav });

    if (this.state.sharinguserStart - 1 >= 0 && nav === -1)
      this.setState({ sharinguserStart: this.state.sharinguserStart + nav });
  }

  _handleOpenTooltip(left, top, text) {
    this.setState({
      left: left,
      top: top,
      text: text
    }, () => {
      this.setState({
        show: true
      });
    });
  }
  
  _handleCloseTooltip() {
    this.setState({
      show: false
    });
  }

  _handleOpenTooltipInactive(left, top, text) {
    this.setState({
      leftInactive: left,
      topInactive: top,
      textInactive: text
    }, () => {
      this.setState({
        showInactive: true
      });
    });
  }

  _handleOpenTooltipDeviceActive(left, top, text) {
    this.setState({
      leftDeviceActive: left,
      topDeviceActive: top,
      textDeviceActive: text
    }, () => {
      this.setState({
        showDeviceActive: true
      });
    });
  }

  _handleCloseTooltipDeviceActive() {
    this.setState({
      showDeviceActive: false
    });
  }

  _handleOpenTooltipDeviceInactive(left, top, text) {
    this.setState({
      leftDeviceInactive: left,
      topDeviceInactive: top,
      textDeviceInactive: text
    }, () => {
      this.setState({
        showDeviceInactive: true
      });
    });
  }

  _handleCloseTooltipDeviceInactive() {
    this.setState({
      showDeviceInactive: false
    });
  }

  _handleOpenTooltipMysooltan(left, top, text) {
    this.setState({
      leftMysooltan: left,
      topMysooltan: top,
      textMysooltan: text
    }, () => {
      this.setState({
        showMysooltan: true
      });
    });
  }

  _handleCloseTooltipMySooltan() {
    this.setState({
      showMysooltan: false
    });
  }

  _handleOpenTooltipSharinguser(left, top, text) {
    this.setState({
      leftSharinguser: left,
      topSharinguser: top,
      textSharinguser: text
    }, () => {
      this.setState({
        showSharinguser: true
      });
    });
  }

  _handleCloseTooltipSharingUser() {
    this.setState({
      showSharinguser: false
    });
  }

  _handleOpenTooltipSooltancam(left, top, text) {
    this.setState({
      leftSooltancam: left,
      topSooltancam: top,
      textSooltancam: text
    }, () => {
      this.setState({
        showSooltancam: true
      });
    });
  }

  _handleCloseTooltipSooltancam() {
    this.setState({
      showSooltancam: false
    });
  }
  
  _handleCloseTooltipInactive() {
    this.setState({
      showInactive: false
    });
  }

  sum(arr) {
    return (arr.length > 0 ) ? arr.map(item => item.value).reduce((prev, next) => prev + next) : 0;
  }

  render() {
    const { classes, labelActive, valueActive, labelInactive, valueInactive,
      labelMysooltan, valueMysooltan, labelSooltancam, valueSooltancam,
      labelActiveDevice, valueActiveDevice, labelInactiveDevice, valueInactiveDevice,
      labelSharinguser, valueSharinguser,
      totalActive, totalInactive, totalMySooltan, totalSooltancamShare, totalSooltancam,
      users } = this.props;console.log(this.props)
    const datePicker = (this.state.datePickerShow) ? (
      <section className={classes.datePicker}>
        <div className={classes.inputWrapper}>
          <input className={classes.input} maxLength="11"
            onChange={this._handleChangeDateInput.bind(this, "start")}
            placeholder={this.state.startDateString}
            ref={this.inputStartRef} type="text" />
          <label>-</label>
          <input className={classes.input} maxLength="11"
            onChange={this._handleChangeDateInput.bind(this, "end")}
            placeholder={this.state.endDateString} 
            ref={this.inputEndRef} type="text" />
        </div>
        <Datepicker 
          endDate={this.state.endDate}
          onChange={this.handleChangeDate}
          startDate={this.state.startDate} />
        <div className={classes.buttonWrapper}>
          <label className={classes.buttonLabel} onClick={this._handleCancel}>
            CANCEL
          </label>
          <label className={[classes.buttonLabel, classes.labelRed].join(' ')}
            onClick={this._handleConfirm}>
            OK
          </label>
        </div>
      </section> 
    ) : '';
    
    return (
      <main>
        <Base {...this.props}>
          <section>
            {
              (getSooltanCam() === 'true') && (<>
                <section className={classes.filterSection}>
                  <span/>
                  <div className={classes.filterWrapper}>
                    <Dropdown className={classes.dropdown} items={["Custom"]}
                      onChange={this._handleCustom}
                      selected-item="0" />
                    {datePicker}
                  </div>
                </section>
                <section className={classes.resultFilter}>
                  {dateToString(this.state.startDate) + ' - ' + dateToString(this.state.endDate)}
                </section>
              </>)
            }
            <h1 className={classes.title}>User Status</h1>
            <section className={classes.cardWrapper}>
              <div className={classes.cardSummary}>
                <span className={classes.checkWrapper}>
                  <img src={IMAGES.CHECK} />
                </span>
                <span className={classes.summaryInfoWrapper}>
                  <label className={classes.summaryCaption}>
                    User Aktif
                  </label>
                  <label className={classes.summaryCount}>
                    {(valueActive) ? this.sum(valueActive, 'count') : 0}
                  </label>
                </span>
              </div>
              <div className={classes.cardSummary}>
                <span className={classes.crossWrapper}>
                  <img src={IMAGES.CROSS} />
                </span>
                <span className={classes.summaryInfoWrapper}>
                  <label className={classes.summaryCaption}>
                    User Nonaktif
                  </label>
                  <label className={classes.summaryCount}>                    
                    {(valueInactive) ? this.sum(valueInactive, 'count') : 0}
                  </label>
                </span>
              </div>  
            </section>
          </section>
          {
            (getSooltanCam() === 'true') && (<>
              <section>
                <br />
                <section className={classes.cardWrapper}>
                  <div className={[classes.cardSummary, classes.cardSummary3].join(' ')}>
                    <span className={classes.summaryInfoWrapper}>
                      <label className={classes.summaryCaption}>
                        User MySooltan
                      </label>
                      <label className={classes.summaryCount}>
                        {(valueMysooltan) ? this.sum(valueMysooltan, 'count') : 0}
                      </label>
                    </span>
                  </div>
                  <div className={[classes.cardSummary, classes.cardSummary3].join(' ')}>
                    <span className={classes.summaryInfoWrapper}>
                      <label className={classes.summaryCaption}>
                        User SooltanCam
                      </label>
                      <label className={classes.summaryCount}>
                        {(valueSooltancam) ? this.sum(valueSooltancam, 'count') : 0}
                      </label>
                    </span>
                  </div>
                  <div className={[classes.cardSummary, classes.cardSummary3].join(' ')}>
                    <span className={classes.summaryInfoWrapper}>
                      <label className={classes.summaryCaption}>
                        User Sharing
                      </label>
                      <label className={classes.summaryCount}>
                        {totalSooltancamShare}
                      </label>
                    </span>
                  </div>  
                </section>
              </section>
            </>)
          }
          <br/>

          <section>
            {
              (getSooltanCam() === 'true') && (<>
                <section className={classes.filterSection}>
                  <h1 className={classes.title}>User Status Summary</h1>
                </section>
              </>)
            }
          </section>
          <section>
            {
              (getSooltanCam() !== 'true') && (<>
                <section className={classes.filterSection}>
                  <h1 className={classes.title}>User Status Summary</h1>
                  <div className={classes.filterWrapper}>
                    <Dropdown className={classes.dropdown} items={["Custom"]}
                      onChange={this._handleCustom}
                      selected-item="0" />
                    {datePicker}
                  </div>
                </section>
                <section className={classes.resultFilter}>
                  {dateToString(this.state.startDate) + ' - ' + dateToString(this.state.endDate)}
                </section>
              </>)
            }
            <section className={classes.cardWrapper}>
              <div className={classes.card}>
                <label className={classes.captionCards}>User Aktif</label>
                <section className={classes.countWrapper}>
                  <label className={classes.countCards}>
                    {(valueActive) ? this.sum(valueActive) : 0}
                  </label>
                </section>
                <div className={classes.chartWrapper}>
                  <ChartLine
                    chartHeight="185"
                    chartWidth="436"
                    variant="chart-line"
                    title="Chart Title Here"
                    yTotalLabel={1}
                    xLabel={labelActive.slice(this.state.activeStart, this.state.activeStart + 5)}
                    maxValue={Math.max.apply(Math, valueActive.map(function(o) { return o.value; })) + 10}
                    data={[
                      {
                        name: "User Aktif ",
                        color: "#219653",
                        point: valueActive
                      },
                    ]}
                    closeTooltip={this._handleCloseTooltip.bind(this)}
                    openTooltip={this._handleOpenTooltip.bind(this)}
                  />
                  <Tooltip
                    label={this.state.text}
                    left={this.state.left}
                    show={this.state.show}
                    top={this.state.top} />
                </div>
                {
                  (valueActive.length > 5) ? (
                    <section style={{display: 'flex', marginTop: '10px'}}>
                      <div onClick={this._handleNavActive.bind(this, -1)}>
                        <img src={IMAGES.PREV_PAGE} />
                      </div>
                      <div onClick={this._handleNavActive.bind(this, 1)}>
                        <img src={IMAGES.NEXT_PAGE} />
                      </div>
                    </section>
                  ) : ''
                }
              </div>
              <div className={classes.card}>
                <label className={classes.captionCards}>User Nonaktif</label>
                <section className={classes.countWrapper}>
                  <label className={classes.countCards}>
                    {(valueInactive) ? this.sum(valueInactive, 'count') : 0}
                  </label>
                </section>
                <div className={classes.chartWrapper}>
                  <ChartLine
                    chartHeight="185"
                    chartWidth="436"
                    variant="chart-line"
                    title="Chart Title Here"
                    yTotalLabel={1}
                    xLabel={labelInactive.slice(this.state.inactiveStart, this.state.inactiveStart + 5)}
                    maxValue={Math.max.apply(Math, valueInactive.map(function(o) { return o.value; })) + 10}
                    data={[
                      {
                        name: "User Nonaktif ",
                        color: "#DE1B1B",
                        point: valueInactive
                      },
                    ]}
                    closeTooltip={this._handleCloseTooltipInactive.bind(this)}
                    openTooltip={this._handleOpenTooltipInactive.bind(this)}
                  />
                  <Tooltip
                    label={this.state.textInactive}
                    left={this.state.leftInactive}
                    show={this.state.showInactive}
                    top={this.state.topInactive} />
                </div>{
                  (valueInactive.length > 5) ? (
                    <section style={{display: 'flex', marginTop: '10px'}}>
                      <div onClick={this._handleNavInactive.bind(this, -1)}>
                        <img src={IMAGES.PREV_PAGE} />
                      </div>
                      <div onClick={this._handleNavInactive.bind(this, 1)}>
                        <img src={IMAGES.NEXT_PAGE} />
                      </div>
                    </section>
                  ) : ''
                }
              </div>  
            </section>
            {
              (getSooltanCam() === 'true') && (
                <>

                  <br />
                  <section className={classes.cardWrapper}>
                    <div className={classes.card}>
                      <label className={classes.captionCards}>User MySooltan</label>
                      <section className={classes.countWrapper}>
                        <label className={classes.countCards}>
                          {(valueMysooltan) ? this.sum(valueMysooltan) : 0}
                        </label>
                      </section>
                      <div className={classes.chartWrapper}>
                        <ChartLine
                          chartHeight="185"
                          chartWidth="436"
                          variant="chart-line"
                          title="Chart Title Here"
                          yTotalLabel={1}
                          xLabel={labelMysooltan.slice(this.state.mySooltanStart, this.state.mySooltanStart + 5)}
                          maxValue={Math.max.apply(Math, valueMysooltan.map(function(o) { return o.value; })) + 10}
                          data={[
                            {
                              name: "User MySooltan ",
                              color: "#219653",
                              point: valueMysooltan
                            },
                          ]}
                          closeTooltip={this._handleCloseTooltipMySooltan.bind(this)}
                          openTooltip={this._handleOpenTooltipMysooltan.bind(this)}
                        />
                        <Tooltip
                          label={this.state.textMysooltan}
                          left={this.state.leftMysooltan}
                          show={this.state.showMysooltan}
                          top={this.state.topMysooltan} />
                      </div>
                      {
                        (valueMysooltan.length > 5) ? (
                          <section style={{display: 'flex', marginTop: '10px'}}>
                            <div onClick={this._handleNavMysooltan.bind(this, -1)}>
                              <img src={IMAGES.PREV_PAGE} />
                            </div>
                            <div onClick={this._handleNavMysooltan.bind(this, 1)}>
                              <img src={IMAGES.NEXT_PAGE} />
                            </div>
                          </section>
                        ) : ''
                      }
                    </div>
                    <div className={classes.card}>
                      <label className={classes.captionCards}>User Sooltancam</label>
                      <section className={classes.countWrapper}>
                        <label className={classes.countCards}>
                          {(valueSooltancam) ? this.sum(valueSooltancam, 'count') : 0}
                        </label>
                      </section>
                      <div className={classes.chartWrapper}>
                        <ChartLine
                          chartHeight="185"
                          chartWidth="436"
                          variant="chart-line"
                          title="Chart Title Here"
                          yTotalLabel={1}
                          xLabel={labelSooltancam.slice(this.state.sooltancamStart, this.state.sooltancamStart + 5)}
                          maxValue={Math.max.apply(Math, valueSooltancam.map(function(o) { return o.value; })) + 10}
                          data={[
                            {
                              name: "User Sooltancam ",
                              color: "#DE1B1B",
                              point: valueSooltancam
                            },
                          ]}
                          closeTooltip={this._handleCloseTooltipSooltancam.bind(this)}
                          openTooltip={this._handleOpenTooltipSooltancam.bind(this)}
                        />
                        <Tooltip
                          label={this.state.textSooltancam}
                          left={this.state.leftSooltancam}
                          show={this.state.showSooltancam}
                          top={this.state.topSooltancam} />
                      </div>{
                        (valueSooltancam.length > 5) ? (
                          <section style={{display: 'flex', marginTop: '10px'}}>
                            <div onClick={this._handleNavSooltancam.bind(this, -1)}>
                              <img src={IMAGES.PREV_PAGE} />
                            </div>
                            <div onClick={this._handleNavSooltancam.bind(this, 1)}>
                              <img src={IMAGES.NEXT_PAGE} />
                            </div>
                          </section>
                        ) : ''
                      }
                    </div>  
                  </section>
                  <br />
                  <section>
                    <div className={classes.card}>
                      <label className={classes.captionCards}>User Sharing</label>
                      <section className={classes.countWrapper}>
                        <label className={classes.countCards}>
                          {(valueSharinguser) ? this.sum(valueSharinguser) : 0}
                        </label>
                      </section>
                      <div className={classes.chartWrapper}>
                        <ChartLine
                          chartHeight="185"
                          chartWidth="436"
                          variant="chart-line"
                          title="Chart Title Here"
                          yTotalLabel={1}
                          xLabel={labelMysooltan.slice(this.state.sharinguserStart, this.state.sharinguserStart + 5)}
                          maxValue={Math.max.apply(Math, valueSharinguser.map(function(o) { return o.value; })) + 10}
                          data={[
                            {
                              name: "User Sharing ",
                              color: "#219653",
                              point: valueSharinguser
                            },
                          ]}
                          closeTooltip={this._handleCloseTooltipSharingUser.bind(this)}
                          openTooltip={this._handleOpenTooltipSharinguser.bind(this)}
                        />
                        <Tooltip
                          label={this.state.textSharinguser}
                          left={this.state.leftSharinguser}
                          show={this.state.showSharinguser}
                          top={this.state.topSharinguser} />
                      </div>
                      {
                        (valueSharinguser.length > 5) ? (
                          <section style={{display: 'flex', marginTop: '10px'}}>
                            <div onClick={this._handleNavSharinguser.bind(this, -1)}>
                              <img src={IMAGES.PREV_PAGE} />
                            </div>
                            <div onClick={this._handleNavSharinguser.bind(this, 1)}>
                              <img src={IMAGES.NEXT_PAGE} />
                            </div>
                          </section>
                        ) : ''
                      }
                    </div>
                  </section>
                  <br />
                  <h1 className={classes.title}>Device Status</h1>
                  <section className={classes.cardWrapper}>
                    <div className={classes.cardSummary}>
                      <span className={classes.checkWrapper}>
                        <img src={IMAGES.CHECK} />
                      </span>
                      <span className={classes.summaryInfoWrapper}>
                        <label className={classes.summaryCaption}>
                          Device Aktif
                        </label>
                        <label className={classes.summaryCount}>
                          {(valueActiveDevice) ? this.sum(valueActiveDevice, 'count') : 0}
                        </label>
                      </span>
                    </div>
                    <div className={classes.cardSummary}>
                      <span className={classes.crossWrapper}>
                        <img src={IMAGES.CROSS} />
                      </span>
                      <span className={classes.summaryInfoWrapper}>
                        <label className={classes.summaryCaption}>
                          Device Nonaktif
                        </label>
                        <label className={classes.summaryCount}>                    
                          {(valueInactiveDevice) ? this.sum(valueInactiveDevice, 'count') : 0}
                        </label>
                      </span>
                    </div>  
                  </section>
                  <br />
                  <section>
                    <section className={classes.filterSection}>
                      <h1 className={classes.title}>Device Status Summary</h1>
                    </section>
                  </section>
                  <section className={classes.cardWrapper}>
                    <div className={classes.card}>
                      <label className={classes.captionCards}>Device Aktif</label>
                      <section className={classes.countWrapper}>
                        <label className={classes.countCards}>
                          {(valueActiveDevice) ? this.sum(valueActiveDevice) : 0}
                        </label>
                      </section>
                      <div className={classes.chartWrapper}>
                        <ChartLine
                          chartHeight="185"
                          chartWidth="436"
                          variant="chart-line"
                          title="Chart Title Here"
                          yTotalLabel={1}
                          xLabel={labelActiveDevice.slice(this.state.activeStartDevice, this.state.activeStartDevice + 5)}
                          maxValue={Math.max.apply(Math, valueActiveDevice.map(function(o) { return o.value; })) + 10}
                          data={[
                            {
                              name: "Device Aktif ",
                              color: "#219653",
                              point: valueActiveDevice
                            },
                          ]}
                          closeTooltip={this._handleCloseTooltipDeviceActive.bind(this)}
                          openTooltip={this._handleOpenTooltipDeviceActive.bind(this)}
                        />
                        <Tooltip
                          label={this.state.textDeviceActive}
                          left={this.state.leftDeviceActive}
                          show={this.state.showDeviceActive}
                          top={this.state.topDeviceActive} />
                      </div>
                      {
                        (valueActiveDevice.length > 5) ? (
                          <section style={{display: 'flex', marginTop: '10px'}}>
                            <div onClick={this._handleNavActiveDevice.bind(this, -1)}>
                              <img src={IMAGES.PREV_PAGE} />
                            </div>
                            <div onClick={this._handleNavActiveDevice.bind(this, 1)}>
                              <img src={IMAGES.NEXT_PAGE} />
                            </div>
                          </section>
                        ) : ''
                      }
                    </div>
                    <div className={classes.card}>
                      <label className={classes.captionCards}>Device Nonaktif</label>
                      <section className={classes.countWrapper}>
                        <label className={classes.countCards}>
                          {(valueInactiveDevice) ? this.sum(valueInactiveDevice, 'count') : 0}
                        </label>
                      </section>
                      <div className={classes.chartWrapper}>
                        <ChartLine
                          chartHeight="185"
                          chartWidth="436"
                          variant="chart-line"
                          title="Chart Title Here"
                          yTotalLabel={1}
                          xLabel={labelInactiveDevice.slice(this.state.inactiveStartDevice, this.state.inactiveStartDevice + 5)}
                          maxValue={Math.max.apply(Math, valueInactiveDevice.map(function(o) { return o.value; })) + 10}
                          data={[
                            {
                              name: "Device Nonaktif ",
                              color: "#DE1B1B",
                              point: valueInactiveDevice
                            },
                          ]}
                          closeTooltip={this._handleCloseTooltipDeviceInactive.bind(this)}
                          openTooltip={this._handleOpenTooltipDeviceInactive.bind(this)}
                        />
                        <Tooltip
                          label={this.state.textDeviceInactive}
                          left={this.state.leftDeviceInactive}
                          show={this.state.showDeviceInactive}
                          top={this.state.topDeviceInactive} />
                      </div>{
                        (valueInactiveDevice.length > 5) ? (
                          <section style={{display: 'flex', marginTop: '10px'}}>
                            <div onClick={this._handleNavInactiveDevice.bind(this, -1)}>
                              <img src={IMAGES.PREV_PAGE} />
                            </div>
                            <div onClick={this._handleNavInactiveDevice.bind(this, 1)}>
                              <img src={IMAGES.NEXT_PAGE} />
                            </div>
                          </section>
                        ) : ''
                      }
                    </div>  
                  </section>
                </>
              )
            }
          </section>
        </Base>
      </main>
    );
  }
}

Component.propTypes = {
  actions: PropTypes.object,
  classes: PropTypes.object,
  isLoading: PropTypes.bool,
  users: PropTypes.array,
};

Component.defaultProps = {
  actions: {},
  classes: {},
  isLoading: false,
  users: [],
};
