import { ACTIONS } from '../../constants';
import { IMAGES } from '../../configs';
import { getUserData } from '../../utils/common';

const initialState = {
  download: [],
  user: null,
  users: [],
  update: null,
  message: null,
  page: 1,
  totalData: null,
};

export default function reducer(state = initialState, action) {
  const { message, page, users, type, totalData, update, user } = action;
  let userArrAll = [];
  let userArr = (Array.isArray(state.users)) ? [...state.users] : [];
  let newUsers;
  switch (type) {
    case ACTIONS.RESET_LIST_SUBSCRIBERS:
      return {
        ...state,
        users: [],
        download: []
      };
    case ACTIONS.FETCH_LIST_ALL_SUBSCRIBERS:  
      newUsers = users.map((item) => (
        item = {
          ...item,
          version: item.appVersion ? Object.keys(item.appVersion).length === 0
            && item.appVersion.constructor === Object ?
            '-' : JSON.stringify(item.appVersion).replace(/[^A-Z0-9.]/ig, ' ') : '',
          action: [
            {
              image: IMAGES.REFRESH,
              text: 'Perbaharui Status',
            },
            {
              image: IMAGES.EDIT,
              text: 'Edit Data Pelanggan',
            },
          ]
        }
      ));
      for (let i = 0; i < newUsers.length; i++) {
        userArrAll[userArrAll.length] = newUsers.slice(i, i + 10);
        i = i + 10;
      }
      return {
        ...state,
        users: userArrAll,
        download: users.map((item) => ({
          ...item,
          version: Object.keys(item.appVersion).length === 0
            && item.appVersion.constructor === Object ?
            '-' : JSON.stringify(item.appVersion).replace(/[^A-Z0-9.]/ig, ' '),
        }))
      };
    case ACTIONS.FETCH_LIST_SUBSCRIBERS:
      userArr[page] = users.map((item) => (
        item = {
          ...item,
          version: item.appVersion ? Object.keys(item.appVersion).length === 0
            && item.appVersion.constructor === Object ?
            '-' : JSON.stringify(item.appVersion).replace(/[^A-Z0-9.]/ig, ' ') : '',
          action: getUserData().level < 2 ?[
            {
              image: IMAGES.REFRESH,
              text: 'Perbaharui Status',
            },
            {
              image: IMAGES.EDIT,
              text: 'Edit Data Pelanggan',
            }
          ] : [
            {
              image: IMAGES.REFRESH,
              text: 'Perbaharui Status',
            },
          ]
        }
      ));
      return {
        ...state,
        users: userArr,
        totalData
      };
    case ACTIONS.SELECT_USER:
      return {
        ...state,
        user,
      };
    case ACTIONS.UPDATE_STATUS:
      return {
        ...state,
        update,
        users,
        message,
      };
    default:
      return {
        ...state
      };
  }
}
