import { ACTIONS } from '../../constants';
import { IMAGES } from '../../configs';
import { getUserData } from '../../utils/common';

const initialState = {
  device: {},
  devices: [],
  download: [],
  totalData: 0
};

export default function reducer(state = initialState, action) {
  const { device, devices, page, totalData, type } = action;
  let deviceArrAll = [];
  let deviceArr = (Array.isArray(state.devices)) ? [...state.devices] : [];
  
  switch (type) {
    case ACTIONS.FETCH_LIST_ALL_DEVICES:  
      for (let i = 0; i < devices.length; i++) {
        deviceArrAll[deviceArrAll.length] = devices.slice(i, i + 10);
        i = i + 10;
      }
      return {
        ...state,
        devices: deviceArrAll,
        download: devices
      };
    case ACTIONS.FETCH_LIST_DEVICES:
      deviceArr[page - 1] = devices.map((item) => (
        item = {
          ...item,
          msisdn: item.msisdn ? item.msisdn : item.ndmain,
          cloud_status: item.cloud_recording_status === 'enable' ? 'aktif' : 'non-aktif',
          action: getUserData().level < 2 && [
            {
              image: IMAGES.DELETE,
              text: 'Hapus Perangkat',
            },
          ]
        }
      ));

      return {
        ...state,
        devices: deviceArr,
        totalData
      };
    case ACTIONS.RESET_LIST_DEVICES:
      return {
        ...state,
        devices: []
      };
    case ACTIONS.SELECT_DEVICE:
      return {
        ...state,
        device
      };
    default:
      return {
        ...state
      };
  }
}
