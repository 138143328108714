import React, { useState } from 'react';
import { Field, change, reset } from 'redux-form';
import PropTypes from 'prop-types';
import Button from '../../elements/Button';
import Dropdown from '../../elements/Dropdown';
import Textfield from '../../elements/Textfield';

const field = ({ className, input, placeholder, values }) => {
  const propsInput = {
    className: className,
    input: {
      name: input.name,
      onChange: input.onChange,
    },
    meta: {
      'icon-align': 'left',
      disabled: false,
      error: false,
      placeholder: placeholder,
      type: 'text',
      variant: 'basic-filled',
      value: values
    },
  };
  return (
    <Textfield {...propsInput} />
  );
};
  
export default function FormCustomer(props) {
  const { classes, dispatch, handleSubmit, initialValues, onClose, onSubmit } = props;
  const packages = [
    {
      package_code: 'C18055IHS2ABOAPP',
      package_name: 'Indihome Smart V2 - Smart Camera',
      package_description :  '- IOT Smart Camera\\n'
    },
    {
      package_code: 'C18055IHS2ABOAPP',
      package_name: 'Indihome Smart V2 - Smart Home',
      package_description: '- IOT Smart Home Camera\\n - Mini Gateway\\n'
    },
    {
      package_code: 'IHS3SEWA01',
      package_name: 'Indihome Smart V3 - Smart Camera',
      package_description: '- IOT Smart Camera\\n'
    },
    {
      package_code: 'IHS3SEWA01',
      package_name: 'Indihome Smart V3 - Smart Home',
      package_description: '- IOT Smart Home Camera\\n - Mini Gateway\\n'
    }
  ];
  const name = initialValues.paket && initialValues.paket.split(' | ')[1];
  const code = initialValues.paket && initialValues.paket.split(' | ')[0];
  const [index, setIndex] = useState(packages.findIndex(item => 
    item.package_code === code && item.package_name === name) > -1 ?
    packages.findIndex(item => item.package_code === code && item.package_name === name) : 0);
  const changeDropdown = (index) => {
    setIndex(index);
    dispatch(change('EditCustomer','package_index', index));
  };
  
  const close = () => {
    dispatch(reset());
    onClose();
  };

  const fields = [
    {
      name: 'name',
      placeholder: 'Nama Pengguna'
    },
    {
      name: 'msisdn',
      placeholder: 'Nomor HP'
    },
    {
      name: 'ndmain',
      placeholder: 'Nomor Indihome'
    },
    {
      name: 'email',
      placeholder: 'E-mail'
    },
  ];
  const filterItem = [
    'Indihome Smart V2 - Smart Camera',
    'Indihome Smart V2 - Smart Home',
    'Indihome Smart V3 - Smart Camera',
    'Indihome Smart V3 - Smart Home'];
  return (
    <form className={classes.root} onSubmit={handleSubmit(onSubmit)}>
      <span className={classes.button} onClick={close}>X</span>
      <h1 className={classes.title}>Data Pelanggan</h1>
      <section className={classes.fields}>
        {
          fields.map((item, index) => {
            return (
              <div className={classes.field} key={index}>
                <label className={classes.label}>{item.placeholder}</label>
                <Field className={classes.textfield}
                  component={field} name={item.name} placeholder={item.placeholder}
                  values={initialValues[`${item.name}`]}
                />
              </div>
            );
          })
        }
        <div className={classes.field}>
          <label className={classes.label}>Paket</label>
          <Dropdown border="true" className={classes.dropdown} items={filterItem}
            onChange={changeDropdown} selected-item={index ? packages[index].package_name : 0}
            variant="white"
          />
        </div>
        <div className={classes.field}>
          <label className={classes.caption}>*Terakhir di perbaharui pada tanggal {
            initialValues.updatedAt}</label>
        </div>
        <div className={classes.field}>
          <Button className={classes.buttonSubmit} color="primary" size="32" >
            Perbaharui
          </Button>
        </div>
      </section>
    </form>
  );
}

FormCustomer.defaultProps = {
  classes: {},
  dispatch: () => { },
  handleSubmit: () => { },
  initialValues: {},
  onClose: () => { },
  onSubmit: () => { },
};

FormCustomer.propTypes = {
  classes: PropTypes.object,
  dispatch: PropTypes.func,
  handleSubmit: PropTypes.func,
  initialValues: PropTypes.object,
  onClose: PropTypes.func,
  onSubmit: PropTypes.func,
};

field.defaultProps = {
  className: undefined,
  input: {},
  placeholder: undefined,
  values: undefined
};

field.propTypes = {
  className: PropTypes.string,
  input: PropTypes.object,
  placeholder: PropTypes.string,
  values: PropTypes.string
};
