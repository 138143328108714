import { convertHex } from '../../../utils/helpers';

const styles = theme => ({
  active: {
    color: `#828282 !important`,
  },
  disabled: {
    '&:hover': {
      cursor: 'auto',
      textDecoration: 'none'
    },
    color: theme.palette.grey[40]
  },
  li: {
    ...theme.typography.subtitle2,
    '& svg': {
      verticalAlign: 'middle'
    },
    '&:focus': {
      boxShadow: `0 0 0 3px ${convertHex(theme.palette.base.primary.main, 50)}`,
      outline: 'none'
    },
    '&:hover': {
      cursor: 'pointer',
      textDecoration: 'underline'
    },
    alignItems: 'center',
    color: '#EE3124',
    display: 'inline-flex',
    listStyle: 'none',
    marginLeft: 10,
    marginRight: 10,
    tabIndex: '0'
  },
  liArray: {
    ...theme.typography.subtitle2,
    '& svg': {
      marginRight: 10,
      verticalAlign: 'middle'
    },
    '&:focus': {
      boxShadow: `0 0 0 3px ${convertHex(theme.palette.base.primary.main, 50)}`,
      outline: 'none'
    },
    '&:hover': {
      cursor: 'pointer',
      textDecoration: 'underline'
    },
    alignItems: 'center',
    color: theme.palette.grey[60],
    display: 'inline-flex',
    listStyle: 'none',
    marginLeft: 10,
    marginRight: 10,
    tabIndex: '0'
  },
  ol: {
    alignItems: 'center',
    display: 'inline-flex',
    listStyle: 'none',
    margin: '0 !important',
    padding: 0,
  },
  root: {},
  separator: {
    color: theme.palette.grey[60],
    display: 'inline-flex',
    userSelect: 'none'
  }
});
export default styles;
