import React from 'react';
import PropTypes from 'prop-types';
import Button from '../../components/elements/Button';
import Textfield from '../../components/elements/Textfield';
import { IMAGES } from '../../configs';

export default class Component extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      password: null,
      passwordType: true,
      remember: false,
      username: null,
    };
    
    this._handleEnter = this._handleEnter.bind(this);
    this._handleRemember = this._handleRemember.bind(this);
    this._handleShowpassword = this._handleShowpassword.bind(this);
    this._handleSubmit = this._handleSubmit.bind(this);
  }

  _handleEnter(e) {
    if (e.key === 'Enter') {
      this.setState({ remember: !this.state.remember });
    }
  }

  _handlePassword(password) {
    this.setState({ errorPassword: false, password });
  }

  _handleRemember(e) {
    this.setState({ remember: e.target.checked });
  }

  _handleShowpassword() {
    this.setState({ passwordType: !this.state.passwordType });
  }

  _handleSubmit() {
    const { actions } = this.props;
    const { password, remember, username } = this.state;
    const payload = {
      password,
      remember,
      username,
    };
    
    if (username && password) {
      actions.login(payload);
    } else {
      if (!username) {
        this.setState({ errorUsername: true });
      }

      if (!password) {
        this.setState({ errorPassword: true });
      }
    }
  }

  _handleUsername(username) {
    this.setState({ errorUsername: false, username });
  }

  render () {
    const  { classes, error } = this.props;
    const errorLabel = (error) ? (
      <div className={classes.error}>
        {error}
      </div>
    ) : null;
    const imagePassword = (this.state.passwordType) 
      ? (<img id="show-password" onClick={this._handleShowpassword} src={IMAGES.EYE} />)
      : (<img id="show-password" onClick={this._handleShowpassword} src={IMAGES.EYE_OPEN} />);
    
    const propsUsername = {
      className: classes.textfield,
      input: {
        name: 'username',
        onChange: this._handleUsername.bind(this)
      },
      meta: {
        'icon-align': 'left',
        error: this.state.errorUsername,
        icon: <img src={IMAGES.PEOPLE}/>,
        placeholder: (!this.state.errorUsername) ? 'Username'
          : 'Silahkan masukan username Anda',
        type: 'text',
        variant: 'basic-filled',
      },
    };
    const password = (this.state.passwordType) ? 'password' : 'text';
    const propsPassword = {
      className: classes.textfield,
      input: {
        name: 'password',
        onChange: this._handlePassword.bind(this),
        value: this.state.password
      },
      meta: {
        'icon-align': 'left',
        error: this.state.errorPassword,
        icon: <img src={IMAGES.KEY}/>,
        iconRight: imagePassword,
        placeholder: (!this.state.errorPassword) ? 'Password'
          : 'Silahkan masukan password Anda',
        type: password,
        variant: 'basic-filled',
      },
    };

    return (
      <main className={classes.root}>
        <section className={[classes.section, classes.sectionForm].join(' ')}>
          {errorLabel}
          <div className={classes.form}>
            <h1 className={classes.formTitle}>
              Selamat datang di <b className={classes.bold}>IndiHome Smart</b>
            </h1>
            <h4 className={classes.formDescription}>
              Silahkan masuk dengan menggunakan akun Anda.
            </h4>
            <Textfield {...propsUsername} />
            <Textfield {...propsPassword} />
            <section className={classes.formItem}>
              <input checked={this.state.remember} className={classes.check} id="remember" 
                onChange={this._handleRemember} 
                onKeyDown={this._handleEnter} type="checkbox" />
              <label className={classes.remember} htmlFor="remember">Ingat Saya</label>
              <label className={classes.forgot}>Lupa Password?</label>
            </section>
            <div onClick={this._handleSubmit.bind(this)}>
              <Button className={classes.button} color="primary" size="32" >
                Login
              </Button>
            </div>
            <p className={classes.copyright}>
              Copyright © 2020 <label className={classes.red}>
                IndiHome Smart.
              </label> All rights reserved.
            </p>
          </div>
        </section>
        <section className={classes.section}>
          <img className={classes.imageLogo} src={IMAGES.LOGOWHITE}/>
          <section className={[classes.sectionImage].join(' ')}>
            <img className={classes.image} src={IMAGES.LOGINCOVER} />
          </section>
        </section>
      </main>
    );
  }
}

Component.propTypes = {
  actions: PropTypes.object,
  classes: PropTypes.object,
  error: PropTypes.bool,
};

Component.defaultProps = {
  actions: {},
  classes: {},
  error: false,
};
