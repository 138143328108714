const lime = {
  10: '#F4FCE3',
  20: '#E9FAC8',
  30: '#D8F5A2',
  40: '#C0EB75',
  50: '#A9E34B',
  60: '#94D82D',
  70: '#82C91E',
  80: '#74B816',
  90: '#66A80F',
  100: '#5C940D',
};

export default lime;
