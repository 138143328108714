import { createMuiTheme } from '@material-ui/core/styles';
import { Blue, Purple, Red, Yellow, Grey, Sky, Lime } from '../components/elements/Colors';

const fontWeight = {
  light: 300,
  regular: 400,
  medium: 500,
  bold: 700
};

export const themeMui = createMuiTheme({
  overrides: {
    MuiBackdrop: {
      root: {
        backgroundColor: 'rgba(68, 75, 85, 0.8)'
      }
    }
  },
  palette: {
    background: {
      default: '#fafafa'
    },
  },
});

export const COLOR_PRIMARY = '#e141f2';
export const COLOR_PRIMARY_2 = '#ff00ff';
export const COLOR_WHITE = '#ffffff';
export const theme = {
  palette: {
    base: {
      primary: {
        main: Red[60],
        dark: Red[70],
        light: Red[50],
      },
      secondary: {
        main: Purple[60],
        dark: Purple[70],
        light: Purple[50],
      },
      //optional
      tertiary: {
        main: Blue[60],
        dark: Blue[70],
        light: Blue[50],
      },
      outline: {
        main: COLOR_WHITE,
        dark: COLOR_WHITE,
        light: COLOR_WHITE,
      }
    },

    grey: Grey,

    common: {
      black: Grey[100],
      white: '#FFFFFF'
    },

    event: {
      success: { 
        main: Lime[80],
        light: Lime[10]
      },
      error: { 
        main: Red[60],
        light: Red[10]
      },
      information: {
        main: Sky[50],
        light: Sky[10]
      },
      warning: {
        main: Yellow[80],
        light: Yellow[10]
      },
      disabled: { main: Grey[50] }
    },

    text: {
      primary: {
        dark: Red[50],
        light: Red[80]
      },
      secondary: {
        dark: Purple[50],
        light: Purple[80]
      }
    },
  },
  typography: {
    fontFamily: `"Poppins`,
    fontSize: 14,
    fontWeightLight: fontWeight.light,
    fontWeightRegular: fontWeight.regular,
    fontWeightMedium: fontWeight.medium,
    fontWeightBold: fontWeight.bold,
    fontSizeInherit: 'inherit',

    h1: {
      fontSize: '60px',
      fontWeight: fontWeight.light,
      letterSpacing: '-0.5px'
    },
    h2: {
      fontSize: '48px',
      fontWeight: fontWeight.light,
      letterSpacing: '0px'
    },
    h3: {
      fontSize: '34px',
      fontWeight: fontWeight.regular,
      letterSpacing: '0.25px'
    },
    h4: {
      fontSize: '24px',
      fontWeight: fontWeight.regular,
      letterSpacing: '0px'
    },
    h5: {
      fontSize: '20px',
      fontWeight: fontWeight.medium,
      letterSpacing: '0.15px'
    },
    subtitle1: {
      fontSize: '16px',
      fontWeight: fontWeight.regular,
      letterSpacing: '0.15px'
    },
    subtitle2: {
      fontSize: '14px',
      fontWeight: fontWeight.medium,
      letterSpacing: '0.1px'
    },
    body1: {
      fontSize: '16px',
      fontWeight: fontWeight.regular,
      letterSpacing: '0.5px'
    },
    body2: {
      fontSize: '14px',
      fontWeight: fontWeight.regular,
      letterSpacing: '0.25px'
    },
    button: {
      fontSize: '14px',
      fontWeight: fontWeight.bold,
      letterSpacing: '1px'
    },
    caption: {
      fontSize: '12px',
      fontWeight: fontWeight.regular,
      letterSpacing: '0.4px'
    },
    overline1: {
      fontSize: '10px',
      fontWeight: fontWeight.regular,
      letterSpacing: '0px',
    },
    overline2: {
      fontSize: '10px',
      fontWeight: fontWeight.medium,
      letterSpacing: '0px',
    },
  }
};
