import React from 'react';
import { Switch, Route } from 'react-router-dom';
import PropTypes from 'prop-types';
import CssBaseline from '@material-ui/core/CssBaseline';
import { MuiThemeProvider } from '@material-ui/core/styles';
import pages from './pages';
import { ROUTES } from './configs';
import { ThemeProvider } from 'react-jss';
import { theme, themeMui } from './styles';
import { checkExpireTime, clearStorages, getRemember, getUserData } from '../src/utils/common';

export default class App extends React.Component {
  constructor(props) {
    super(props);

    const [isLoggedIn, shouldRender] = this._checkAuth();

    this.state = {
      isLoggedIn,
      shouldRender
    };
  }
  
  _checkAuth() {
    const { pathname } = location;
    const { CUSTOMER_LIST, LOGIN } = ROUTES;
    const data = getUserData();
    const isExpired = checkExpireTime();
    const validData = data;
    const isLoggedIn = (pathname == LOGIN());
    let shouldRender = true;
    
    if (!validData && pathname !== LOGIN()) {
      location.href = LOGIN();
      shouldRender = false;
    } else if (isExpired && pathname !== LOGIN()) {
      clearStorages();
      location.href = LOGIN();
      shouldRender = false;
    } else if (isExpired) {
      clearStorages();
    } else if (validData && pathname == LOGIN()) {
      location.href = CUSTOMER_LIST();
      shouldRender = false;
    }

    return [isLoggedIn, shouldRender];
  }

  _renderLogin() {
    const { Login } = pages;

    return (
      <ThemeProvider theme={theme}>
        <MuiThemeProvider theme={themeMui}>
          <CssBaseline />
          <Login />
        </MuiThemeProvider>
      </ThemeProvider>
    );
  }

  _renderApp() {
    return (
      <ThemeProvider theme={theme}>
        <main>
          <MuiThemeProvider theme={themeMui}>
            <CssBaseline />
            <Switch>
              <Route exact path={ROUTES.ADMIN()} component={pages.Admin} />
              <Route exact path={ROUTES.CUSTOMER_LIST()} component={pages.ListCustomer} />
              <Route exact path={ROUTES.CUSTOMER_LIST_SHARING()} component={pages.ListCustomerSharing} />
              <Route exact path={ROUTES.DASHBOARD()} component={pages.Dashboard} />
              <Route exact path={ROUTES.DEVICE_LIST()} component={pages.ListDevice} />
              <Route exact path={ROUTES.ERROR_EVENT()} component={pages.ErrorEvent} />
              <Route exact path={ROUTES.LOG_DEVICE()} component={pages.LogDevice} />
              <Route exact path={ROUTES.LOG_EMAIL()} component={pages.LogEmail} />
              <Route exact path={ROUTES.SETTINGS()} component={pages.EditProfile} />
              <Route component={pages.Error404} />
            </Switch>
          </MuiThemeProvider>
        </main>
      </ThemeProvider>
    );
  }

  render() {
    const { isLoggedIn, shouldRender } = this.state;

    if (getRemember() === 'false') {
      window.onbeforeunload = function() {
        clearStorages();
      }
    }

    if (!shouldRender) {
      return (null);
    }

    return (
      isLoggedIn ? this._renderLogin() : this._renderApp()
    );
  }
}

App.propTypes = {
  children: PropTypes.element
};