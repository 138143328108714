const styles = () => ({
  border: {
    border: '1px solid #D9DEE4',
    borderRadius: '2px',
    boxSizing: 'border-box',
  },
  chevron: {
    alignItems: 'center',
    display: 'flex',
    height: '100%',
    paddingLeft: '10px',
  },
  chevronWhite: {
    alignItems: 'center',
    display: 'flex',
    height: '100%',
    marginLeft: '10px',
    paddingLeft: '10px',
  },
  dropdownItem: {
    position: 'absolute',
    top: '100%',
    width: '100%',
    zIndex: '2',
  },
  icon: {
    height: '21px',
    width: '21px',
  },
  label: {
    color: '#EE3124',
    fontSize: '12px',
    fontStyle: 'normal',
    fontWeight: 500,
    lineHeight: '18px',
    marginBottom: 0
  },
  labelWhite: {
    color: 'rgba(0, 0, 0, 0.54)',
    fontSize: '12px',
    fontStyle: 'normal',
    fontWeight: 500,
    lineHeight: '18px',
  },
  rootDropdown: {
    alignItems: 'center',
    background: 'rgba(232, 176, 172, 0.3)',
    borderRadius: '5px',
    display: 'flex',
    height: '38px',
    justifyContent: 'space-between',
    padding: '3px 11px',
    position: 'relative',
  },
  separate: {
    borderLeft: '1px solid #ffffff',
  },
  white: {
    background: '#ffffff',
    border: '1px solid #D9DEE4',
    boxSizing: 'border-box',
  },
  wrapper: {
    position: 'relative',
  }
});

export default styles;
