/* eslint-disable max-lines */
import fetch, { getBasicSooltancam } from '../../utils/fetch';
import moment from 'moment';
import { AUTH_KEY, SERVICES } from '../../configs';
import { ACTIONS } from '../../constants';
import { getEazy, getUserData } from '../../utils/common';

const packages = [
  {
    package_code: 'C18055IHS2ABOAPP',
    package_name: 'Indihome Smart V2 - Smart Camera',
    package_description :  '- IOT Smart Camera\\n'
  },
  {
    package_code: 'C18055IHS2ABOAPP',
    package_name: 'Indihome Smart V2 - Smart Home',
    package_description: '- IOT Smart Home Camera\\n - Mini Gateway\\n'
  },
  {
    package_code: 'IHS3SEWA01',
    package_name: 'Indihome Smart V3 - Smart Camera',
    package_description: '- IOT Smart Camera\\n'
  },
  {
    package_code: 'IHS3SEWA01',
    package_name: 'Indihome Smart V3 - Smart Home',
    package_description: '- IOT Smart Home Camera\\n - Mini Gateway\\n'
  }
];
let error = '';

export function fetchUser(page,query = '',size = 10, date) {
  return dispatch => {
    let options = {
      method: 'GET',
      params: {
        startDate: date ? moment(date.split(' - ')[0]).format('YYYY-MM-DD') : null,
        endDate: date ? moment(date.split(' - ')[1]).format('YYYY-MM-DD') : null
      },
      url: SERVICES.GET_USERS_SUBSCRIBE(page,size) + query + '&download=false',
      headers: { 
        'Authorization': `Bearer ${getUserData().userToken}`, 
      }
    };

    if (getBasicSooltancam()) {
      delete options.headers;

      options.auth = getBasicSooltancam();
      options.url = SERVICES.GET_USERS_SUBSCRIBE_SOOLTANCAM(page,size) + query + '&download=false';
    }

    if (getEazy() === 'true') {
      options.params = {
        ...options.params,
        startDate: date ? moment(date.split(' - ')[0]).format('YYYY-MM-DD') : null,
        endDate: date ? moment(date.split(' - ')[1]).format('YYYY-MM-DD') : null,
        isEazy: true
      };
    }

    dispatch(loading());
    
    fetch(options)
      .then(res => {
        if (res.data) {
          dispatch(setSubscribers(res.data.filter.page - 1, res.data.totalData, res.data.list));
          dispatch(doneLoading());
        }
      })
      .catch(() => {
        dispatch(doneLoading());
      });
  };
}

export function fetchAllUser(page,query = '',size = 10, showModalDownload, date) {
  return dispatch => {
    let options = {
      method: 'GET',
      url: SERVICES.GET_USERS_SUBSCRIBE(page,size) + query + '&download=true',
      params: {
        startDate: date ? moment(date.split(' - ')[0]).format('YYYY-MM-DD') : null,
        endDate: date ? moment(date.split(' - ')[1]).format('YYYY-MM-DD') : null
      },
      headers: { 
        'Authorization': `Bearer ${getUserData().userToken}`, 
      }
    };

    if (getBasicSooltancam()) {
      delete options.headers;

      options.auth = getBasicSooltancam();
      options.url = SERVICES.GET_USERS_SUBSCRIBE_SOOLTANCAM(page,size) + query + '&download=true';
    }

    if (getEazy() === 'true') {
      options.params = {
        ...options.params,
        isEazy: true
      };
    }

    dispatch(loading());
    
    fetch(options)
      .then(res => {
        if (res.data) {
          // dispatch({
          //   type: ACTIONS.FETCH_LIST_ALL_SUBSCRIBERS,
          //   users: res.data.list
          // });
          
          download(res.data.url, 'all-users');
          showModalDownload();
          dispatch(doneLoading());
        }
      })
      .catch(() => {
        dispatch(doneLoading());
      });
  };
}

export function resetList() {
  return dispatch=> {
    dispatch({
      type: ACTIONS.RESET_LIST_SUBSCRIBERS,
    });
  };
}

export function updateStatus(ndmain, index, users) {
  return dispatch => {
    let options = {
      method: 'GET',
      url: SERVICES.UPDATE_STATUS + '?ndmain=' + ndmain,
      headers: { 
        'Authorization': `Bearer ${getUserData().userToken}`, 
      }
    };

    if (getBasicSooltancam()) {
      delete options.headers;

      options.auth = getBasicSooltancam();
    }

    dispatch({
      type: ACTIONS.UPDATE_STATUS,
      update: null,
      message: '',
    });

    fetch(options)
      .then((res) => {
        dispatch({
          type: ACTIONS.UPDATE_STATUS,
          update: true,
          users: [
            ...users.slice(0, index),
            {
              ...users[index],
              status: res.data.status,
            },
            ...users.slice(index + 1)
          ],
          message: res.data,
        });
      })
      .catch(() => {
        dispatch({
          type: ACTIONS.UPDATE_STATUS,
          update: false,
        });
      });
  };
}

export  function updateUser(value, oldValue, page, query, size = 10) {
  return async dispatch => {
    let msisdnProcess = false;
    let updateuserProcess = false;
    let updatepackageProcess = false;
    
    msisdnProcess = await updateMsisdn(value.msisdn, oldValue.msisdn, value.userId);
    if (msisdnProcess) {
      updateuserProcess = await updateDataUser(value);
    }
    if (updateuserProcess) {
      updatepackageProcess = await updatePackage(value);
    }
    if (updatepackageProcess && updateuserProcess && msisdnProcess) {
      dispatch({
        type: ACTIONS.SELECT_USER,
        user: null
      });
      dispatch({
        type: ACTIONS.UPDATE_STATUS,
        update: true,
        message: false,
      });

      let options = {
        method: 'GET',
        url: SERVICES.GET_USERS_SUBSCRIBE(page,size) + query,
        headers: { 
          'Authorization': `Bearer ${getUserData().userToken}`, 
        }
      };
  
      if (getBasicSooltancam()) {
        delete options.headers;
  
        options.auth = getBasicSooltancam();
        options.url = SERVICES.GET_USERS_SUBSCRIBE_SOOLTANCAM(page,size) + query;
      }  
  
      dispatch(loading());
      
      fetch(options)
        .then(res => {
          if (res.data) {
            dispatch(setSubscribers(res.data.filter.page - 1, res.data.totalData, res.data.list));
            dispatch(doneLoading());
          }
        })
        .catch(() => {
          dispatch(doneLoading());
        });
    } else {
      dispatch({
        type: ACTIONS.SELECT_USER,
        user: null
      });
      dispatch({
        type: ACTIONS.UPDATE_STATUS,
        update: false,
        message: error,
      });
    }
  };
}

async function updateMsisdn(msisdn, oldMsisdn, userId) {
  if (msisdn !== oldMsisdn) {
    let options = {
      method: 'PUT',
      url: SERVICES.UPDATE_MSISDN,
      data: {
        newMsisdn: msisdn,
        userId,
        forceUpdate: true
      },
      headers: { 
        'Authorization': `Bearer ${getUserData().userToken}`, 
      }
    };

    if (getBasicSooltancam()) {
      delete options.headers;

      options.auth = getBasicSooltancam();
    }

    return await fetch(options)
      .then(() => {
        return true;
      })
      .catch((err) => {
        error = err;
        return false;
      });
  } else {
    return await true;
  }
}

async function updateDataUser(value) {
  const { email, name, userId } = value;
  const newValue = {
    email, userId, fullname: name
  };
  Object.keys(newValue).forEach((key) => (newValue[key] === null || newValue[key] === '') && delete newValue[key]);
  let options = {
    method: 'PUT',
    url: SERVICES.UPDATE_USER,
    data: newValue,
    headers: { 
      'Authorization': `Bearer ${getUserData().userToken}`, 
    }
  };

  if (getBasicSooltancam()) {
    delete options.headers;

    options.auth = getBasicSooltancam();
  }

  return await fetch(options)
    .then(() => {
      return true;
    })
    .catch((err) => {
      error = err;
      return false;
    });
}

async function updatePackage(value) {
  if (value.package_index) {
    const options = {
      method: 'POST',
      url: SERVICES.UPDATE_SUBSCRIBE,
      data: {
        ...packages[value.package_index],
        user_name: value.userId,
        nd: (value.ndmain) ? value.ndmain : value.msisdn,
        type: 'subscribe',
      },
      headers: { 
        'Authorization': AUTH_KEY ,
        'Content-Type': 'application/json',
      },
    };

    return await fetch(options)
      .then(() => {
        return true;
      })
      .catch((err) => {
        error = err;
        return false;
      });
  } else {
    return true;
  }
}

export function resetModal() {
  return dispatch => {
    dispatch({
      type: ACTIONS.UPDATE_STATUS,
      update: null,
    });
  };
}

export function setUser(user) {
  return {
    type: ACTIONS.SELECT_USER,
    user
  };
}

function doneLoading() {
  return {
    type: ACTIONS.DONE_LOADING,
  };
}

function loading() {
  return {
    type: ACTIONS.LOADING,
  };
}

function setSubscribers(page, totalData, users) {
  return {
    type: ACTIONS.FETCH_LIST_SUBSCRIBERS,
    page,
    totalData,
    users
  };
}

function download(dataurl, filename) {
  const link = document.createElement('a');
  link.href = dataurl;
  link.download = filename;
  link.click();
}
