import React from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import {  } from 'react-router';
import { IMAGES, MENU, ROUTES } from '../../../configs';
import Avatar from '../../elements/Avatar';
import Button from '../../elements/Button';
import Breadcrumbs from '../../elements/Breadcrumbs';
import Icon from '../../elements/Icon';
import ListItem from '../../elements/ListItem';
import { clearStorages, getEazy, getSooltanCam, getUserData, setEazy, setSooltanCam } from '../../../utils/common';

export default class Pagebase extends React.Component {
  constructor(props) {
    super(props);

    const data = getUserData();
    let menu = data ? ((data.level < 1) ? ['Settings', 'List Admin', 'Logout']
      : ['Settings', 'Logout']) : ['Settings', 'Logout'];
    this.state = {
      indexMenu: null,
      listUsermenu: menu,
      userDropdown: false,
      showModalLogout: false,
      showSidebar: true,
      showSubmenu: false,
      menu: getSooltanCam() === 'true' ? MENU.sooltancam : (data?.level === 4 ? MENU[147] : MENU.index)
    };

    this.setWrapperRef = this.setWrapperRef.bind(this);
    this._handleClickOutside = this._handleClickOutside.bind(this);
    this._handleSidebar = this._handleSidebar.bind(this);
    this._handleUserDropdown = this._handleUserDropdown.bind(this);
  }

  componentDidMount() {
    document.addEventListener('mousedown', this._handleClickOutside);
  }

  componentWillUnmount() {
    document.removeEventListener('mousedown', this._handleClickOutside);
  }

  setWrapperRef(node) {
    this.wrapperRef = node;
  }

  _handleClickLogo(logo) {
    setSooltanCam(logo === 'sooltancam');
    setEazy(logo === 'eazy');

    this.setState({ logoToggle: false });

    window.location.reload();
  }

  _handleClickOutside(event) {
    if (this.wrapperRef && !this.wrapperRef.contains(event.target)) {
      this.setState({ userDropdown: false });
    }
  }

  _handleClickMenuUser(index) {
    if (this.state.listUsermenu[index] === 'Logout') {
      this.setState({ showModalLogout: true, userDropdown: false });
    }

    if (this.state.listUsermenu[index] === 'Settings') {
      this.props.history.push(ROUTES.SETTINGS());
    }

    if (this.state.listUsermenu[index] === 'List Admin') {
      this.props.history.push(ROUTES.ADMIN());
    }
  }

  _handleLogout(logout) {
    if (logout) {
      clearStorages();
      location.href = '/';
    } else {
      this.setState({ showModalLogout: false });
    }
  }

  _handleUserDropdown() {
    this.setState({
      userDropdown: !this.state.userDropdown,
    });
  }

  _handleSidebar() {
    this.setState({
      showSidebar: !this.state.showSidebar,
    });
  }

  _handleSubMenu(indexMenu) {
    this.setState({
      showSubmenu: !this.state.showSubmenu,
    }, () => {
      if (this.state.showSubmenu === true) {
        this.setState({ indexMenu });
      } else {
        this.setState({ indexMenu: null });
      }
    });
  }

  renderBreadcrumb() {
    const { classes } = this.props;
    let locations = window.location.pathname.split('/');

    locations.shift();

    return (
      <Breadcrumbs >
        {
          locations.map((location, index) => (
            <div className={classes.capitalize} key={index}>{location.replace('_', ' ')}</div>
          ))
        }
      </Breadcrumbs>
    );
  }

  renderSidebar() {
    const { classes } = this.props;
    const currentMenu = (location.pathname.split('/')[1]) ? location.pathname.split('/')[1]
      : null;
    const currentSubMenu = (location.pathname.split('/')[2]) ? location.pathname.split('/')[2]
      : null;

    return (
      <section className={classes.sideBar}>
        <span className={[classes.logoWrapper].join(' ')}>
          {
            (this.state.logoToggle === true || (getSooltanCam() !== 'true' && getEazy() !== 'true')) && (              
              <img className={classes.logo} onClick={this._handleClickLogo.bind(this, 'ihsmart')} src={IMAGES.LOGO} />
            )
          }
          {
            (this.state.logoToggle === true || getSooltanCam() === 'true') && (              
              <img className={classes.logoSooltancam} onClick={this._handleClickLogo.bind(this, 'sooltancam')} src={IMAGES.LOGO_SOOLTANCAM} />
            )
          }          
          {
            (this.state.logoToggle === true || getEazy() === 'true') && (              
              <img className={classes.logoEazy} onClick={this._handleClickLogo.bind(this, 'eazy')} src={IMAGES.LOGO_EAZY} />
            )
          }        
          <img className={classes.logoChevron} onClick={() => {
            this.setState({ logoToggle: !this.state.logoToggle }); }}
          src={IMAGES.CHEVRON_DOWN} />
        </span>
        <span className={classes.listMenu}>
          {
            this.state.menu.map((item, index) => {
              const currentMenuClass = (item.menuTitle === currentMenu) ? classes.currentMenu
                : null;
              const rotate = (this.state.indexMenu !== index && !this.state.showSubmenu)
                ? classes.rotate : null;
              const chevron = (item.subMenu) ? <img className={rotate} src={IMAGES.COLLAPSE_RED} /> : '';
              const click = (item.subMenu) ? this._handleSubMenu.bind(this, index) : '';
              const menuContent = (item.route) ? (
                <span className={[classes.menu, currentMenuClass].join(' ')}
                  onClick={click}>
                  <Link className={classes.link} to={item.route}>
                    <label className={classes.labelMenu}>
                      <img className={classes.menuImagePng} src={item.image} />
                      {item.menuTitle}
                    </label>
                    {chevron}
                  </Link>
                </span>
              ) : (
                <span className={[classes.menu, currentMenuClass].join(' ')}
                  onClick={click}>
                  <label className={classes.labelMenu}>
                    <img className={classes.menuImagePng} src={item.image} />
                    {item.menuTitle}
                  </label>
                  {chevron}
                </span>
              );
              return (
                <span className={classes.block} key={index}>
                  {menuContent}
                  <span className={classes.block}>
                    {
                      (this.state.indexMenu === index && this.state.showSubmenu && item.subMenu) ?
                        item.subMenu.map((submenu, indexSub) => {
                          const currentSubMenuClass = (submenu.subMenuTitle === currentSubMenu)
                            ? classes.subMenuCurrent
                            : null;
                          const currentSubMenuImageClass = (submenu.subMenuTitle === currentSubMenu)
                            ? classes.menuImageCurrent
                            : null;

                          return (
                            <Link className={[classes.capitalize, classes.subMenu,
                              currentSubMenuClass].join(' ')} key={indexSub} to={submenu.route}>
                              <Icon className={[currentSubMenuImageClass,
                                classes.menuImage].join(' ')} src={submenu.image} />

                              {submenu.subMenuTitle.replace('_', ' ')}
                            </Link>
                          );
                        })
                        : null
                    }
                  </span>
                </span>
              );
            })
          }
        </span>
      </section>
    );
  }

  render() {
    const { children, classes } = this.props;
    const blurClass = (this.state.showModalLogout) ? classes.blur : null;
    const modalLogout = (this.state.showModalLogout) ? (
      <section className={classes.logoutWrapper}>
        <span className={classes.logoutOverlay} onClick={this._handleLogout.bind(this, false)} />
        <div className={classes.logoutModal}>
          <h2 className={classes.logoutTitle}>Log Out</h2>
          <span className={classes.logoutDivider} />
          <div className={classes.logoutDescription}>
            Apakah kamu yakin akan keluar dari dashboard IndiHome Smart?
          </div>
          <section className={classes.logoutButton}>
            <Button className={[classes.buttonLogout, classes.buttonWhite].join(' ')} color="primary"
              onClick={this._handleLogout.bind(this, false)} size="32">
              <label className={classes.buttonLabel}>
                Batal
              </label>
            </Button>
            <Button className={classes.buttonLogout} color="primary"
              onClick={this._handleLogout.bind(this, true)} size="32">
              <label className={classes.buttonLabel}>Logout</label>
            </Button>
          </section>
        </div>
      </section>
    ) : null;
    const rootClass = [
      blurClass,
      classes.root
    ].join(' ');
    const showUserDropdown = (this.state.userDropdown) ? (
      <span className={classes.dropdown} ref={this.setWrapperRef}>
        <ListItem className={classes.dropdownItem} items={this.state.listUsermenu}
          onClick={this._handleClickMenuUser.bind(this)} selected-item={-1} />
      </span>
    ) : null;
    const sideBar = (this.state.showSidebar) ? this.renderSidebar() : null;

    return (
      <React.Fragment>
        <main className={rootClass}>
          {sideBar}
          <section className={classes.content}>
            <header className={classes.header}>
              <span className={classes.headerItem}>
                <img onClick={this._handleSidebar} src={IMAGES.COLLAPSE} />
              </span>
              <span className={classes.headerItem} onClick={this._handleUserDropdown} >
                <Avatar src={IMAGES.DUMMY_AVATAR} variant="small" />
                <label className={classes.userLabel}>
                  {(getUserData()) ? getUserData().username : 'username'}
                </label>
                <img src={IMAGES.CHEVRON_DOWN} />
                {showUserDropdown}
              </span>
            </header>
            <section className={classes.headerCrumbs}>
              <img src={IMAGES.LIST} />
              {this.renderBreadcrumb()}
            </section>
            <content className={classes.contentChildren}>{children}</content>
          </section>
        </main>
        {modalLogout}
      </React.Fragment>
    );
  }
}

Pagebase.propTypes = {
  actions: PropTypes.object,
  children: PropTypes.object,
  classes: PropTypes.object,
  history: PropTypes.object,
  index: PropTypes.number,
};

Pagebase.defaultProps = {
  actions: {},
  children: {},
  classes: {},
  history: {},
  index: null,
};
