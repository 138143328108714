import Grey from '../Colors/Grey';
import { convertHex } from '../../../utils/helpers';
const styles = theme => ({
  circle24: {
    borderRadius: 40, fontSize: 20, height: 40, minWidth: 40,
  },
  circle32: {
    borderRadius: 56, fontSize: 20, height: 56, minWidth: 56,
  },
  circle48: {
    borderRadius: 56, fontSize: 20, height: 56, minWidth: 56,
  },
  
  colorCirclePrimary: {
    '&:active': { backgroundColor: theme.palette.base.primary.dark },
    '&:disabled': { backgroundColor: `${Grey[40]} !important` },
    '&:focus': { boxShadow: `0 0 0 3px ${convertHex(theme.palette.base.primary.main, 50)}`, },
    '&:hover': { backgroundColor: theme.palette.base.primary.light },
    backgroundColor: theme.palette.base.primary.main,
  },
  colorCircleSecondary: {
    '&:active': { backgroundColor: theme.palette.base.secondary.dark },
    '&:disabled': { backgroundColor: `${Grey[40]} !important` },
    '&:focus': { boxShadow: `0 0 0 3px ${convertHex(theme.palette.base.secondary.main, 50)}`, },
    '&:hover': { backgroundColor: theme.palette.base.secondary.light },
    backgroundColor: theme.palette.base.secondary.main,
  },
  colorCircleTertiary: {
    '&:active': { backgroundColor: theme.palette.base.tertiary.dark },
    '&:disabled': { backgroundColor: `${Grey[40]} !important` },
    '&:focus': { boxShadow: `0 0 0 3px ${convertHex(theme.palette.base.tertiary.main, 50)}`, },
    '&:hover': { backgroundColor: theme.palette.base.tertiary.light },
    backgroundColor: theme.palette.base.tertiary.main,
  },
  colorFilledOutline: {
    '&:active': { backgroundColor: `${theme.palette.base.primary.dark} !important`, boxShadow: 'none' },
    '&:disabled': { backgroundColor: `${Grey[40]} !important`, color: Grey[60], },
    '&:focus': { boxShadow: `0 0 0 3px ${convertHex(theme.palette.base.primary.main, 50)}`, },
    '&:hover': { backgroundColor: theme.palette.base.primary.light, color: 'white !important' },
    backgroundColor: 'white',
    border: `1px solid ${theme.palette.base.primary.main} !important`,
    color: `${theme.palette.base.primary.main} !important`,
  },
  colorFilledPrimary: {
    '&:active': { backgroundColor: `${theme.palette.base.primary.dark} !important`, boxShadow: 'none' },
    '&:disabled': { backgroundColor: `${Grey[40]} !important`, color: Grey[60], },
    '&:focus': { boxShadow: `0 0 0 3px ${convertHex(theme.palette.base.primary.main, 50)}`, },
    '&:hover': { backgroundColor: theme.palette.base.primary.light },
    backgroundColor: theme.palette.base.primary.main,
  },
  colorFilledSecondary: {
    '&:active': { backgroundColor: theme.palette.base.secondary.dark, boxShadow: 'none' },
    '&:disabled': { backgroundColor: `${Grey[40]} !important`, color: Grey[60], },
    '&:focus': { boxShadow: `0 0 0 3px ${convertHex(theme.palette.base.secondary.main, 50)}`, },
    '&:hover': { backgroundColor: theme.palette.base.secondary.light },
    backgroundColor: theme.palette.base.secondary.main,
  },
  colorFilledTertiary: {
    '&:active': { backgroundColor: theme.palette.base.tertiary.dark, boxShadow: 'none' },
    '&:disabled': { backgroundColor: `${Grey[40]} !important`, color: Grey[60], },
    '&:focus': { boxShadow: `0 0 0 3px ${convertHex(theme.palette.base.tertiary.main, 50)}`, },
    '&:hover': { backgroundColor: theme.palette.base.tertiary.light },
    backgroundColor: theme.palette.base.tertiary.main,
  },
  colorGhostPrimary: {
    '&:active': { border: `1px solid ${theme.palette.base.primary.dark}`, boxShadow: 'none' },
    '&:disabled': { backgroundColor: `${Grey[40]} !important`, borderColor: Grey[40], color: theme.palette.common.black, },
    '&:focus': { boxShadow: `0 0 0 3px ${convertHex(theme.palette.base.primary.main, 50)}`, },
    '&:hover': { borderColor: theme.palette.base.primary.main, },
    borderColor: Grey[30],
  },
  colorGhostSecondary: {
    '&:active': { border: `1px solid ${theme.palette.base.secondary.dark}`,boxShadow: 'none' },
    '&:disabled': { backgroundColor: `${Grey[40]} !important`, borderColor: Grey[40],  color: Grey[60], },
    '&:focus': { boxShadow: `0 0 0 3px ${convertHex(theme.palette.base.secondary.main, 50)}`, },
    '&:hover': { borderColor: theme.palette.base.secondary.dark, },
    borderColor: Grey[30],
  },
  colorGhostTertiary: {
    '&:active': { border: `1px solid ${theme.palette.base.tertiary.dark}`, boxShadow: 'none' },
    '&:disabled': { backgroundColor: `${Grey[40]} !important`, borderColor: Grey[40], color: Grey[60], },
    '&:focus': { boxShadow: `0 0 0 3px ${convertHex(theme.palette.base.tertiary.main, 50)}`, },
    '&:hover': { borderColor: theme.palette.base.tertiary.dark, },
    borderColor: Grey[30],
  },
  colorImagePrimary: {
    '&:active': { backgroundColor: Grey[40] },
    '&:disabled': { color: Grey[20] },
    '&:focus': { boxShadow: `0 0 0 3px ${convertHex(theme.palette.base.primary.main, 50)}`, },
    '&:hover': { backgroundColor: Grey[30] },
  },
  colorImageSecondary: {
    '&:active': { color: theme.palette.base.secondary.dark },
    '&:disabled': { color: Grey[30] },
    '&:focus': { boxShadow: `0 0 0 3px ${convertHex(theme.palette.base.secondary.main, 50)}`, },
    '&:hover': { textDecoration: 'underline' },
  },
  colorImageTertiary: {
    '&:active': { color: theme.palette.base.tertiary.dark },
    '&:disabled': { color: Grey[30] },
    '&:focus': { boxShadow: `0 0 0 3px ${convertHex(theme.palette.base.tertiary.main, 50)}`, },
    '&:hover': { textDecoration: 'underline' },
  },
  colorMinimalBetweentextPrimary: {
    '&:active': { color: theme.palette.base.primary.dark },
    '&:disabled': { color: Grey[30] },
    '&:focus': { boxShadow: `0 0 0 3px ${convertHex(theme.palette.base.primary.main, 50)}`, textDecoration: 'underline' },
    '&:hover': { textDecoration: 'underline' },
  },
  colorMinimalBetweentextSecondary: {
    '&:active': { color: theme.palette.base.secondary.dark },
    '&:disabled': { color: Grey[30] },
    '&:focus': { boxShadow: `0 0 0 3px ${convertHex(theme.palette.base.secondary.main, 50)}`, textDecoration: 'underline' },
    '&:hover': { textDecoration: 'underline' },
  },
  colorMinimalBetweentextTertiary: {
    '&:active': { color: theme.palette.base.tertiary.dark },
    '&:disabled': { color: Grey[30] },
    '&:focus': { boxShadow: `0 0 0 3px ${convertHex(theme.palette.base.tertiary.main, 50)}`, textDecoration: 'underline' },
    '&:hover': { textDecoration: 'underline' },
  },
  colorMinimalBlackPrimary: {
    '&:active': { color: theme.palette.base.primary.dark },
    '&:disabled': { color: Grey[30] },
    '&:focus': { boxShadow: `0 0 0 3px ${convertHex(theme.palette.base.primary.main, 50)}`, },
    '&:hover': { textDecoration: 'underline' },
  },
  colorMinimalBlackSecondary: {
    '&:active': { color: theme.palette.base.secondary.dark },
    '&:disabled': { color: Grey[30] },
    '&:focus': { boxShadow: `0 0 0 3px ${convertHex(theme.palette.base.secondary.main, 50)}`, },
    '&:hover': { textDecoration: 'underline' },
  },
  colorMinimalBlackTertiary: {
    '&:active': { color: theme.palette.base.tertiary.dark },
    '&:disabled': { color: Grey[30] },
    '&:focus': { boxShadow: `0 0 0 3px ${convertHex(theme.palette.base.tertiary.main, 50)}`, },
    '&:hover': { textDecoration: 'underline' },
  },
  colorMinimalColorPrimary: {
    '&:active': { color: theme.palette.base.primary.dark },
    '&:disabled': { color: Grey[30] },
    '&:focus': { boxShadow: `0 0 0 3px ${convertHex(theme.palette.base.primary.main, 50)}`, },
    '&:hover': { textDecoration: 'underline' },
    color: theme.palette.base.primary.main,
  },
  colorMinimalColorSecondary: {
    '&:active': { color: theme.palette.base.secondary.dark },
    '&:disabled': { color: Grey[30] },
    '&:focus': { boxShadow: `0 0 0 3px ${convertHex(theme.palette.base.secondary.main, 50)}`, },
    '&:hover': { textDecoration: 'underline' },
    color: theme.palette.base.secondary.main,
  },
  colorMinimalColorTertiary: {
    '&:active': { color: theme.palette.base.tertiary.dark },
    '&:disabled': { color: Grey[30] },
    '&:focus': { boxShadow: `0 0 0 3px ${convertHex(theme.palette.base.tertiary.main, 50)}`, },
    '&:hover': { textDecoration: 'underline' },
    color: theme.palette.base.tertiary.main,
  },
  filled24: {
    height: 24
  },
  filled32: {
    height: 32, paddingLeft: 20, paddingRight: 20
  },
  filled48: {
    height: 48, paddingLeft: 20, paddingRight: 20
  },
  root: {
    '&:active': {
      transition: '0s background-color'
    },
    '&:hover': {
      transition: '0.5s background-color'
    },
    alignItems: 'center', border: 'none', borderRadius: 24, color: theme.palette.common.white, cursor: 'pointer', display: 'inline-flex',
    fontFamily: theme.typography.font_family, fontSize: '14px', fontWeight: 700, justifyContent: 'center', letterSpacing: '1px', margin: 2, minWidth: 50, outline: 'none', overflow: 'hidden',
    paddingLeft: 12, paddingRight: 12, verticalAlign: 'middle',
  },
  variantCircle: {
    border: 'none', paddingLeft: 0, paddingRight: 0,
  },
  variantGhost: {
    backgroundColor: 'inherit', border: ' 1px solid #9E9E9E', color: theme.palette.common.black
  },
  variantImage: {
    backgroundColor: 'inherit', border: 'none', borderRadius: 2, color: theme.palette.common.black, fontSize: 20, minHeight: 34,
    minWidth: 34, paddingLeft: 0, paddingRight: 0,
  },
  variantImageBorder: {
    backgroundColor: 'inherit', border: `1px solid ${Grey[20]}`, borderRadius: 2, color: theme.palette.common.black, fontSize: 20,
    minHeight: 34, minWidth: 34, paddingLeft: 0, paddingRight: 0,
  },
  variantImageFilled: {
    backgroundColor: Grey[20], border: 'none', borderRadius: 2, color: theme.palette.common.black, fontSize: 20,
    minHeight: 34, minWidth: 34, paddingLeft: 0, paddingRight: 0,
  },
  variantMinimalColor: {
    backgroundColor: 'inherit', border: 'none', borderRadius: 0, color: theme.palette.common.black, minWidth: 10, paddingLeft: 2, paddingRight: 2,
  },
});
export default styles;
