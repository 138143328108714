const images = {
  ADD: '/assets/add.svg',
  AVATAR_BIG: '/assets/avatarbig.svg',
  CHECK: '/assets/check.svg',
  CHEVRON_DOWN: '/assets/chevron_down.svg',
  CHEVRON_UP: '/assets/chevron_up.svg',
  COLLAPSE: '/assets/collapse.svg',
  COLLAPSE_RED: '/assets/collapse_red.svg',
  CROSS: '/assets/cross.svg',
  CUSTOMER: '/assets/customer.png',
  DASHBOARD: '/assets/dashboard.png',
  DELETE: '/assets/delete.svg',
  DEVICE: '/assets/device.svg',
  DOWNLOAD: '/assets/download.svg',
  DOWNLOAD_MODAL: '/assets/download_modal.svg',
  DUMMY_AVATAR: '/assets/dummy_avatar.svg',
  EDIT: '/assets/edit.svg',
  ERROR_EVENT: '/assets/error_event.svg',
  EYE: '/assets/eye.svg',
  EYE_OPEN: '/assets/eyeopen.svg',
  HOME: '/assets/home.jpg',
  KEY: '/assets/key.svg',
  LIST: '/assets/list.svg',
  LOG: '/assets/log.svg',
  LOG_DEVICE: '/assets/logdevice.svg',
  LOG_EMAIL: '/assets/logemail.svg',
  LOGINCOVER: '/assets/logincover.jpeg',
  LOGO: '/assets/logo.svg',
  LOGO_EAZY: '/assets/logo_eazy.png',
  LOGO_SOOLTANCAM: '/assets/logo_sooltancam.png',
  LOGOWHITE: '/assets/logowhite.svg',
  NEXT_PAGE: '/assets/next_page.svg',
  NOT_FOUND: '/assets/notfound.svg',
  PEOPLE: '/assets/people.svg',
  POINT: '/assets/point.svg',
  PREV_PAGE: '/assets/prev_page.svg',
  REFRESH: '/assets/refresh.svg',
  SEARCH: '/assets/search.svg',
  STATUS_SUCCESS: '/assets/status_check_success.svg',
  STATUS_ERROR: '/assets/status_check_error.svg',
  TIMES: '/assets/times-mixed.svg',
  TRASH: '/assets/trash.svg',
  TRASH_CHECK: '/assets/trash-check.svg'
};

export default images;