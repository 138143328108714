import React from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import Typography from '../Typography';

class Tooltip extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      offsetHeight: 0,
      offsetLeft: 0,
      offsetTop: 0,
      offsetWidth: 0,
    };
  }

  UNSAFE_componentWillReceiveProps() {
    this._handleOpenTooltip(null);
  }

  _handleOpenTooltip(e) {
    const { left, top } = this.props;

    if (e !== null) {
      this.setState({
        offsetHeight: e.currentTarget.offsetHeight,
        offsetLeft: e.currentTarget.offsetLeft,
        offsetTop: e.currentTarget.offsetTop,
        offsetWidth: e.currentTarget.offsetWidth,
        open: true,
      });
    } else {
      this.setState({
        offsetHeight: 0,
        offsetLeft: left,
        offsetTop: top,
        offsetWidth: 0
      });
    }
  }

  _handleCloseTooltip() {
    this.setState({
      open: false,
    });
  }

  _renderTooltip() {
    let {
      classes,
      className,
      position,
      setCenter,
      label
    } = this.props;
    const { offsetHeight, offsetLeft, offsetTop, offsetWidth } = this.state;

    const tooltipPos = position.toLowerCase().split(' ')[0];
    const arrowPos = position.toLowerCase().split(' ')[1];
    const clearText = label.trim();
    const whitespace = clearText.length - clearText.replace(/\s/g, '').length;
    const textContent = clearText.replace(/\s/g, '').length > 24 ?
      `${clearText.substring(0, 24 + whitespace)}...` : clearText;
    const tooltipHeight = 24;

    const tooltipClass = classNames(
      classes.root,
      {
        [classes.topCenter]: tooltipPos === 'top' && arrowPos === 'center',
        [classes.topLeft]: tooltipPos === 'top' && arrowPos === 'left',
        [classes.topRight]: tooltipPos === 'top' && arrowPos === 'right',
        [classes.bottomCenter]: tooltipPos === 'bottom' && arrowPos === 'center',
        [classes.bottomLeft]: tooltipPos === 'bottom' && arrowPos === 'left',
        [classes.bottomRight]: tooltipPos === 'bottom' && arrowPos === 'right',
        [classes.leftCenter]: tooltipPos === 'left' && arrowPos === 'center',
        [classes.leftTop]: tooltipPos === 'left' && arrowPos === 'top',
        [classes.leftBottom]: tooltipPos === 'left' && arrowPos === 'bottom',
        [classes.rightCenter]: tooltipPos === 'right' && arrowPos === 'center',
        [classes.rightTop]: tooltipPos === 'right' && arrowPos === 'top',
        [classes.rightBottom]: tooltipPos === 'right' && arrowPos === 'bottom',
      },
      className,
    );

    const centerTooltip = !setCenter ? {} :
      arrowPos === 'left' ? { left: offsetLeft + (offsetWidth / 2) - 10 } :
        arrowPos === 'right' ? { right: `calc(100% - ${offsetLeft + (offsetWidth / 2)}px - 10px)` } :
          arrowPos === 'top' ? { top: offsetTop + (offsetHeight / 2) - 8 } :
            { top: offsetTop + (offsetHeight / 2) - 16 };

    let setTooltipPos;

    switch (position) {
      case 'top center': setTooltipPos = {
        left: offsetLeft + (offsetWidth / 2),
        top: offsetTop - tooltipHeight - 6,
        transform: 'translateX(-50%)'
      }; break;
      case 'top left': setTooltipPos = {
        left: offsetLeft,
        top: offsetTop - tooltipHeight - 6,
      }; break;
      case 'top right': setTooltipPos = {
        right: `calc(100% - ${offsetLeft + offsetWidth}px)`,
        top: offsetTop - tooltipHeight - 6,
      }; break;
      case 'bottom center': setTooltipPos = {
        top: offsetTop + offsetHeight + 6,
        left: offsetLeft + (offsetWidth / 2),
        transform: 'translateX(-50%)',
      }; break;
      case 'bottom left': setTooltipPos = {
        top: offsetTop + offsetHeight + 6,
        left: offsetLeft,
      }; break;
      case 'bottom right': setTooltipPos = {
        top: offsetTop + offsetHeight + 6,
        right: `calc(100% - ${offsetLeft + offsetWidth}px)`,
      }; break;
      case 'left center': setTooltipPos = {
        right: `calc(100% - ${offsetLeft - 7}px)`,
        top: offsetTop + (offsetHeight / 2),
        transform: 'translateY(-50%)'
      }; break;
      case 'left top': setTooltipPos = {
        right: `calc(100% - ${offsetLeft - 7}px)`,
        top: offsetTop,
      }; break;
      case 'left bottom': setTooltipPos = {
        top: `calc(${offsetTop + offsetHeight}px - ${tooltipHeight}px)`,
        right: `calc(100% - ${offsetLeft - 7}px)`,
      }; break;
      case 'right center': setTooltipPos = {
        left: offsetLeft + offsetWidth + 7,
        top: offsetTop + (offsetHeight / 2),
        transform: 'translateY(-50%)'
      }; break;
      case 'right top': setTooltipPos = {
        left: offsetLeft + offsetWidth + 7,
        top: offsetTop,
      }; break;
      case 'right bottom': setTooltipPos = {
        top: `calc(${offsetTop + offsetHeight}px - ${tooltipHeight}px)`,
        left: offsetLeft + offsetWidth + 7,
      }; break;
    }

    return (
      <span className={tooltipClass} style={{ ...setTooltipPos, ...centerTooltip }}>
        <span className={classes.tooltip}>
          <Typography variant="overline1"> {textContent} </Typography>
        </span>
      </span>
    );
  }

  render() {
    const { children, show } = this.props;
    const pointedElProps = {
      onMouseEnter: e => this._handleOpenTooltip(e),
      onMouseLeave: e => this._handleCloseTooltip(e)
    };

    const pointedElement = !children || show !== null ? undefined :
      React.Children.map(children, el =>
        React.cloneElement(el, pointedElProps)
      );

    return (
      <React.Fragment>
        {pointedElement}
        {(this.state.open || show) && this._renderTooltip()}
      </React.Fragment>
    );
  }
}

Tooltip.propTypes = {
  children: PropTypes.node,
  /**
   * Override or extend the styles applied to the component.
   See [CSS API](#css) below for more details.
   */
  classes: PropTypes.object.isRequired,
  className: PropTypes.string,
  label: PropTypes.string.isRequired,
  left: PropTypes.number,
  position: PropTypes.string,
  setCenter: PropTypes.bool,
  show: PropTypes.bool,
  top: PropTypes.number
};

Tooltip.defaultProps = {
  children: null,
  className: ' ',
  left: 0,
  position: 'top center',
  setCenter: false,
  show: null,
  top: 0,
};

export default Tooltip;
