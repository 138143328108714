import axios from 'axios';
import { getSooltanCam, getUserData } from './common';

export default function fetch(options) {
  return new Promise((resolve, reject) => {
    axios(options)
      .then(res => {
        resolve(res.data);
      })
      .catch(err => {
        const defaultError = {
          code: 500,
          status: 'error',
          message: 'Failed to fetch data. Please contact developer.'
        };

        if (typeof err.response === 'undefined') reject(defaultError);
        else if (typeof err.response.data === 'undefined') reject(defaultError);
        else reject(err.response.data);
      });
  });
}

export function getBasicSooltancam() {
  if (getSooltanCam() !== 'true') {
    return false;
  }

  switch(getUserData()?.level) {
    case 0:
      return {
        username: 'sooltancam_super_admin',
        password: 'ac56d15e8f9ac8486f99b3a91aa547c4'
      };
    case 1:
      return {
        username: 'sooltancam_admin',
        password: '1730e8364acf41295098f2e2b861837a'
      };
    case 2:
      return {
        username: 'sooltancam_cs',
        password: '3f4c069eeabf5d0b12cd1fb525c0e4e5'
      };
  }
}
