import React from 'react';
import PropTypes from 'prop-types';
import { Router } from 'react-router';
import { Provider } from 'react-redux';
import App from './App';
import { getUserData } from './utils/common';
import { ROUTES } from './configs';

export default class Root extends React.Component {
  componentDidMount() {
    const { history } = this.props;
    const data = getUserData();
    if (data?.level === 4 && ![ROUTES.DEVICE_LIST(), ROUTES.SETTINGS()].includes(this.props.history.location.pathname)) {
      window.location = ROUTES.DEVICE_LIST();
      console.log(history)
    }
  }

  render() {
    const { store, history } = this.props;
    return (
      <Provider store={store}>
        <Router history={history}>
          <App />
        </Router>
      </Provider>
    );
  }
}

Root.propTypes = {
  store: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired
};