const styles = () => ({
  root: {
    objectFit: 'cover',
  },
  small: {
    borderRadius: '32px',
    height: '32px',
    width: '32px',
  },
});

export default styles;
