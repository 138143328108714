import axios from 'axios';
import { ACTIONS } from '../../constants';

export function fetchAllDevices(keyword = '', queryFilter) {
  return dispatch => {
    dispatch(loading());
    axios.get('https://script.googleusercontent.com/macros/echo?user_content_key=shszeNYVaJ5mWU_A_sRXtSw3d5WvVNAnim9dk_oMu5N6FYig2iLWPBEaF0S0veK43cJfbv2oSkF3G0CzZXFpiOEAm2LFsxdHm5_BxDlH2jW0nuo2oDemN9CCS2h10ox_1xSncGQajx_ryfhECjZEnP7UPNOotdv6il854TMopM7S6eeLbXHLvkHBoarV6nczbMax8kDCkcj7VJ65pdX3emL0iJ-5UyhtBZfQKyH9-LKqB2vWum6yz9z9Jw9Md8uu&lib=MBFKilnFUy0iot7c5kxX2rEwB3HTZ5ktp').then(resp => {
      let item = resp.data.data;

      if (keyword) {
        item = resp.data.data.filter(datas => datas.MESSAGE.includes(keyword)
          || datas['SOLUTION'].toLowerCase().includes(keyword.toLowerCase()));
      }

      if (queryFilter.length > 0) {
        item = item.filter(datas => queryFilter.filter(query => datas['EVENT NAME'].includes(query)).length > 0);
      }

      dispatch(setDevices(item));
      dispatch(doneLoading());
    });
  };
}

function doneLoading() {
  return {
    type: ACTIONS.DONE_LOADING,
  };
}

function loading() {
  return {
    type: ACTIONS.LOADING,
  };
}

function setDevices(logs) {
  return {
    type: ACTIONS.FETCH_LIST_LOG,
    logs
  };
}
