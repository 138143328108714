import React from 'react';
import PropTypes from 'prop-types';

export default class ChartLine extends React.Component {
  constructor(props) {
    super(props);

    this._handleCloseTooltip = this._handleCloseTooltip.bind(this);
  }

  _handleOpenTooltip(left, top, tooltipText, event) {
    this.props.openTooltip(left, top, tooltipText, event);
  }

  _handleCloseTooltip() {
    this.props.closeTooltip();
  }

  _setYLabel(grid) {
    const { maxValue, yTotalLabel } = this.props;
    const currentValue = maxValue - (maxValue*grid/(yTotalLabel+1));
    let modifiedValue = currentValue >= 1000 ? currentValue/1000 : currentValue;
    
    if (!Number.isInteger(modifiedValue)) {
      modifiedValue = modifiedValue.toFixed(1);
    }
    
    return currentValue >= 1000 ? `${modifiedValue}K` : modifiedValue;
  }

  _renderLineGraph(line, key) {
    const { maxValue, xLabel, classes, chartHeight } = this.props;
    const valuePerLabel = [];
    
    const coordinates = xLabel.map((label, idx) => {
      const point = line.point.find(value => value.label === label);
      const pointValue = point !== undefined ? point.value : 0;
      valuePerLabel.push(pointValue);
      
      const xPoint = (idx === 0) ? 0 
        : (idx === xLabel.length - 1) ? 436
          : ((idx + 1)) + ( idx * (436 / xLabel.length)) + ((436 / xLabel.length) / 2);
      const yPoint = (chartHeight)-((chartHeight)*(pointValue/maxValue));
      const coordinate = `${xPoint},${yPoint}`;
      
      return (idx+1) === xLabel.length ? coordinate : `${coordinate} `;
    }).join('');

    return (
      <React.Fragment key={key}>
        <polyline
          fill="none"
          points={coordinates}
          stroke={line.color}
          strokeWidth="3"
        />
        {
          xLabel.map((_, idx) => {
            const xPos = Number(coordinates.split(' ')[idx].split(',')[0]);
            const yPos = Number(coordinates.split(' ')[idx].split(',')[1]);
            const tooltipText = `${line.name}: ${valuePerLabel[idx]}`;

            return (
              <circle
                className={classes.point}
                cx={xPos}
                cy={yPos}
                fill={line.color}
                key={idx}
                onMouseEnter={this._handleOpenTooltip.bind(this, xPos, yPos-3, tooltipText)}
                onMouseLeave={this._handleCloseTooltip}
                r="6"
              />
            );
          })
        }
      </React.Fragment>
    );
  }

  _renderLabel() {
    const { maxValue } = this.props;

    let yLabels = [];

    yLabels.push(<text
      x="0"
      y="15">
      { (Number.isInteger(maxValue)) ? maxValue : '' }
    </text>);

    return [...yLabels];
  }

  _renderGrid() {
    const { yTotalLabel, chartWidth, chartHeight } = this.props;
    const xGrid = [], yGrid = [];

    for (let point = 0; point < yTotalLabel + 2; point++) {
      yGrid.push(
        <line
          key={xGrid.length + point}  
          x1="0"
          x2={`${chartWidth}`}
          y1={(chartHeight) * point / (yTotalLabel + 1)}
          y2={(chartHeight) * point / (yTotalLabel + 1)}
        />
      );
    }

    return xGrid.concat(yGrid);
  }
  
  render() {
    let {
      classes,
      data,
      chartHeight,
      chartWidth,
      xLabel,
    } = this.props;

    return (
      <section>
        <svg 
          className={classes.wrapper}
          // height="185"
          role="img"
          viewBox={`0 0 ${chartWidth} ${chartHeight}`} 
          // width="436"
          xmlns="http://www.w3.org/2000/svg">
          <g className={classes.grid} transform="scale(0.95 0.95)
            translate(10 0)">{ this._renderGrid() }</g>
          <g className={classes.label} transform="scale(0.95 0.95)
            translate(10 0)"> { this._renderLabel() } </g>
          <g transform="scale(0.95 0.95)
            translate(10 0)"> { data.map((line, key) => this._renderLineGraph(line, key)) } </g>
        </svg>
        <section className={classes.label}>
          {
            xLabel.map((label, idx) => {
              return <label key={idx}>{label}</label>;
            })
          }
        </section>
      </section>
    );
  }
}

ChartLine.propTypes = {
  chartHeight: PropTypes.number,
  chartWidth: PropTypes.number,
  /**
   * Override or extend the styles applied to the component.
   * See [CSS API](#css) below for more details.
   */
  classes: PropTypes.object.isRequired,
  closeTooltip: PropTypes.func,
  data: PropTypes.array.isRequired,
  maxValue: PropTypes.number,
  openTooltip: PropTypes.func,
  xLabel: PropTypes.array.isRequired,
  yTotalLabel: PropTypes.number
};

ChartLine.defaultProps = {
  chartHeight: 280,
  chartWidth: 290,
  closeTooltip: () => {},
  maxValue: 0,
  openTooltip: () => {},
  yTotalLabel: 0,
};
