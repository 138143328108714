import React from 'react';
import PropTypes from 'prop-types';
import CryptoJS from 'crypto-js';
import { IMAGES, ROUTES } from '../../configs';
import { getUserData } from '../../utils/common';
import Base from '../../components/layouts/Pagebase';
import Button from '../../components/elements/Button';
import DataTable from '../../components/elements/DataTable';
import Dropdown from '../../components/elements/Dropdown';
import Textfield from '../../components/elements/Textfield';
export default class Component extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      password: null,
      passwordType: true,
      username: null,
      page: 1,
      selectedItem: 0,
      showModal: false,
    };

    this._handleChangeDropdown = this._handleChangeDropdown.bind(this);
    this._handleShowpassword = this._handleShowpassword.bind(this);
  }

  componentDidMount() {
    const { actions } = this.props;

    actions.fetchAllUsers();
  }

  _handleChangeDropdown(selectedItem) {
    this.setState({ selectedItem });
  }
  _handlePassword(password) {
    this.setState({ errorPassword: false, password });
  }

  _handleShowModal(showModal) {
    this.setState({ showModal, username: null, selectedItem: 0 });
  }

  _handleCloseModal() {
    const { actions } = this.props;

    this.setState({ showModal: false, password: null });
    actions.closeModal();
  }

  _handleDelete(username) {
    const { actions, users, modalDelete } = this.props;

    let usersNew = [...users];
    usersNew.splice(modalDelete.index, 1);

    actions.deleteAdmin(username, usersNew);
  }

  _handleUsername(username) {
    this.setState({ errorUsername: false, username });
  }

  _handleShowpassword() {
    this.setState({ passwordType: !this.state.passwordType });
  }

  _handleSubmit() {
    const { actions, users } = this.props;
    const payload = {
      password: CryptoJS.MD5(this.state.password).toString(),
      username: this.state.username,
      level: ((this.state.selectedItem + 1) + (this.state.selectedItem === 2 ? 1 : 0)).toString(),
    };
    
    actions.createUser(payload, users);

    this.setState({ showModal: false });
  }

  _handleAction(item, index, actionIndex) {
    const { actions } = this.props;

    actions.selectAdmin(item);

    if (actionIndex === 0) {
      this.setState({
        showModal: true,
        username: item.username,
        selectedItem: item.level
      });

      this.props.history.push(ROUTES.SETTINGS());
    }

    if (actionIndex === 1) {
      actions.showDeleteModal(item, index);
    }
  }

  render() {
    const { classes, users, isLoading } = this.props;
    let head, modal, show;
    let userFiltered = users.map((item) => {
      return {
        ...item,
        action: [
          {
            text: 'Edit User',
          },
          {
            text: 'Hapus'
          }
        ]
      };
    });


    if (getUserData().level === 0) {
      head = ['Username', 'Role', ''];
      show = ['username', 'level', 'action'];
    } else {
      head = ['Username', 'Role'];
      show = ['username', 'level'];
    }

    const filterEntries = [10, 20, 30, 40, 50];
    const filterItem = ['Supervisor', 'CS','Agent 147'];
    const thead = head;
    const table = (!isLoading) ? (
      <DataTable data-body={userFiltered} data-head={thead}
        filter-entries={filterEntries}
        onAction={this._handleAction.bind(this)}
        page={this.state.page}
        show={show} />
    ) : null;
    const imagePassword = (this.state.passwordType)
      ? (<img id="show-password" onClick={this._handleShowpassword} src={IMAGES.EYE} />)
      : (<img id="show-password" onClick={this._handleShowpassword} src={IMAGES.EYE_OPEN} />);
    const password = (this.state.passwordType) ? 'password' : 'text';
    const propsUsername = {
      className: classes.textfield,
      input: {
        name: 'name',
        onChange: this._handleUsername.bind(this),
        value: this.state.username
      },
      meta: {
        'icon-align': 'left',
        error: this.state.errorUsername,
        placeholder: 'Name',
        type: 'text',
        variant: 'basic-filled',
      },
    };
    const propsPassword = {
      className: classes.textfield,
      input: {
        name: 'password',
        onChange: this._handlePassword.bind(this),
        value: this.state.password,
      },
      meta: {
        'icon-align': 'left',
        error: this.state.errorPassword,
        iconRight: imagePassword,
        placeholder: (!this.state.errorPassword) ? 'Password'
          : 'Silahkan masukan password Anda',
        type: password,
        variant: 'basic-filled',
      },
    };
    modal = (this.state.showModal) ? (
      <section className={classes.modalWrapper}>
        <span className={classes.modalOverlay} />
        <div className={classes.modal}>
          <h1 className={classes.modalTitle}>Create User</h1>
          <section className={classes.modalField}>
            <label>Name</label>
            <Textfield {...propsUsername} />
          </section>
          <section className={classes.modalField}>
            <label>Password</label>
            <Textfield {...propsPassword} />
          </section>
          <section className={classes.modalField}>
            <label>Role</label>
            <Dropdown className={classes.dropdownCreate} items={filterItem}
              onChange={this._handleChangeDropdown} selected-item={this.state.selectedItem}
              variant="white" />
          </section>
          <section className={[classes.modalField, classes.modalBottom].join(' ')}>
            <Button className={[classes.buttonModal, classes.buttonWhite].join(' ')} color="primary"
              onClick={this._handleSubmit.bind(this)} size="32">
              <label className={classes.buttonLabel}>
                Done
              </label>
            </Button>
          </section>
          <span className={classes.modalClose} onClick={this._handleShowModal.bind(this, false)}>
            X
          </span>
        </div>
      </section>
    ) : null;
    const blurred = (this.state.showModal || this.props.modal || this.props.modalDelete) ? classes.blur : '';
    const button = (getUserData().level < 2) ? (
      <Button className={classes.button} onClick={this._handleShowModal.bind(this, true)}>
        <img src={IMAGES.ADD} /> Tambah Admin
      </Button>
    ) : '';

    if (this.props.modal) {
      let image = (!this.props.modal.success)
        ? <img src={IMAGES.STATUS_ERROR} />
        : <img src={IMAGES.STATUS_SUCCESS} />;
      modal = (<section className={classes.modalWrapper}>
        <span className={classes.modalOverlay} />
        <div className={[classes.modal, classes.modalSmall].join(' ')}>
          {image}
          <span className={classes.modalDivider} />
          <div className={classes.modalDescription}>
            {this.props.modal.message}
          </div>
          <section className={classes.modalButton}>
            <Button className={[classes.buttonModal, classes.buttonWhite].join(' ')} color="primary"
              onClick={this._handleCloseModal.bind(this, true)} size="32">
              <label className={classes.buttonLabel}>
                OK
              </label>
            </Button>
          </section>
        </div>
      </section>);
    }

    if (this.props.modalDelete) {
      modal = (<section className={classes.modalWrapper}>
        <span className={classes.modalOverlay} />
        <div className={[classes.modal, classes.modalTiny].join(' ')}>
          <div className={classes.modalDescriptionTiny}>
            {this.props.modalDelete.text}
          </div>
          <section className={classes.modalButtonTiny}>
            <Button className={[classes.buttonWhite, classes.buttonModalTiny].join(' ')} color="primary"
              onClick={this._handleCloseModal.bind(this, true)} size="32">
              <label className={classes.buttonLabel}>
                Batal
              </label>
            </Button>
            <Button className={[classes.buttonModalTiny].join(' ')} color="primary"
              onClick={this._handleDelete.bind(this, this.props.modalDelete.username)} size="32">
              <label className={classes.buttonLabel}>
                Ya
              </label>
            </Button>
          </section>
        </div>
      </section>);
    }

    return (
      <React.Fragment>
        <main className={blurred}>
          <Base {...this.props}>
            <div className={classes.filterSection}>
              <label className={classes.title}>
                Admin User
              </label>
              {button}
            </div>
            <section className={classes.table}>
              {table}
            </section>
          </Base>
        </main>
        {modal}
      </React.Fragment>
    );
  }
}

Component.propTypes = {
  actions: PropTypes.object,
  classes: PropTypes.object,
  history: PropTypes.object,
  isLoading: PropTypes.bool,
  modal: PropTypes.object,
  modalDelete: PropTypes.object,
  users: PropTypes.array,
};

Component.defaultProps = {
  actions: {},
  classes: {},
  history: {},
  isLoading: false,
  modal: {},
  modalDelete: {},
  users: [],
};
