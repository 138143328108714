import IMAGES from './images';
import ROUTES from './routes';
const menu = [
  {
    image: IMAGES.DASHBOARD,
    menuTitle: 'dashboard',
    route: ROUTES.DASHBOARD(),
  },
  {
   image: IMAGES.CUSTOMER,
   menuTitle: 'pelanggan',
   subMenu: [
     {
       image: IMAGES.LIST,
       route: ROUTES.CUSTOMER_LIST(), 
       subMenuTitle: 'list_pelanggan',
     },
     {
      image: IMAGES.DEVICE,
      route: ROUTES.DEVICE_LIST(), 
      subMenuTitle: 'list_perangkat',
    },
   ]
  },
  {
   image: IMAGES.LOG,
   menuTitle: 'log',
   subMenu: [
     {
       image: IMAGES.LOG_EMAIL,
       route: ROUTES.LOG_EMAIL(), 
       subMenuTitle: 'email',
     },
     {
      image: IMAGES.LOG_DEVICE,
      route: ROUTES.LOG_DEVICE(), 
      subMenuTitle: 'list_perangkat',
    },
   ]
  },
  {
   image: IMAGES.ERROR_EVENT,
   menuTitle: 'error_event',
   route: ROUTES.ERROR_EVENT(),
  }
]
const menuSooltancam = [
  {
    image: IMAGES.DASHBOARD,
    menuTitle: 'dashboard',
    route: ROUTES.DASHBOARD(),
  },
  {
   image: IMAGES.CUSTOMER,
   menuTitle: 'pelanggan',
   subMenu: [
     {
       image: IMAGES.LIST,
       route: ROUTES.CUSTOMER_LIST(), 
       subMenuTitle: 'list_pelanggan',
     },
     {
       image: IMAGES.LIST,
       route: ROUTES.CUSTOMER_LIST_SHARING(), 
       subMenuTitle: 'list_sharing',
     },
     {
      image: IMAGES.DEVICE,
      route: ROUTES.DEVICE_LIST(), 
      subMenuTitle: 'list_perangkat',
    },
   ]
  },
]
const menu147 = [
  {
    image: IMAGES.CUSTOMER,
    menuTitle: 'pelanggan',
    subMenu: [
      {
      image: IMAGES.DEVICE,
      route: ROUTES.DEVICE_LIST(), 
      subMenuTitle: 'list_perangkat',
    },
    ]
  }
];

export {
  menu147,
  menuSooltancam
};

export default menu;