import React from 'react';
import PropTypes from 'prop-types';
import { IMAGES } from '../../configs';
import Base from '../../components/layouts/Pagebase';
import Button from '../../components/elements/Button';
import DataTable from '../../components/elements/DataTableNew';
import Dropdown from '../../components/elements/Dropdown';
import Textfield from '../../components/elements/Textfield';
import Form from '../../components/forms/EditCustomer';
import { getSooltanCam } from '../../utils/common';

export default class Component extends React.Component {
  constructor(props) {
    super(props);

    let filterObject;
    
    if (getSooltanCam() === 'true') {
      filterObject = ['username', 'mobileNumber', 'email'];
    } else {
      filterObject = ['fullname', 'msisdn', 'ndmain', 'email'];
    }

    this.state = {
      download: false, edit: false, filterObject, page: 1,
      queryText: null, query: null, selectedItem: 0, showModal: false, reset: false,
    };

    this._handleChangeDropdown = this._handleChangeDropdown.bind(this);
    this._handleClearfilter = this._handleClearfilter.bind(this);
    this._handleFilter = this._handleFilter.bind(this);
    this._handleQuery = this._handleQuery.bind(this);
  }

  componentDidMount() {
    if (getSooltanCam() !== 'true') {
      window.location = '/';
    }
    const { actions } = this.props;
    actions.fetchUser(1);
  }

  componentDidUpdate(prevProps) {
    if (prevProps.download !== this.props.download && this.props.download.length > 0) {
      this._download(this.props.download);
    }
  }

  _handleAction(item, index, indexAction) {
    const { actions, users } = this.props;
    const { ndmain } = item;
    
    if (indexAction === 0) {
      this.setState({ showModal: true }, () => {
        const idx = users.map((user) => { return user.ndmain; }).indexOf(ndmain);

        actions.updateStatus(ndmain, idx, users);
      });
    }

    if (indexAction === 1) {
      this.setState({
        showModal: true,
        edit: true
      }, () => { actions.setUser(item); });
    }
  }

  _handleChangeDropdown(selectedItem) {
    this.setState({ selectedItem });
  }

  _handleClearfilter() {
    const { actions } = this.props;
    
    actions.resetList();
    actions.fetchUser(1);
    this.setState({ query: '', queryText: '', reset: true },
      () => { this.setState({ reset: false }); }); 
  }

  _handleNav(page) {
    const { actions, users } = this.props;
    const { filterObject, selectedItem } = this.state;
    const query = (this.state.queryText) ? `&${filterObject[selectedItem]}=${this.state.queryText}` : '';
    this.setState({
      page,
    }, () => { 
      if (!users[page - 1]) {
        actions.fetchUser(page,query); 
      }
    });    
  }

  _handleApplyDate(_, picker) {
    const { actions } = this.props;
    const { filterObject, selectedItem } = this.state;
    let date;

    if (_ === false) {
      date = '';
    } else {
      date = `${picker.startDate.format('MM/DD/YYYY')} - ${picker.endDate.format('MM/DD/YYYY')}`;
    }

    this.setState({ date }, () => {
      const query = (this.state.queryText) ? `&${filterObject[selectedItem]}=${this.state.queryText}` : '';
      actions.resetList();
      actions.fetchUser(1,query,10, date);
    });
  }

  _handleDownload() {
    const { actions, isLoading, totalData } = this.props;
    
    if (isLoading) { return; }

    const { date, filterObject, selectedItem } = this.state;
    const query = (this.state.queryText) ? `&${filterObject[selectedItem]}=${this.state.queryText}` : '';
    actions.fetchAllUser(1,query,totalData,() => { this.setState({ showModal: 'download', download: true }); },date);
  }

  _handleQuery(queryText) {
    const { actions } = this.props;

    this.setState({ queryText }, () => {
      if (queryText === '') {
        actions.fetchUser(1);
      }
    });
  }

  _handleFilter() {
    const { actions } = this.props;
    const { filterObject, selectedItem } = this.state;
    const query = this.state.queryText ? `&${filterObject[selectedItem]}=${this.state.queryText}`
      : '';

    this.setState({ page: 1, query: query, reset: true },
      () => {
        actions.resetList();
        actions.fetchUser(1,this.state.query);
        this.setState({ reset: false }); });
  }

  _handleUpdate(value) {
    const { actions, user } = this.props;
    const { filterObject, selectedItem } = this.state;
    const query = (this.state.queryText) ? `&${filterObject[selectedItem]}=${this.state.queryText}` : '';
    
    actions.updateUser(value, user, this.state.page, query);
  }

  render() {
    const { actions, classes, isLoading, message, user, users, totalData } = this.props;
    const { date } = this.state;
    let filterItem, thead, show;

    if (getSooltanCam() === 'true') {
      filterItem = ['Nama Pengguna', 'Nomor HP', 'Email'];
    } else {
      filterItem = ['Nama Pengguna', 'Nomor HP', 'Nomor Indihome', 'Email'];
    }

    const propsSearch = {
      className: classes.textfield,
      input: {
        name: 'username',
        onChange: this._handleQuery,
        onSubmit: this._handleFilter,
        value: this.state.queryText,
      },
      meta: {
        'icon-align': 'left',
        icon: <img src={IMAGES.SEARCH} />,
        iconRight: <img onClick={this._handleClearfilter} src={IMAGES.TIMES}/>,
        placeholder: `Masukkan ${filterItem[this.state.selectedItem]}`,
        type: 'text',
        value: this.state.queryText,
        variant: 'basic-filled',
      },
    };
    let modal;
    const filterEntries = [10, 20, 30, 40, 50];

    if (getSooltanCam() === 'true') {
      thead = ['Username', 'No. HP Parent', 'Email Parent', 'MAU', 'Relation',
        'Terakhir Aktif', 'Updated Terakhir'];
      show = ['username', 'mobileNumber', 'email', 'mau', 'relationName', 'lastActiveAt', 
        'updatedAt'];
    } else {
      thead = ['Nama Pengguna', 'No. HP',  'Nomor Indihome', 'Email', 'Paket', 'Versi App', 'MAU', 'Verified',
        'Terakhir Aktif', 'Updated Terakhir', ''];
      show = ['name', 'msisdn', 'ndmain', 'email', 'paket', 'version', 'mau', 'ndmain_status', 'lastActive', 
        'updatedAt', 'action'];      
    }

    const table = users.length > 0 && !this.state.reset ? (
      <DataTable data-body={users.map((user) => (typeof user === 'object') ? user.map((item) => ({
        ...item,
        mau: item.mau === 'true' ? 'Yes' : 'No'
      })) : [])} data-head={thead} date={date}
      filter="status" filter-entries={filterEntries} filter-item={[]} 
      filter-label="Status Layanan"
      loading={isLoading}
      max-char={40} onAction={this._handleAction.bind(this)}
      onApplyDate={this._handleApplyDate.bind(this)}
      onClickPage={this._handleNav.bind(this)}
      onDownload={this._handleDownload.bind(this)}
      page={this.state.page} show={show} total-data={totalData} />
    ) : ''; 
    const resetModal = () => { this.setState({
      download: false,
      showModal: false,
      edit: false,
    });
    actions.setUser(null);
    };
    if (this.state.showModal && this.props.update !== null) {
      let image = (!this.props.update)
        ? <img src={IMAGES.STATUS_ERROR} />
        : <img src={IMAGES.STATUS_SUCCESS} />;
      let label = (!this.props.update)
        ? 'tidak berhasil' : 'berhasil';

      modal = (<section className={classes.modalWrapper}>
        <span className={classes.modalOverlay}/>
        <div className={classes.modal}>
          {image}
          <span className={classes.modalDivider} />
          <div className={classes.modalDescription}>
            {message ? `Status pelanggan ${label} diperbaharui`
              : `Data pelanggan ${label} diperbaharui`}
          </div>
          {
            message && <div>
              <p>Detail:</p>
              {
                Object.keys(message).map((key) => {
                  if (typeof message[`${key}`] === 'string') {
                    return <p key={key}>{key} : {message[`${key}`]}</p>;
                  } else if (key === 'smartliving_response') {
                    return <p key={key}>message : {message[`${key}`].info}</p>;              
                  }
                })
              }
            </div>
          }
          <section className={classes.modalButton}>
            <Button className={[classes.buttonModal, classes.buttonWhite].join(' ')} color="primary" 
              onClick={resetModal} size="32">
              <label className={classes.buttonLabel}>
                Oke
              </label>
            </Button>
          </section>
        </div>
      </section>);
    }
    modal = (this.state.showModal && this.state.download) ? (
      <section className={classes.modalWrapper}>
        <span className={classes.modalOverlay}/>
        <div className={classes.modal}>
          <img src={IMAGES.DOWNLOAD_MODAL} />
          <span className={classes.modalDivider} />
          <div className={classes.modalDescription}>
            Data berhasil di download
          </div>
          <section className={classes.modalButton}>
            <Button className={[classes.buttonModal, classes.buttonWhite].join(' ')} color="primary" 
              onClick={resetModal} size="32">
              <label className={classes.buttonLabel}>
                Oke
              </label>
            </Button>
          </section>
        </div>
      </section>
    ) : modal;
    modal = (this.state.showModal && this.state.edit && user) ? (
      <section className={classes.modalWrapper}>
        <span className={classes.modalOverlay}/>
        <Form onClose={resetModal} onSubmit={this._handleUpdate.bind(this)} />
      </section>
    ) : modal;
    const blurred = (this.state.showModal) ? classes.blur : '';
    return (
      <React.Fragment>
        <main className={blurred}>
          <Base {...this.props}>
            <section className={classes.search}>
              <label className={classes.searchLabel}>
                Cari dengan :
              </label>
              <Dropdown className={classes.dropdown} items={filterItem}
                onChange={this._handleChangeDropdown} selected-item={this.state.selectedItem} />
              <div className={classes.textfieldWrap}>
                <Textfield {...propsSearch} />
              </div>
              <Button className={classes.button} color="primary" onClick={this._handleFilter} size="32" >
                Cari
              </Button>
            </section>
            <section className={classes.table}>{table}</section>
          </Base>
        </main>
        {modal}
      </React.Fragment>
    );
  }
}

Component.propTypes = {
  actions: PropTypes.object,
  classes: PropTypes.object,
  download: PropTypes.array,
  isLoading: PropTypes.bool,
  message: PropTypes.string,
  totalData: PropTypes.number,
  update: PropTypes.bool,
  user: PropTypes.object,
  users: PropTypes.array,
};

Component.defaultProps = {
  actions: {},
  classes: {},
  download: [],
  isLoading: false,
  message: '',
  totalData: 0,
  update: null,
  user: {},
  users: [],
};

// _download(list) {
//   this.setState({ download: true, showModal: true }, () => {
//     let csv = toCsv([
//       'User Id','Nama Pengguna','Nomor Indihome','Username','Email','No. Handphone','Paket',
//       'Versi App', 'MAU', 'Verified', 'Last Active', 'Updated At'
//     ],list.map(item => ({
//       userId: item.userId, name: item.name, ndmain: item.ndmain,
//       username: item.name, email: item.email,
//       msisdn: item.msisdn, paket: item.paket,
//       version: item.version,
//       mau: item.mau ? 'Yes' : 'No', ndmain_status: item.ndmain_status,
//       lastActive: item.lastActive,
//       updatedAt: item.updatedAt
//     })));
//     let blob = new Blob([csv], { type: 'text/csv;charset=utf-8;' });
//     let link = document.createElement('a');
    
//     if (link.download !== undefined) {
//       let url = URL.createObjectURL(blob);        
//       link.setAttribute('href', url);
//       link.setAttribute('download', 'download.csv');
//       link.style.visibility = 'hidden';
//       document.body.appendChild(link);
//       link.click();
//       document.body.removeChild(link);
//     }
//   });
// }
