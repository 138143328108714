const styles = () => ({
  block: {
    backgroundColor: 'rgb(238, 49, 36, 0.2)',
    height: '100%',
    position: 'absolute',
    width: '50%',
  },
  blockLeft: {
    left: 0
  },
  blockRight: {
    right: 0,
  },
  dateWrapper: {
    position: 'relative',
  },
  picker: {
    backgroundColor: 'white',
    borderRadius: '5px',
    boxSizing: 'border-box',
    marginTop: '20px',
    position: 'relative',
    width: '280px'
  },
  pickerBody: {
    marginTop: '4px'
  },
  pickerChevron: {
    alignItems: 'center',
    color: 'red',
    cursor: 'pointer',
    display: 'flex',
    height: '40px',
    justifyContent: 'center',
    width: '40px',
  },
  pickerDateCurrent: {
    '&:hover': {
      backgroundColor: 'grey'
    },
  },
  pickerDateItem: {
    alignItems: 'center',
    cursor: 'pointer',
    display: 'flex',
    height: '40px',
    justifyContent: 'center',
    width: '40px'
  },
  pickerDay: {
    display: 'grid',
    gridTemplateColumns: '40px 40px 40px 40px 40px 40px 40px'
  },
  pickerEnd: {
    '&:before': {
      content: '""',
      left: 0,
    },
    borderRadius: '100%',
    position: 'absolute',
  },
  pickerHead: {
    alignItems: 'center',
    display: 'flex',
    height: '40px',
  },
  pickerHeadCenter: {
    justifyContent: 'center'
  },
  pickerHeadJustify: {
    justifyContent: 'space-between'
  },
  pickerHeadLabel: {
    alignItems: 'center',
    display: 'flex',
    fontSize: '16px',
    fontWeight: 500,
    lineHeight: '18px',
  },
  pickerItemDisabled: {
    color: 'grey'
  },
  pickerItemSunday: {
    color: 'red'
  },
  pickerMonth: {
    display: 'grid',
    gridTemplateColumns: '90px 90px 90px',
    marginTop: '30px'
  },
  pickerMonthItem: {
    '&:hover': {
      backgroundColor: 'grey'
    },
    alignItems: 'center',
    cursor: 'pointer',
    display: 'flex',
    height: '40px',
    justifyContent: 'center'
  },
  pickerRange: {
    backgroundColor: 'rgb(238, 49, 36, 0.2)'
  },
  pickerSelected: {
    backgroundColor: `red !important`,
    color: 'white !important',
  },
  pickerStart: {
    '&:before': {
      content: '""',
      right: 0,
    },
    borderRadius: '100%',
    position: 'absolute',
  },
  pickerStartItem: {
    borderRadius: '100%',
  },
  wrapper: {
    width: '280px'
  }
});

export default styles;
