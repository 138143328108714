import React from 'react';
import PropTypes from 'prop-types';

export default class Avatar extends React.Component {
  render() {
    const { classes, src, variant } = this.props;
    const classAvatar = [
      classes[variant],
      classes.root
    ].join(' ');
    
    return (
      <img className={classAvatar} src={src}/>
    );
  }
}

Avatar.propTypes = {
  classes: PropTypes.object,
  src: PropTypes.string,
  variant: PropTypes.string,
};

Avatar.defaultProps = {
  classes: {},
  src: '',
  variant: '',
};
