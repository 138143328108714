const routes = {
  ADMIN() { return `/admin-list` },
  CUSTOMER_LIST() { return `/pelanggan/list_pelanggan` },
  CUSTOMER_LIST_SHARING() { return `/pelanggan/list_sharing` },
  DASHBOARD() { return `/dashboard` },
  DEVICE_LIST() { return `/pelanggan/list_perangkat` },
  ERROR_EVENT() { return `/error_event` },
  LOG_DEVICE() { return `/log/perangkat` },
  LOG_EMAIL() { return `/log/email` },
  LOGIN() { return `/`; },
  SETTINGS() { return `/setting`; },
};

export default routes;