const line = '1px solid #D9DEE4';
const styles = {
  block: {
    display: 'block',
  },
  blur: {
    filter: 'blur(5px)',
    WebkitFilter: 'blur(5px)',
  },
  buttonLabel: {
    fontFamily: 'Poppins',
    fontSize: '16px',
    fontStyle: 'normal',
    fontWeight: 'normal',
    lineHeight: '24px',
  },
  buttonLogout: {
    borderRadius: '5px !important',
    height: '50px !important',
    marhin: 0,
    width: '320px',
  },
  buttonWhite: {
    background: '#FFFFFF !important',
    border: '1px solid #EE3124 !important',
    boxSizing: 'border-box !imporant',
    color: '#EE3124 !important',
  },
  capitalize: {
    textTransform: 'capitalize',
  },
  content: {
    display: 'flex',
    flexDirection: 'column',
    flexGrow: 1,
  },
  contentChildren: {
    background: '#f8f8ff',
    flexGrow: 1,
    padding: '30px 33px 30px 31px'
  },
  currentMenu: {
    background: 'rgba(235, 121, 114, 0.2)',
    borderLeft: '4px solid #EE3124',
    color: '#EE3124',
  },
  dropdown: {
    position: 'absolute',
    right: 0,
    top: '100%',
    width: '150px',
  },
  header: {
    alignItems: 'center',
    background: 'white',
    borderBottom: line,
    display: 'flex',
    height: '60px',
    justifyContent: 'space-between',
    padding: '0px 34.33px 0px 35px',
  },
  headerCrumbs: {
    alignItems: 'center',
    background: 'white',
    borderBottom: line,
    display: 'flex',
    height: '60px',
    padding: '0px 34.33px 0px 35px',
  },
  headerItem: {
    alignItems: 'center',
    display: 'flex',
    justifyContent: 'center',
    position: 'relative',
  },
  labelMenu: {
    alignItems: 'center',
    display: 'flex',
    marginBottom: 0
  },
  link: {
    color: 'inherit',
    textDecoration: 'none',
  },
  listMenu: {
    display: 'block',
    marginTop: '15px',
  },
  logo: {
    height: '36px',
    margin: '10px 0',
    width: '125px',
  },
  logoChevron: {
    bottom: '-3px',
    cursor: 'pointer',
    height: '14px',
    position: 'absolute',
  },
  logoEazy: {
    margin: '10px 0',    
    width: '75px',
  },
  logoSooltancam: {
    height: '20px',
    margin: '10px 0',    
    width: '125px',
  },
  logoutButton: {
    marginTop: '28px',
  },
  logoutDescription: {
    color: 'rgba(0, 0, 0, 0.54)',
    fontSize: '23px',
    fontStyle: 'normal',
    fontWeight: 500,
    lineHeight: '34px',
    marginTop: '18px',
    textAlign: 'center',
  },
  logoutDivider: {
    background: '#EE3124',
    borderRadius: '7px',
    display: 'block',
    height: '7px',
    marginTop: '4px',
    width: '70px',
  },
  logoutModal: {
    alignItems: 'center',
    background: 'white',
    borderRadius: '5px',
    display: 'flex',
    flexDirection: 'column',
    height: '312px',
    justifyContent: 'flex-start',
    padding: '28px 37px',
    position: 'fixed',
    width: '730px',
  },
  logoutOverlay: {
    background: 'rgb(0,0,0,0.4)',
    display: 'block',
    height: '100%',
    width: '100%',
  },
  logoutTitle: {
    fontSize: '36px',
    fontStyle: 'normal',
    fontWeight: 'bold',
    lineHeight: '54px',
    margin: 0,
    marginTop: '22px',
  },
  logoutWrapper: {
    alignItems: 'center',
    display: 'flex',
    fontFamily: 'Poppins',
    height: '100vh',
    justifyContent: 'center',
    left: 0,
    position: 'fixed',
    top: 0,
    width: '100vw',
  },
  logoWrapper: {
    alignItems: 'center',
    borderBottom: line,
    display: 'flex',
    flexDirection: 'column',
    minHeight: '60px',
    position: 'relative',
  },
  menu: {
    alignItems: 'center',
    display: 'flex',
    height: '40px',
    justifyContent: 'space-between',
    paddingLeft: '30px',
    paddingRight: '15px',
    textTransform: 'capitalize',
  },
  menuImage: {
    backgroundColor: '#828282',
    height: '24px',
    marginRight: '8px',
    width: '24px',
  },
  menuImageCurrent: {
    backgroundColor: '#EE3124',
  },
  menuImagePng: {
    height: '24px',
    marginRight: '8px',
    width: '24px',
  },
  root: {
    display: 'flex',
    fontFamily: 'Poppins',
    marginBottom: '20px',
  },
  rotate: {
    transform: 'rotate(180deg)'
  },
  sideBar: {
    background: 'white',
    borderRight: line,
    minHeight: '100vh',
    minWidth: '215px',
  },
  subMenu: {
    alignItems: 'center',
    color: '#828282',
    cursor: 'pointer',
    display: 'flex',
    height: '40px',
    paddingLeft: '45px',
    textDecoration: 'none',
    textTransform: 'capitalize',
  },
  subMenuCurrent: {
    color: '#EE3124',
  },
  userLabel: {
    fontSize: '13px',
    fontStyle: 'normal',
    fontWeight: 600,
    lineHeight: '19px',
    margin: '0 44.33px 0 10px',
  }
};

export default styles;
