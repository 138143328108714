/* eslint-disable */

import React from 'react';
import classNames from 'classnames';
import Typography from '../Typography'
import { IMAGES } from '../../../configs';

export default class Calendar extends React.Component {
  constructor(props) {
    super(props);

    const { state } = this.props;
    
    this.state = state;
  }

  _daysInMonth(iMonth, iYear) {
    return 32 - new Date(iYear, iMonth, 32).getDate();
  }

  _renderDate() {
    const { classes } = this.props;

    return (
      <section className={classes.pickerBody}>
        { this._renderDays() }
        { this._renderDateItem() }
      </section>
    );
  }
  
  _renderDateItem() {
    const { classes, clickNavigator, clickValue, 
      currentMonth, currentYear, startDate, endDate } = this.props;
    const totalDate = this._daysInMonth(currentMonth,currentYear);
    let dateItem = [];
    let firstDay = (new Date(currentYear, currentMonth).getDay() === 0) 
      ? new Date(currentYear, currentMonth).getDay() + 6
      : new Date(currentYear, currentMonth).getDay() - 1;
    let startDates = 1;
    let totalDateBefore = new Date(currentYear, currentMonth, 0).getDate();  
    
    if (firstDay !== 0) {
      for (let i = 0; i < firstDay; i++) {
        const className = classNames(
          classes.pickerDateItem, classes.pickerItemDisabled
        );

        dateItem.push(
          <div className={className} 
            key={`before-${i}`} onClick={clickNavigator.bind(this, 'before')}>
            <Typography variant="caption">{totalDateBefore}</Typography>
          </div>);

        totalDateBefore--;
      }
    } else {
      const className = classNames(
        classes.pickerDateItem, classes.pickerItemDisabled
      );

      dateItem.push(
        <div className={className} 
          key={`before`} onClick={clickNavigator.bind(this, 'before')}>
          <Typography variant="caption">{totalDateBefore}</Typography>
        </div>);
    }

    dateItem.reverse();

    do {
      const sundayItem = ((dateItem.length + 1) % 7 === 0) ? classes.pickerItemSunday : '';
      let block, startItem, startItemDate, startPicker, endItem, endPicker;

      if (startDate) {
        startItem = (startDate.getDate() === startDates 
          && startDate.getMonth() === currentMonth 
          && startDate.getFullYear() === currentYear) ? classes.pickerSelected : '';
        startPicker = (endDate > startDate && startDate.getDate() === startDates
          && startDate.getMonth() === currentMonth 
          && startDate.getFullYear() === currentYear) ?
          classes.pickerStart : '';
        startItemDate = (startDate.getDate() === startDates 
        && startDate.getMonth() === currentMonth 
        && startDate.getFullYear() === currentYear) ? classes.pickerStartItem : '';
      }

      if (endDate) {
        endItem = (endDate.getDate() === startDates 
          && endDate.getMonth() === currentMonth 
          && endDate.getFullYear() === currentYear) ? classes.pickerSelected : '';
        endPicker = (endDate > startDate && endDate.getDate() === startDates
          && endDate.getMonth() === currentMonth 
          && endDate.getFullYear() === currentYear) ?
          classes.pickerEnd : '';
      }

      const rangeItem = (startDate && endDate && (startDate <= new Date(currentYear, currentMonth, startDates)) 
        && (new Date(currentYear, currentMonth, startDates) <= endDate))
        ? classes.pickerRange : '';
      const className = classNames(
        classes.pickerDateCurrent, classes.pickerDateItem, sundayItem, startItem, endItem, rangeItem,
        startPicker, startItemDate, endPicker
      );
      
      if (startPicker || endPicker) {
        const align = (startPicker) ? classes.blockRight : classes.blockLeft;

        block = <span className={[align, classes.block].join(' ')}/>;
      };

      dateItem.push(
        <div className={classes.dateWrapper}>
          {block}
          <div className={className} id="date-item" 
            key={startDates} onClick={clickValue.bind(this, startDates)}>
            <Typography variant="caption">{startDates}</Typography>
          </div>
        </div>
      );

      startDates++;
    } while (startDates <= totalDate);
    
    startDates = 1;

    while (dateItem.length % 7 !== 0) {
      const className = classNames(
        classes.pickerDateItem, classes.pickerItemDisabled
      );

      dateItem.push(<div className={className} key={`after-${startDates}`} onClick={clickNavigator.bind(this, 'after')}><Typography variant="caption">{startDates}</Typography></div>);

      startDates++;
    }

    return (
      <div className={classes.pickerDay}>
        { dateItem }
      </div>
    );
  }

  _renderDays() {
    const { classes } = this.props;
    const className = classNames(
      classes.pickerDateItem, classes.pickerItemSunday
    );

    return (
      <div className={classes.pickerDay}>
        <div className={classes.pickerDateItem}><Typography variant="caption">S</Typography></div>
        <div className={classes.pickerDateItem}><Typography variant="caption">S</Typography></div>
        <div className={classes.pickerDateItem}><Typography variant="caption">R</Typography></div>
        <div className={classes.pickerDateItem}><Typography variant="caption">K</Typography></div>
        <div className={classes.pickerDateItem}><Typography variant="caption">J</Typography></div>
        <div className={classes.pickerDateItem}><Typography variant="caption">S</Typography></div>
        <div className={className}><Typography variant="caption">M</Typography></div>
      </div>
    );
  }

  _renderHeaderDate() {
    const { classes, clickLayer, currentMonth, currentYear, month } = this.props;

    return (
      <div className={classes.pickerHeadLabel}>
        <div id="navigation-month" onClick={clickLayer.bind(this, 'month')}>
          <Typography variant="subtitle2">{month[currentMonth]}</Typography>
        </div>
        <span>&nbsp;</span> 
        <div id="navigation-year" onClick={clickLayer.bind(this, 'year')}>
          <Typography variant="subtitle2">{currentYear}</Typography>
        </div>
      </div>
    );
  }

  _renderHeaderYear() {
    const { classes, currentYear } = this.props;

    return (
      <div className={classes.pickerHeadLabel}>
        <div>
          <Typography variant="subtitle2">{currentYear}</Typography>
        </div>
        <span>-</span> 
        <div>
          <Typography variant="subtitle2">{currentYear + 11}</Typography>
        </div>
      </div>
    );
  }

  _renderMonth() {
    const { classes, month, actualMonth, setMonth } = this.props;
    const classNameMonthWrapper = classNames(
      classes.pickerBody, classes.pickerMonth
    );  
    
    return (
      <section className={classNameMonthWrapper}>
        {
          month.map((month, index) => {
            const thisMonth = (index === actualMonth) ? classes.pickerSelected : '';
            const classNameMonthItem = classNames(
              classes.pickerMonthItem, thisMonth
            );

            return (
              <div className={classNameMonthItem} id="month-item" key={index} onClick={setMonth.bind(this, index)}>
                <Typography variant="caption">{ month.substr(0,3) }</Typography>
              </div>
            );
          })
        }
      </section>
    );
  }

  _renderYear() {
    const { actualYear, classes, currentYear, setYear } = this.props;
    let yearItem = [];
    const classNameYearWrapper = classNames(
      classes.pickerBody, classes.pickerMonth
    );

    for (let index = currentYear ; index < currentYear + 12 ; index++) {
      const thisYear = (index === actualYear) ? classes.pickerSelected : '';
      const classNameYearItem = classNames(
        classes.pickerMonthItem, thisYear
      );

      yearItem.push(
        <div className={classNameYearItem} id="year-item" key={index} onClick={setYear.bind(this, index)}>
          <Typography variant="caption">{ index }</Typography>
        </div>
      );
    }

    return (
      <section className={classNameYearWrapper}>
        { yearItem }
      </section>
    );
  }

  render() {
    const { activeLayer, classes, clickNavigator } = this.props;
    let layer, header, headerPosition;
    const chevronLeft = (activeLayer === 'date' || activeLayer ===  'year') ?
      <div className={classes.pickerChevron} id="navigation-left" onClick={clickNavigator.bind(this,'before')}>
        <img src={IMAGES.PREV_PAGE} />
      </div> 
      : '';
    const chevronRight = (activeLayer === 'date' || activeLayer ===  'year') ?
      <div className={classes.pickerChevron} id="navigation-right" onClick={clickNavigator.bind(this,'after')}>
        <img src={IMAGES.NEXT_PAGE} />
      </div>
      : '';

    switch(activeLayer) {
      case 'date':
        layer = this._renderDate();
        header = this._renderHeaderDate();
        headerPosition = classes.pickerHeadJustify;
        break;
      case 'month':
        layer = this._renderMonth();
        header = this._renderHeaderDate();
        headerPosition = classes.pickerHeadCenter;
        break;
      case 'year':
        layer = this._renderYear();
        header = this._renderHeaderYear();
        headerPosition = classes.pickerHeadJustify;
        break;
      default:
        layer = this._renderDate();
        header = this._renderHeaderDate();
        headerPosition = classes.pickerHeadJustify;
        break;
    }

    const className = classNames(
      classes.pickerHead, headerPosition
    );

    return (
      <div className={classes.picker}>
        <section className={className}>
          { chevronLeft }
          <div>{ header }</div>
          { chevronRight }
        </section>
        { layer }
      </div>
    );
  }
}