import React from 'react';
import PropTypes from 'prop-types';

export default class ListItem extends React.Component {
  constructor(props) {
    super(props);

    this._handleClick = this._handleClick.bind(this);
  }

  _handleClick(index) {
    const { onClick } = this.props;

    if (onClick) 
      onClick(index);
  }

  render() {
    const { classes, className: classNameprops, items, 'selected-item': selectedItem } = this.props;
    const classNames = [
      classes.root,
      classNameprops,
    ].join(' ');

    return (
      <main className={classNames}>
        {
          items.map((item, index) => {
            const selectedClass = (index === selectedItem) ? classes.selected : null;
            const classItem = [
              classes.item,
              selectedClass
            ].join(' ');
            
            return (
              <div className={classItem} key={index} 
                onClick={this._handleClick.bind(this, index)}>{item}</div>
            );
          })
        }
      </main>
    );
  }
}

ListItem.propTypes = {
  classes: PropTypes.object,
  className: PropTypes.string,
  items: PropTypes.array,
  onClick: PropTypes.func,
  'selected-item': PropTypes.number,
};

ListItem.defaultProps = {
  'selected-item': 0,
  classes: {},
  className: {},
  items: [],
  onClick: () => {},
};
