import React from 'react';
import PropTypes from 'prop-types';

export default class Icon extends React.Component {
  render() {
    const { className: classNameProps ,src } = this.props;

    return (
      <i className={classNameProps} style={{
        mask: `url(${src}) no-repeat center`,
        WebkitMask: `url(${src}) no-repeat center`,
      }} />
    );
  }
}

Icon.propTypes = {
  className: PropTypes.object,
  src: PropTypes.string,
};

Icon.defaultProps = {
  className: {},
  src: '',
};
