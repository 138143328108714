const styles = theme => ({
  body1: theme.typography.body1,
  body2: theme.typography.body2,
  button: theme.typography.button,
  caption: theme.typography.caption,
  colorBlack: {
    color: theme.palette.common.black
  },
  colorDefault: {
    color: 'inherit'
  },
  colorDisabled: {
    color: theme.palette.event.disabled.main
  },
  colorError: {
    color: theme.palette.event.error.main
  },
  colorInformation: {
    color: theme.palette.event.information.main
  },
  colorPrimaryDark: {
    color: theme.palette.base.primary.dark
  },
  colorPrimaryLight: {
    color: theme.palette.base.primary.light
  },
  colorPrimaryMain: {
    color: theme.palette.base.primary.main
  },
  colorSecondaryDark: {
    color: theme.palette.base.secondary.dark
  },
  colorSecondaryLight: {
    color: theme.palette.base.secondary.light
  },
  colorSecondaryMain: {
    color: theme.palette.base.secondary.main
  },
  colorSuccess: {
    color: theme.palette.event.success.main
  },
  colorTertiaryDark: {
    color: theme.palette.base.tertiary.dark
  },
  colorTertiaryLight: {
    color: theme.palette.base.tertiary.light
  },
  colorTertiaryMain: {
    color: theme.palette.base.tertiary.main
  },
  colorWarning: {
    color: theme.palette.event.warning.main
  },
  colorWhite: {
    color: theme.palette.common.white
  },
  h1: theme.typography.h1,
  h2: theme.typography.h2,
  h3: theme.typography.h3,
  h4: theme.typography.h4,
  h5: theme.typography.h5,
  noWrap: {
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap'
  },
  overline1: theme.typography.overline1,
  overline2: theme.typography.overline2,
  root: {
    display: 'block',
    fontFamily: theme.typography.fontFamily,
    margin: 0,
  },

  subtitle1: theme.typography.subtitle1,
  subtitle2: theme.typography.subtitle2,

  
});

export default styles;
