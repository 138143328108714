import React from 'react';
import PropTypes from 'prop-types';
import { IMAGES } from '../../configs';
import Base from '../../components/layouts/Pagebase';
import Button from '../../components/elements/Button';
import DataTable from '../../components/elements/DataTable';
import Textfield from '../../components/elements/Textfield';
import toCsv from '../../utils/toCsv';

export default class Component extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      page: 1,
      queryText: null,
      query: null,
      showModal: false,
    };

    this._handleClearfilter = this._handleClearfilter.bind(this);
    this._handleFilter = this._handleFilter.bind(this);
    this._handleQuery = this._handleQuery.bind(this);
    this._handleChangeDropdown = this._handleChangeDropdown.bind(this);
  }

  componentDidMount() {
    const { actions } = this.props;

    actions.fetchAllDevices(this.state.queryText);
  }

  _filteredDevices(devices) {
    const { filterObject, query, selectedItem } = this.state;
    let filtered = devices;
    
    if (query) {
      filtered = devices.filter((device) => {
        if (device[filterObject[selectedItem]]) {
          return device[filterObject[selectedItem]].toLowerCase()
            .includes(this.state.query.toLowerCase());
        }
      });
    }

    return filtered;
  }

  _handleChangeDropdown(selectedItem) {
    this.setState({ query: '', queryText: '', selectedItem });
  }

  _handleClearfilter() {
    const { actions } = this.props;

    this.setState({
      query: '',
      queryText: '',
    }, () => {
      actions.fetchAllDevices();
    }); 
  }

  _handleDownload(list) {
    this.setState({ showModal: true }, () => {
      let csv = toCsv([
        'timestamp', 'fields'
      ],list);
      let blob = new Blob([csv], { type: 'text/csv;charset=utf-8;' });
      let link = document.createElement('a');
      
      if (link.download !== undefined) {
        let url = URL.createObjectURL(blob);
        
        link.setAttribute('href', url);
        link.setAttribute('download', 'download.csv');
        link.style.visibility = 'hidden';
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      }
    });
  }

  _handleQuery(queryText) {
    this.setState({ queryText });
  }

  _handleFilter() {
    const { actions } = this.props;
    
    this.setState({
      page: 1,
    }, () => { actions.fetchAllDevices(this.state.queryText); });    
  }

  render() {
    const { classes, logs, isLoading } = this.props;
    const propsSearch = {
      className: classes.textfield,
      input: {
        name: 'username',
        onChange: this._handleQuery,
        onSubmit: this._handleFilter,
        value: this.state.queryText,
      },
      meta: {
        'icon-align': 'left',
        icon: <img src={IMAGES.SEARCH} />,
        iconRight: <label onClick={this._handleClearfilter}>Clear</label>,
        placeholder: 'E-mail / No. Handphone',
        type: 'text',
        value: this.state.queryText,
        variant: 'basic-filled',
      },
    };
    const filterEntries = [10, 20, 30, 40, 50];
    const thead = ['Timestamp', 'Respon Data'];
    const table = (!isLoading) ? (
      <DataTable 
        align="top"  
        data-body={this._filteredDevices(logs)} data-head={thead}
        filter-entries={filterEntries} max-char={2000} 
        max-width="600"
        min-width="330"
        onDownload={this._handleDownload.bind(this)}
        page={this.state.page} 
        show={['timestamp', 'fields']} />
    ) : null;
    const resetModal = () => { this.setState({ showModal: false }); };
    const modal = (this.state.showModal) ? (
      <section className={classes.modalWrapper}>
        <span className={classes.modalOverlay}/>
        <div className={classes.modal}>
          <img src={IMAGES.DOWNLOAD_MODAL} />
          <span className={classes.modalDivider} />
          <div className={classes.modalDescription}>
            Data berhasil di download
          </div>
          <section className={classes.modalButton}>
            <Button className={[classes.buttonModal, classes.buttonWhite].join(' ')} color="primary" 
              onClick={resetModal} size="32">
              <label className={classes.buttonLabel}>
                OK
              </label>
            </Button>
          </section>
        </div>
      </section>
    ) : null;
    const blurred = (this.state.showModal) ? classes.blur : '';
    return (
      <React.Fragment>
        <main className={blurred}>
          <Base {...this.props}>
            <section className={classes.search}>
              <div className={classes.textfieldWrap}>
                <Textfield {...propsSearch} />
              </div>
              <Button className={classes.button} color="primary" onClick={this._handleFilter} size="32" >
                Search
              </Button>
            </section>
            <section className={classes.table}>
              {table}
            </section>
          </Base>
        </main>
        {modal}
      </React.Fragment>
    );
  }
}

Component.propTypes = {
  actions: PropTypes.object,
  classes: PropTypes.object,
  isLoading: PropTypes.bool,
  logs: PropTypes.array,
};

Component.defaultProps = {
  actions: {},
  classes: {},
  isLoading: false,
  logs: [],
};
